import _ from "lodash";
import { Link } from "react-router-dom";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../core/localization/I18n";
import { StakeholderType } from "../../../../enums";
import ProjectStakeholdersApi from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export const getEntityNameMicroFormFields = (organisationId: number, projectId: number, onItemSelected) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const projectStakeholderProvider = ProjectStakeholdersApi;

  const stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    placeholder: I18n.t("placeholders.searchAndAddAudience"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectStakeholderProvider.getUnassignedProjectStakeholders(organisationId, projectId);

      if (res?.payload) {
        let sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        sortedStakeholders = sortedStakeholders.filter(s => s.stakeholderType === StakeholderType.AUDIENCE);
        self.setOptions(sortedStakeholders);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
      this.searchQuery = "";
    },
    shouldClearOnBlur: true,
    fieldClassName: "stakeholder-add-input mb-lg-0",
    valueLabelFn: e =>
      e.stakeholderType === StakeholderType.INDIVIDUAL ? e.firstName + " " + e.lastName : e.firstName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    searchResultHint: (
      <p className="mb-0 pl-3 pb-1 pt-2">
        <Link target="_blank" to={`/organisations/${organisationId}/projects/${projectId}/addAudience`}>
          {I18n.t("forms.createNewAudience")}
        </Link>
      </p>
    )
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stakeholdersSearch);

  return generateFormFieldsFromJson(fields);
};
