import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiActionRenderers } from "@flightpath/coreui/dist/uiAction/IUiAction";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import * as _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { NavigateFunction } from "react-router-dom";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import {
  SHOW_ADD_PROCESSES_FROM_TEMPLATE_MODAL_CONFIG,
  SHOW_PROCESS_DELETE_CONFIRM_MODAL
} from "../../../../../core/grids/builder/directorBuilder/grids/ProcessesGrid/ProcessesGridView_modals";
import { GridModalBuilder } from "../../../../../core/grids/builder/directorBuilder/modals/GridModal_builder";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import { ModalContextModel } from "../../../../../core/modalZ/context/ModalContext_model";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { DisposableModel } from "../../../../../core/util/DisposableModel";
import { buildTree } from "../../../../../core/util/Helpers";
import ProcessesApi, { ProcessesApi as IProcessesApi } from "../../../../../services/api/v2/processes/Processes.api";
import { CommentsApi as ICommentsApi } from "../../../../../services/api/v2/comments/Comments.api";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { buildProcessListData } from "../../../../change/organisations/settingsView/processesSettings/ProcessesSettings_model";
import { ProcessGridViewModel } from "../_grid/ProcessesGridView_model";
import * as apiFilters from "./ProcessesView_apiFilter";
import { SHOW_PROCESS_IMPACT_GROUP_LINK_MODAL } from "./ProcessesView_modals";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class ProcessesViewModel extends DisposableModel {
  commentsProvider: ICommentsApi;
  gridToastService: IGridToastService;
  projectId: number;
  modalService: IModalContextModel;
  projectProcessProvider: IProjectProcessesApi;
  processProvider: IProcessesApi;
  @observable confirmationService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable.ref selectedProcesses: number[] = [];
  toasterService: IToasterService;
  @observable.ref process: FP.Entities.IProject;
  organisationId: number;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  gridApi: GridApi;
  navigate: NavigateFunction;
  filterModel?: any;
  @observable gridModel: ProcessGridViewModel;
  @observable.ref columnDefs: any[];
  @observable searchText: string;
  gridModalBuilder: GridModalBuilder;
  uiActions = [
    {
      id: "action1",
      label: I18n.t("entities.highLevelImpacts"),
      onAction: ev => {
        this.showLinkProcessesToImpactGroupModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: "p-1"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser, navigate: NavigateFunction) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.projectProcessProvider = ProjectProcessesApi;
    this.processProvider = ProcessesApi;
    this.modalService = new ModalContextModel();
    this.httpProgress = ProgressIndicatorModel;
    this.gridModalBuilder = new GridModalBuilder();
  }

  onMount = async () => {
    this.setGridModel(
      new ProcessGridViewModel(this.authUser, this.organisationId, this.projectId, [
        "selector",
        "uniqueIdentifier",
        "processL1",
        "processL2",
        "processL3",
        "scopeItemNames",
        "processRoles",
        "processApps",
        "impactGroupNames",
        "impactNames",
        "actionNames",
        "processJobRoles",
        "noteCount",
        "tags"
      ])
    );
    await this.gridModel.onMount();

    // this.setColumnDefs(res.payload);
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setColumnDefs = (columnDefs: any[]) => (this.columnDefs = columnDefs);

  @action
  updateSelectedProcesses = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedProcesses = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  loadOrgProcessData = async () => {
    this.httpProgress.showOverlay();
    let res = await this.processProvider.getAll(this.organisationId);

    this.httpProgress.hideOverlay();
    if (res.isError) {
      return;
    }

    return this.setOrgProcessData(res.payload);
  };

  setOrgProcessData = (processData: any[]) => {
    const mappedResults = processData.map(e => {
      return {
        ...e,
        parent: e.parent === 0 || !e.parent ? "" : e.parent
      };
    });
    const treeData = buildTree(mappedResults);

    const orgProcessData = buildProcessListData(treeData);

    return orgProcessData;
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  setGridModel = (gridModel: any) => {
    this.gridModel = gridModel;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.filterModel = {
      ...apiFilters.getProcessGridFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  removeProcessRange = async (processIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.projectProcessProvider.deleteRange(this.organisationId, this.projectId, processIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    return res.payload;
  };

  showProcessConfirmDeleteModal = () => {
    return SHOW_PROCESS_DELETE_CONFIRM_MODAL(this.selectedProcesses, this.removeProcessRange);
  };

  showLinkProcessesToImpactGroupModal = () => {
    return SHOW_PROCESS_IMPACT_GROUP_LINK_MODAL(this.projectId, this.selectedProcesses);
  };

  showCoreProcessesLinkModal = async () => {
    return this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(
        SHOW_ADD_PROCESSES_FROM_TEMPLATE_MODAL_CONFIG(
          await this.loadOrgProcessData(),
          this.assignProjectProcessesToProject,
          this.selectedProcesses
        )
      )
      .generateModal();
  };

  assignProjectProcessesToProject = async (selectedItems: any) => {
    this.httpProgress.showOverlay();
    const res = await this.projectProcessProvider.createProjectProcess(
      this.organisationId,
      this.projectId,
      selectedItems
    );
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    ModalContext.hide();
    return res.payload;
  };
}
