import { ColDef } from "ag-grid-community";
import { ActionField, EntityTypes } from "../../../../../../enums";
import {
  colCreatedByName,
  colCreatedOn,
  colDescription,
  colEndDate,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colOwner,
  colProgressStatus,
  colRefNumber,
  colReviewedBy,
  colSelected,
  colStartDate,
  colTags,
  colUpdatedOn,
  colImpacts
} from "../../../../../../core/grids/CommonGridColumns";
import {
  colActionTypes,
  colAssignedTo,
  colBusinessAreasFromImpacts,
  colProjectAudiences,
  colProjectStakeholderOwners,
  colProjectStakeholders,
  colRagStatus
} from "./AcGridView_BaseColumns";

import { IActionGridModals } from "./modals/AcGridView_modals";
import { IGridFns } from "../../../../../../core/grids/GridFunctions";
import { SHOW_STAKEHOLDER_OWNERS_MODAL } from "../ActionListView_modals";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";

export interface IGetActionGridViewColumns {
  userCanViewImpacts: boolean;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypes: FP.Entities.IActionType[];
  projectTeamMembers: FP.Entities.IUser[];
  modals: IActionGridModals;
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  userCanViewStakeholders: boolean;
}

export const GetActionGridViewColumns = (modelProps: IGetActionGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  let modals = modelProps.modals;
  if (modelProps.canEdit) {
    result.push(colSelected());
  }
  result.push(colRagStatus(modelProps.canEdit, modelProps.gridFns.updateIdField, { pinned: "left" }));
  result.push(colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.ACTIONS));
  result.push(
    colName(modelProps.canEdit, modelProps.gridFns.updateTextField, ActionField.name, "", {
      width: 500,
      autoHeight: true
    })
  );
  result.push(colDescription(modelProps.canEdit, modals.showEditActionDescriptionFN));
  result.push(
    colProgressStatus(
      modelProps.canEdit,
      modelProps.progressStatuses,
      modelProps.gridFns.updateIdField,
      ActionField.progressStatus,
      {}
    )
  );
  result.push(colStartDate(modelProps.canEdit, modelProps.gridFns.updateDateField, ActionField.startDate, {}));
  result.push(colEndDate(modelProps.canEdit, modelProps.gridFns.updateDateField, ActionField.endDate, {}));
  result.push(
    colOwner(modelProps.canEdit, modelProps.projectTeamMembers, modelProps.gridFns.updateIdField, ActionField.owner, {})
  );
  result.push(colAssignedTo(modelProps.canEdit, modelProps.projectTeamMembers, modelProps.gridFns.updateIdField));
  result.push(colProjectStakeholderOwners(modelProps.canEdit, SHOW_STAKEHOLDER_OWNERS_MODAL(ModalContext)));
  result.push(colActionTypes(modelProps.canEdit, modelProps.actionTypes, modelProps.gridFns.updateIdField));
  result.push(colNoteCount(modelProps.canEdit, modals.showNotesModalFN));
  result.push(colTags(modelProps.canEdit, modals.showTagsModalFN));

  // Should only be added if the user has permissions to see/edit them
  if (modelProps.userCanViewImpacts) {
    result.push(colImpacts(modelProps.canEdit, modals.showImpactsModalFN));
    result.push(colBusinessAreasFromImpacts());
  }

  // Should only be added if the user has permissions to see/edit them
  if (modelProps.userCanViewStakeholders) {
    result.push(colProjectStakeholders());
    result.push(colProjectAudiences());
  }

  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());

  return result;
};
