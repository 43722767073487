import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../components/ui/Button";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums, ProjectStakeholderField, StakeholderType } from "../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import GridToastService from "../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../services/local/gridToastService/IGridToastService";
import { SingleFormModel } from "../../../change/forms/singleFormModel/SingleForm_model";
import {
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL,
  SHOW_STAKEHOLDER_LINK_MODAL
} from "../Stakeholders/StakeholdersListView/StakeholdersView_modal";
import { GetStakeholderURLFilters } from "./AudienceView_filters";
import { getEntityNameMicroFormFields } from "./AudiencesView_fields";
import {
  SHOW_AUDIENCE_DELETE_CONFIRM_MODAL,
  SHOW_AUDIENCE_EXISTS_MODAL,
  SHOW_AUDIENCE_REVIEW_CONFIRM_MODAL
} from "./AudiencesView_modal";
import ColumnDefsApi from "../../../../services/api/v2/columnDefs/ColumnDefs.api";
import { AudienceGridViewModel } from "./AudienceGridView/AudienceGridView_model";
import Pages from "../../../../routes/InsightRoutes";

export class AudiencesViewModel extends BaseModel {
  projectId: number;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable.ref gridAudiences: FP.Entities.IProjectAudienceSummary[];
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  authUser: FP.Entities.IUser;
  httpProgress: IProgressIndicatorModel;
  gridToastService: IGridToastService;
  microFormModel: SingleFormModel;
  toasterService: IToasterService;
  gridApi: any;
  @observable.ref selectedAudiences: number[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalContextModel;
  navigate: any;
  gridModel: AudienceGridViewModel;
  @observable.ref columnDefs: any[];
  filterModel: {};
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.impacts"),
      onAction: ev => {
        this.showLinkToImpact();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser, navigate: any) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = ToasterService;
    this.modalService = ModalContext;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.httpProgress = ProgressIndicatorModel;
    this.gridToastService = GridToastService;
    this.setMicroAddForm();
    this.navigate = navigate;
  }

  onMount = async () => {
    await this.loadColumnDefs();
    await this.loadStakeholderAndAudienceCounts();
  };

  onUnmount = () => {};

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  onGridReady(gridReadyEvent: GridReadyEvent): void {
    this.filterModel = {
      ...GetStakeholderURLFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  }

  updateSelectedAudiences = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedAudiences = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setMicroAddForm = () => {
    this.microFormModel = new SingleFormModel();
    this.microFormModel.className = "mr-2";
    this.microFormModel.formFields = getEntityNameMicroFormFields(
      this.organisationId,
      this.projectId,
      this.addAudienceToProject
    );
  };

  loadColumnDefs = async () => {
    let res = await ColumnDefsApi.getAudiencesColumnDefs(this.organisationId);
    this.setColumnDefs(res.payload);
    this.gridModel = new AudienceGridViewModel(this.organisationId, this.projectId, this.authUser, res.payload);
    this.setIsLoading(false);
  };

  @action
  setColumnDefs = (columnDefs: any[]) => (this.columnDefs = columnDefs);

  addAudienceToProject = async audience => {
    this.showBringAudienceModal(audience);
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.navigate(
        Pages.projects.stakeholders.visualisations.parameters.generateLink(this.organisationId, this.projectId, 1, 0)
      );
      return;
    }
    if (newTabIndex === 0) {
      this.navigate(Pages.projects.stakeholders.listView.generateLink(this.organisationId, this.projectId));
      return;
    }
    this.navigate(Pages.projects.audiences.listView.generateLink(this.organisationId, this.projectId));
  };

  showConfirmReviewModal = () => {
    return SHOW_AUDIENCE_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedAudiences,
      this.reviewStakeholderRange,
      this.toasterService,
      this.deselectRows
    );
  };

  showLinkStakeholderModal = () => {
    return SHOW_STAKEHOLDER_LINK_MODAL(this.modalService, this.projectId, StakeholderType.AUDIENCE);
  };

  showLinkToImpact = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedAudiences);
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  showBringAudienceModal = async (audience: FP.Entities.IStakeholder) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectStakeholderProvider.getDeletedStakeholder(
      this.organisationId,
      this.projectId,
      audience.id
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (res.payload) {
      SHOW_AUDIENCE_EXISTS_MODAL(
        this.modalService,
        res,
        audience,
        this.activateExistingAudience,
        this.addProjectAudience
      );
      return;
    } else {
      this.addProjectAudience(audience);
    }
  };

  @action
  deleteFieldData = async (stakeholderId: number, actionField: ProjectStakeholderField) => {
    const res = await this.projectStakeholderProvider.deleteField(
      this.organisationId,
      this.projectId,
      stakeholderId,
      actionField
    );
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  activateExistingAudience = async (projectAudience: FP.Entities.IProjectStakeholder) => {
    this.httpProgress.showTopProgressBarVisible();
    projectAudience.lifecycleStatus = Enums.LifecycleStatus.Active;
    let res = await this.projectStakeholderProvider.update(
      this.organisationId,
      projectAudience.project.id,
      projectAudience.stakeholder.id,
      projectAudience
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) {
      return;
    }
  };

  addProjectAudience = async s => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.projectStakeholderProvider.create(this.organisationId, this.projectId, {
      stakeholderId: s.id,
      projectId: this.projectId
    } as any);
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;
  };

  showStakeholderConfirmDeleteModal = () => {
    return SHOW_AUDIENCE_DELETE_CONFIRM_MODAL(this.modalService, this.selectedAudiences, this.removeAudiences);
  };

  removeAudiences = async () => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectStakeholderProvider.deleteRange(
      this.organisationId,
      this.projectId,
      this.selectedAudiences
    );
    this.loadStakeholderAndAudienceCounts();
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;
    return res.payload;
  };

  reviewStakeholderRange = async (projectAudienceIds: number[], comment: string) => {
    let res = await this.projectStakeholderProvider.reviewRange(
      this.organisationId,
      this.projectId,
      projectAudienceIds,
      comment
    );

    if (!res || res.isError) return;
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );
    if (!res || res.isError) return;
    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-audiences-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedAudiences && this.selectedAudiences.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };
}
