import { Enums } from "@flightpath/coreui/dist/enums";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { ButtonIcon, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { Input } from "@flightpath/coreui/dist/ui/_forms/Input";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { IF } from "../../../../../components/hoc/If";
import { ButtonIconDropdown } from "../../../../../components/ui/ButtonIconDropdown";
import { ConnectedUserAvatars } from "../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { GridView } from "../../../../../core/grids/GridView_view";
import I18n from "../../../../../core/localization/I18n";
import { EntityTypes, UiSizes } from "../../../../../enums";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProcessesViewModel } from "./ProcessesView_model";
import "./_processes.scss";
export interface IProcessesViewProps {
  model?: ProcessesViewModel;
}

const ProcessesView: React.FunctionComponent<IProcessesViewProps> = observer(({ model: m }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const wrapTextKey = `projects-${projectId}-${EntityTypes.PROCESSES}-wrap-text`;
  const storageService = LocalStorageService;
  const navigate = useNavigate();
  const authUser = useFlightPathUser();
  const [hasFilters, setHasFilters] = useState(false);
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const { projectProcessStore } = useStores();
  const filterRef = useRef(null);
  const [model] = useState(
    () => m || new ProcessesViewModel(!!projectId ? +projectId : null, organisationId, authUser, navigate)
  );

  useEffect(() => {
    projectProcessStore.invokeDataLoad(organisationId, +projectId, authUser);
    model.onMount();
    return () => {
      projectProcessStore.invokeUserLeft(organisationId, +projectId, authUser);
      model.onUnmount();
    };
    // eslint-disable-next-line
  }, [model, projectId, organisationId]);

  return (
    <div className="processes-view">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex" style={{ height: "32px" }}>
            <ButtonIcon
              className="float-left mr-2"
              type={ButtonTypes.PRIMARY}
              size={UiSizes.SM}
              onClick={() => {
                model.showCoreProcessesLinkModal();
              }}
              symbol={IconSymbols.CircleDownArrow}
            >
              {I18n.t("phrases.importProcessesFromTemplate")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={model.selectedProcesses.length === 0}
              onClick={model.selectedProcesses.length > 0 ? model.showProcessConfirmDeleteModal : null}
              className="mr-2 align-self-start"
              symbol={IconSymbols.Trash}
            >
              {I18n.t("phrases.delete")}
            </ButtonIcon>

            <ButtonIconDropdown
              iconSymbol={IconSymbols.Link}
              buttonProps={{
                size: UiSizes.SM,
                type: ButtonTypes.LINK,
                style: { display: "block" },
                className: "p-0 mr-2",
                isDisabled: model.selectedProcesses.length === 0
              }}
              tooltipWidth={210}
              actions={model.uiActions}
            >
              {I18n.t("phrases.link")}
            </ButtonIconDropdown>
            <span className="vertical-line"></span>
            <ButtonIcon
              type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              onClick={() => {
                setIsTextWrapToggled(!isTextWrapToggled);
                filterRef.current.toggleTextWrapper();
              }}
              symbol={IconSymbols.TextWrap}
              className="mr-2 align-self-start"
            >
              {I18n.t("phrases.textWrap")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={!hasFilters}
              onClick={() => filterRef.current.clearFilters()}
              className="mr-2 align-self-start"
              symbol={IconSymbols.ClearFilter}
            >
              {I18n.t("phrases.clearFilters")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={!hasColumnChanges}
              onClick={() => filterRef.current.resetColumns()}
              className="mr-2 align-self-start"
              symbol={IconSymbols.MonitorBack}
            >
              {I18n.t("phrases.resetColumns")}
            </ButtonIcon>
          </div>
          <div className="col-3">
            <ConnectedUserAvatars connectedUsers={projectProcessStore.connectedUsers} />
          </div>
          <div className="col-2">
            <div className="text-right">
              <Input
                autoFocus={true}
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                style={{ height: 32 }}
              />
            </div>
          </div>
        </div>
      </div>
      <IF condition={projectProcessStore.isLoading || model.isLoading}>
        <PositionedSpinner />
      </IF>
      <IF condition={!projectProcessStore.isLoading && !model.isLoading}>
        <GridView
          context={{}}
          data={projectProcessStore.data}
          users={projectProcessStore.connectedUsers}
          onCellClicked={projectProcessStore.updateUserSelectedCell(organisationId, +projectId)}
          onCellEditModeChange={projectProcessStore.updateUserSelectedCell(organisationId, +projectId)}
          onSelectionChanged={model.updateSelectedProcesses}
          onGridReady={model.onGridReady}
          model={model.gridModel}
          isLoading={projectProcessStore.isLoading}
          ref={filterRef}
          filterHasChangedFn={setHasFilters}
          columnOrderHasChangedFn={setHasColumnChanges}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});

export { ProcessesView };
