import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Panel } from "../../../../components/ui/Panel";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ChangeImpactAssessment } from "./ChangeImpactAssessment/ChangeImpactAssessment_view";
import { ImpactReportingViewModel } from "./ImpactReportingView_model";
import { TopImpactsReportingBar } from "./TopImpactsReportingBar_view";
import { useFlightPathUser } from "../../../../core/auth_insight";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";

export interface ImpactReportingViewProps {
  model?: ImpactReportingViewModel;
}

export const ImpactReportingView: React.FC<ImpactReportingViewProps> = observer(({ model: m }) => {
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string; page: string }>();
  const [model, setModel] = useState(
    () => m || new ImpactReportingViewModel(organisationId, +projectId, authUser, navigate)
  );
  const modelProjectId = model.projectId;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ImpactReportingViewModel(organisationId, +projectId, authUser, navigate));
    }
  }, [projectId, modelProjectId, organisationId, model, authUser, navigate]);

  return (
    <div>
      <div className="container-fluid">
        <TopImpactsReportingBar
          ragData={model.ragData}
          impactCount={model.impactCount}
          dataConfidence={model.averageDataConfidence}
          organisationId={model.organisationId}
          projectId={model.projectId}
        />
        <div className="row">
          <div className="col-md-6">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byTypeChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byTypeChartDataProps.Data}
                  dataConfidence={model.byTypeChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByType")}
                  gapAnalysisClick={model.byTypeChartDataProps.gapAnalysisOnClickFunction(
                    ImpactColDefFieldNamesEnum.ImpactTypes
                  )}
                  drillThroughClick={model.byTypeChartDataProps.drillThroughOnClickFunction(
                    ImpactColDefFieldNamesEnum.ImpactTypes
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByType"))}
                />
              )}
            </Panel.Panel>
          </div>
          <div className="col-md-6">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byLocationChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byLocationChartDataProps.Data}
                  dataConfidence={model.byLocationChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByLocation")}
                  gapAnalysisClick={model.byLocationChartDataProps.gapAnalysisOnClickFunction(
                    ImpactColDefFieldNamesEnum.Locations
                  )}
                  drillThroughClick={model.byLocationChartDataProps.drillThroughOnClickFunction(
                    ImpactColDefFieldNamesEnum.Locations
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByLocation"))}
                />
              )}
            </Panel.Panel>
          </div>
        </div>
        <div className="row mb-4">
          <div className={"col-md-6"}>
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byBusinessAreaChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byBusinessAreaChartDataProps.Data}
                  dataConfidence={model.byBusinessAreaChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByBusinessArea")}
                  gapAnalysisClick={model.byBusinessAreaChartDataProps.gapAnalysisOnClickFunction(
                    ImpactColDefFieldNamesEnum.BusinessAreas
                  )}
                  drillThroughClick={model.byBusinessAreaChartDataProps.drillThroughOnClickFunction(
                    ImpactColDefFieldNamesEnum.BusinessAreas
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByBusinessArea"))}
                />
              )}
            </Panel.Panel>
          </div>
          <div className="col-md-6">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byOwnerAreaChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byOwnerAreaChartDataProps.Data}
                  dataConfidence={model.byOwnerAreaChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByOwner")}
                  gapAnalysisClick={model.byOwnerAreaChartDataProps.gapAnalysisOnClickFunction(
                    CommonColDefFieldNamesEnum.Owner
                  )}
                  drillThroughClick={model.byOwnerAreaChartDataProps.drillThroughOnClickFunction(
                    CommonColDefFieldNamesEnum.Owner
                  )}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByOwner"))}
                />
              )}
            </Panel.Panel>
          </div>
        </div>
        {/* <div className="row mb-4">
          <div className="col-md-4">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byStakeholderSentimentChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byStakeholderSentimentChartDataProps.Data}
                  dataConfidence={model.byStakeholderSentimentChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByAverageStakeholderSentiment")}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByAverageStakeholderSentiment"))}
                />
              )}
            </Panel.Panel>
          </div>
          <div className="col-md-4">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byStakeholderCommitmentChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byStakeholderCommitmentChartDataProps.Data}
                  dataConfidence={model.byStakeholderCommitmentChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByAverageStakeholderCommitment")}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByAverageStakeholderCommitment"))}
                />
              )}
            </Panel.Panel>
          </div>
          <div className="col-md-4">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
              {!model.byStakeholderReceptivenessChartDataProps.IsLoading && (
                <ChangeImpactAssessment
                  impacts={model.byStakeholderReceptivenessChartDataProps.Data}
                  dataConfidence={model.byStakeholderReceptivenessChartDataProps.DataConfidence}
                  heading={I18n.t("visualisations.impactsByAverageStakeholderReceptiveness")}
                  noDataLabel={noDataLabel(I18n.t("visualisations.impactsByAverageStakeholderReceptiveness"))}
                />
              )}
            </Panel.Panel>
          </div>
        </div> */}
      </div>
    </div>
  );
});

const noDataLabel = (label: string) => {
  return `No ${label} Data`;
};
