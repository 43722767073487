import React, { useEffect, useState } from "react";
import { IF } from "../../../../components/hoc/If";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionGridModel } from "./ActionGrid_model";
import { ActionGridViewModel } from "./ActionGridView_model";
import { Button, ButtonTypes } from "../../../../components/ui/Button";
import { Enums, UiSizes } from "../../../../enums";
import { observer } from "mobx-react";
import { Input } from "../../../../components/ui/_forms/Input";
import { IconSymbols } from "../../../../components/ui/Icon";
import { useFlightPathUser } from "../../../auth_insight";
import { GridView } from "../../GridView_view";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ButtonIcon, ButtonIconShapes } from "@flightpath/coreui/dist/ui/Button";
import ModalContext from "../../../modalZ/context/ModalContext";

export const ActionGrid: React.FC<{ projectId: number; onAssignSuccess: (selectedActions: number[]) => void }> =
  observer(({ projectId, onAssignSuccess }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const [model] = useState(() => new ActionGridModel(projectId, organisationId, authUser));
    const [gridModel] = useState(() => new ActionGridViewModel(organisationId, projectId, authUser));

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [model]);

    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className="impact-action-grid">
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-10">
              <Button
                type={ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                isDisabled={model.selectedActions.length === 0}
                className="mt-2 mr-2 align-self-start"
                onClick={async () => {
                  onAssignSuccess(model.selectedActions);
                }}
              >
                Assign
              </Button>

              <Input
                autoFocus={true}
                className="mt-2"
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                iconClass="mt-1"
                style={{ height: 32 }}
              />
            </div>
            <div className="col-2 d-flex justify-content-end align-items-end px-0">
              <ButtonIcon
                size={UiSizes.SM}
                onClick={ModalContext.hide}
                type={ButtonTypes.OUTLINE_PRIMARY}
                shape={ButtonIconShapes.RECTANGLE}
                symbol={IconSymbols.Close}
              />
            </div>
          </div>
        </div>
        <IF condition={!model.isLoading}>
          <GridView
            context={{}}
            data={model.gridActions}
            users={[]}
            onCellClicked={() => {}}
            onCellEditModeChange={() => {}}
            onSelectionChanged={model.updateSelectedImpacts}
            onGridReady={model.onGridReady}
            model={gridModel}
            isLoading={gridModel.isLoading}
            // ref={filterRef}
            // filterHasChangedFn={setHasFilters}
            // isExternalFilterPresent={model.isExternalFilterPresent}
            // doesExternalFilterPass={model.doesExternalFilterPass}
            overlayNoRowsTemplate={null}
          />
        </IF>
      </div>
    );
  });
