import { createConsoleLoggerDelegate, createLogger } from "./core/logger/Logger";
import { ILogger } from "./core/logger/ILogger";
import { Enums } from "./enums";
import moment from "moment";
import "moment/locale/en-gb";
// MobX batching configuration
moment.locale("en-gb");

export interface StartUpConfig {
  isDebug: boolean;
  logger?: ILogger;
  configureServices?(): void;
  configureApp?(): void;
}

export async function startUp({ isDebug, logger, configureServices, configureApp }: StartUpConfig) {
  logger = logger || createLogger(createConsoleLoggerDelegate(), isDebug ? Enums.LogLevel.All : Enums.LogLevel.Error);

  if (configureServices) {
    configureServices();
  }

  if (configureApp) {
    configureApp();
  }
}
