import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../../components/ui/ReviewModal";
import { ImpactsUploader } from "../../../../../../pages/insight/projects/ImpactsUploader/ImpactsUploader_view";
import { ImpactBulkUpdateSidebarModel } from "../../../../../../pages/insight/projects/ImpactsView/modals/ImpactBulkUpdateSidebar/impactBulkUpdateSidebar_model";
import { BulkUpdateImpactsSidebar } from "../../../../../../pages/insight/projects/ImpactsView/modals/ImpactBulkUpdateSidebar/impactBulkUpdateSidebar_view";
import { ImpactBusinessAreasSidebar } from "../../../../../../pages/insight/projects/ImpactsView/modals/impactBusinessAreasSidebar/ImpactBusinessAreasSidebar_view";
import { ImpactLocationsSidebar } from "../../../../../../pages/insight/projects/ImpactsView/modals/impactLocationsSidebar/ImpactLocationsSidebar_view";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridSideModalTitle } from "../../../../GridSideModals";
import { ActionGrid } from "../../../../commonGrids/ActionGrid/ActionGrid_view";
import { LabelGrid } from "../../../../commonGrids/LabelGrid/LabelGrid_view";
import { StakeholderGrid } from "../../../../commonGrids/StakeholderGrid/StakeholderGrid_view";
import { ImpactGroupsGridView } from "../ImpactGroupsGrid/ImpactGroupsGridView_view";

export const IMPACT_UPLOADER_MODAL_CONFIG = () => {
  return {
    showClose: true,
    title: <h1 className="mt-3 px-3">Upload impact groups</h1>,
    content: (
      <>
        <img
          className="beta-ribbon-img"
          src="/images/assets/beta-ribbon.png"
          alt=""
          style={{
            height: 50,
            width: 50,
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
        <ImpactsUploader />
      </>
    )
  };
};

export const IMPACT_BULK_EDIT_MODAL_CONFIG = (projectId: number, organisationId: number, selectedItems: number[]) => {
  return {
    showClose: true,
    title: (
      <GridSideModalTitle
        name={I18n.t("phrases.updatingInBulk")}
        field={`${I18n.t("phrases.noOfImpactsSelected", {
          plural: selectedItems.length > 1 ? "s" : "",
          num: selectedItems.length
        })}`}
      />
    ),
    content: (
      <>
        <BulkUpdateImpactsSidebar
          model={new ImpactBulkUpdateSidebarModel(ModalContext.hide, projectId, organisationId, selectedItems)}
        ></BulkUpdateImpactsSidebar>
      </>
    )
  };
};

export const SHOW_IMPACT_DELETE_CONFIRM_MODAL = (
  itemIds: number[],
  removeItems: (itemIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeImpactGroupFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmImpactGroupRangeRemove", { name: itemIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeItems(itemIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_IMPACT_REVIEW_CONFIRM_MODAL = (
  itemsIds: number[],
  reviewImpactRange: (itemsIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    ModalContext.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewImpactRange(itemsIds, comment);
        ModalContext.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const LINK_TO_STAKEHOLDERS_MODAL_CONFIG = (
  projectId: number,
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    title: null,
    content: <StakeholderGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />
  };
};

export const LINK_TO_ACTIONS_MODAL_CONFIG = (
  projectId: number,
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    title: null,
    content: <ActionGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />
  };
};

export const LINK_TO_LOCATIONS_MODAL_CONFIG = (onAssignSuccess: (itemIds: number[]) => Promise<any>) => {
  return {
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Assign locations to selected impacts.</h3>
      </div>
    ),
    content: <ImpactLocationsSidebar onSubmit={onAssignSuccess} />
  };
};

export const LINK_TO_BUSINESS_AREAS_MODAL_CONFIG = (onAssignSuccess: (itemIds: number[]) => Promise<any>) => {
  return {
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Assign business areas to selected impacts.</h3>
      </div>
    ),
    content: <ImpactBusinessAreasSidebar onSubmit={onAssignSuccess} />
  };
};

export const LINK_TO_LABELS_MODAL_CONFIG = (
  projectId: number,
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    title: null,
    content: <LabelGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />
  };
};

export const LINK_TO_IMPACT_GROUPS_MODAL_CONFIG = (onAssignSuccess: (itemIds: number[]) => void) => {
  return {
    showClose: false,
    title: null,
    content: <ImpactGroupsGridView gridToolbarType="link-modal" onAssignSuccess={onAssignSuccess} />
  };
};
