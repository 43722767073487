import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { observer } from "mobx-react";
import I18n from "../../../../core/localization/I18n";
import { Hr } from "../../../../components/ui/Hr";
import { ImpactLevelCell } from "../../projects/impactsView/ImpactLevelCell";
import { Pill, PillTypes } from "../../../../components/ui/Pill";

export interface ImpactCardProps {
  item: FP.Entities.IImpact;
}

const ImpactCard: React.FunctionComponent<ImpactCardProps> = observer(({ item }) => {
  return (
    <Panel.Panel
      className="impact-card p-3 mb-2"
      hasShadow={true}
      background={Panel.PanelBackgrounds.BG_WHITE}
      type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
    >
      <div className="row mb-2">
        <div className="col">
          <div className="d-flex justify-content-between">
            <div className="mr-3 no-overflow align-self-center">{item.name}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="my-1" />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col d-inline-flex">
          <div className="border-right pr-3 no-flex-shrink">
            <span className="strong">{item.refNumber}</span>
          </div>
          <div className="border-right px-3 no-flex-shrink">
            <span className="strong mr-1">{I18n.t("table.level")}:</span>
            <ImpactLevelCell obj={item} className="d-inline" />
          </div>
          <div className="pl-3 d-flex">
            <span className="strong mr-1">{I18n.t("table.type")}:</span>
            {item.impactTypes?.length > 0 ? (
              <Pill type={PillTypes.INDICATOR_ACCENT_1} style={{ padding: "3px 11px" }}>
                <h4 className="mb-0 no-overflow">{item.impactTypes.map(x => x.name).join(", ")}</h4>
              </Pill>
            ) : (
              I18n.t("table.noInput")
            )}
          </div>
        </div>
      </div>
    </Panel.Panel>
  );
});

export { ImpactCard };
