import * as React from "react";
import "react-dates/initialize";
import { SingleDatePicker as SDP } from "react-dates";
import * as moment from "moment";
import { Icon, IconSymbols } from "../../Icon";
import { B } from "../../../../core/util/BootstrapHelper";
import { Enums } from "../../../../enums";

export interface SingleDatePickerProps {
  icon?: IconSymbols;
  datePickerProps?: Partial<any>; //, "onFocusChange" | "focused">
  showClearDate?: boolean;
}

const customCloseIcon = (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const SingleDatePicker: React.FunctionComponent<SingleDatePickerProps> = (props: any) => {
  const { datePickerProps } = props;
  const [focused, setFocused] = React.useState(datePickerProps.focused);
  const cls = B().bl("ui-input");

  return props.icon ? (
    <div className="ui-input">
      <SDP
        id={datePickerProps.id}
        date={datePickerProps.date}
        onDateChange={datePickerProps.onDateChange}
        focused={focused}
        customCloseIcon={customCloseIcon}
        showClearDate={props.showClearDate}
        onFocusChange={({ focused }) => setFocused(focused)}
        displayFormat={datePickerProps.displayFormat || (() => moment.localeData("en-gb").longDateFormat("L"))}
        {...datePickerProps}
      />
      <Icon className={cls.el("icon").bem} symbol={props.icon} size={Enums.UiSizes.MD} />
    </div>
  ) : (
    <>
      <SDP
        id={datePickerProps.id}
        customCloseIcon={customCloseIcon}
        date={datePickerProps.date}
        onDateChange={datePickerProps.onDateChange}
        focused={focused}
        showClearDate={props.showClearDate}
        onFocusChange={({ focused }) => setFocused(focused)}
        displayFormat={datePickerProps.displayFormat || (() => moment.localeData("en-gb").longDateFormat("L"))}
        {...datePickerProps}
      />
    </>
  );
};

export { SingleDatePicker };
