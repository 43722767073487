import React, { useState } from "react";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getActionTagFormFields } from "./ActionTagsSidebar_formFields";
import TagsApi from "../../../../../../../../services/api/v2/tags/Tags.api";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";

interface ManageActionTagProps {
  organisationId: number;
  projectId: number;
  action: FP.Entities.IAction;
  closeFn: () => void;
}

class ManageActionTagFormModel {
  formModel: SingleFormModel;

  constructor(organisationId: number, projectId: number, action: FP.Entities.IAction, closeFn: () => void) {
    this.formModel = new SingleFormModel();

    const tagsProvider = TagsApi;
    let formFields = getActionTagFormFields(tagsProvider, organisationId, projectId, action);
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          closeFn();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const ManageActionTagForm: React.FC<ManageActionTagProps> = props => {
  const { action, closeFn, projectId, organisationId } = props;
  
  let [model] = useState(() => new ManageActionTagFormModel(organisationId, projectId, action, closeFn));

  return <SingleForm model={model.formModel} />;
};
