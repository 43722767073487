import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../../components/ui/Button";
import I18n from "../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { ActionField } from "../../../../../../../enums";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../../services/api/v2/actions/Actions.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getActionDescriptionFormFields } from "./ActionDescriptionSidebar_formFields";

export class ActionDescriptionSidebarModel extends BaseModel {
  actionsProvider: IActionsApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref action: FP.Entities.IAction;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  actionId: number;
  formModel: SingleFormModel;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, actionId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.actionsProvider = ActionsApi;
    this.actionId = actionId;
    this.formModel = new SingleFormModel();
    this.closeModalFn = closeModalFn;
  }

  onMount = async () => {
    await this.loadAction(this.actionId);
    this.formModel.formFields = getActionDescriptionFormFields(this.action);
    this.formModel.actions = this.getFormActions();
  };

  getFormActions = () => [
    {
      id: "cancel",
      label: I18n.t("phrases.cancel"),
      onAction: ev => {
        this.closeModalFn();
      },
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.OUTLINE_PRIMARY,
        className: ""
      }
    },
    {
      id: "save",
      label: I18n.t("phrases.save"),
      onAction: async () => {
        let descriptionValue = this.formModel.getFormKeyExtractedValue()["description"];
        this.closeModalFn();
        let res = await this.actionsProvider.updateField(
          this.organisationId,
          this.projectId,
          this.actionId,
          descriptionValue,
          0,
          null,
          0,
          ActionField.description
        );
        if (!res || res.isError) return;
      },
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.PRIMARY,
        className: "ml-2"
      }
    }
  ];

  @action
  setAction = (action: FP.Entities.IAction) => {
    this.action = action;
    this.isLoading = false;
  };

  @action
  loadAction = async (actionId: number) => {
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;

    this.setAction(res.payload);
  };
}
