import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../apps/insight/stores/RootStore";
import { IF } from "../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { ButtonIconDropdown } from "../../../../components/ui/ButtonIconDropdown";
import { IconSymbols } from "../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Tab, Tabs } from "../../../../components/ui/Tabs";
import { ConnectedUserAvatars } from "../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../core/auth_insight";
import { GridView } from "../../../../core/grids/GridView_view";
import I18n from "../../../../core/localization/I18n";
import { EntityTypes, UiSizes } from "../../../../enums";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { AudiencesViewModel } from "./AudiencesView_model";

export const AudiencesView: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId } = useParams<{ projectId: string }>();
  const { projectAudienceStore } = useStores();
  const wrapTextKey = `projects-${projectId}-${EntityTypes.AUDIENCES}-wrap-text`;
  const storageService = LocalStorageService;
  const authUser = useFlightPathUser();
  const [hasFilters, setHasFilters] = useState(false);
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  const navigate = useNavigate();
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const filterRef = useRef(null);
  const [model, setModel] = useState(() => new AudiencesViewModel(organisationId, +projectId, authUser, navigate));

  useEffect(() => {
    projectAudienceStore.invokeDataLoad(organisationId, +projectId, authUser);
    model.onMount();
    return () => {
      projectAudienceStore.invokeUserLeft(organisationId, +projectId, authUser);
      model.onUnmount();
    };
    // eslint-disable-next-line
  }, [model]);

  useEffect(() => {
    if (model.projectId !== +projectId || model.organisationId !== organisationId) {
      setModel(new AudiencesViewModel(organisationId, +projectId, authUser, navigate));
      projectAudienceStore.canInvoke() && projectAudienceStore.invokeDataLoad(organisationId, +projectId, authUser);
    }
  }, [projectAudienceStore, organisationId, projectId, authUser, navigate, model.projectId, model.organisationId]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="audiences-view">
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={1}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount)}></Tab>
        <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.stakeholderMaps")}</h2>}
        />
      </Tabs>

      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex">
            <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
              <ButtonIcon
                type={ButtonTypes.PRIMARY}
                size={UiSizes.SM}
                onClick={model.showLinkStakeholderModal}
                symbol={IconSymbols.Plus}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.add")}
              </ButtonIcon>

              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedAudiences.length === 0}
                symbol={IconSymbols.CommentPencil}
                onClick={model.selectedAudiences.length > 0 ? model.showConfirmReviewModal : null}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.review")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                symbol={IconSymbols.Trash}
                isDisabled={model.selectedAudiences.length === 0}
                onClick={model.selectedAudiences.length > 0 ? model.showStakeholderConfirmDeleteModal : null}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.remove")}
              </ButtonIcon>
              <ButtonIconDropdown
                iconSymbol={IconSymbols.Link}
                buttonProps={{
                  size: UiSizes.SM,
                  type: ButtonTypes.LINK,
                  className: "p-0 mr-2",
                  isDisabled: model.selectedAudiences.length === 0
                }}
                actions={model.actions}
              >
                {I18n.t("phrases.link")}
              </ButtonIconDropdown>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedAudiences.length === 0}
                onClick={model.selectedAudiences.length > 0 ? model.exportRows : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Download}
              >
                {I18n.t("phrases.export")}
              </ButtonIcon>
              <span className="vertical-line"></span>
              <ButtonIcon
                type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                onClick={() => {
                  setIsTextWrapToggled(!isTextWrapToggled);
                  filterRef.current.toggleTextWrapper();
                }}
                symbol={IconSymbols.TextWrap}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.textWrap")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasFilters}
                onClick={() => filterRef.current.clearFilters()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.ClearFilter}
              >
                {I18n.t("phrases.clearFilters")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasColumnChanges}
                symbol={IconSymbols.MonitorBack}
                onClick={() => filterRef.current.resetColumns()}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.resetColumns")}
              </ButtonIcon>
            </CanEdit>
          </div>
          <div className="col">
            <ConnectedUserAvatars connectedUsers={projectAudienceStore.connectedUsers} />
          </div>
        </div>
      </div>

      <IF condition={model.isLoading}>
        <PositionedSpinner />
      </IF>
      <IF condition={!model.isLoading}>
        <GridView
          context={{ deleteFn: model.deleteFieldData }}
          data={projectAudienceStore.data}
          users={projectAudienceStore.connectedUsers}
          onCellClicked={projectAudienceStore.updateUserSelectedCell(organisationId, +projectId)}
          onCellEditModeChange={projectAudienceStore.updateUserSelectedCell(organisationId, +projectId)}
          onSelectionChanged={model.updateSelectedAudiences}
          model={model.gridModel}
          isLoading={projectAudienceStore.isLoading || model.isLoading}
          onGridReady={model.onGridReady}
          filterHasChangedFn={val => {
            setHasFilters(val);
          }}
          columnOrderHasChangedFn={setHasColumnChanges}
          ref={filterRef}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});
