import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ActionDescriptionSidebarModel } from "./ActionDescriptionSidebar_model";

interface ActionDescriptionSidebarProps {
  model: ActionDescriptionSidebarModel;
}

export const ActionDescriptionSidebar: React.FC<ActionDescriptionSidebarProps> = observer(({ model }) => {
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
