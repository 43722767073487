import { action, makeObservable, observable } from "mobx";
import { PROGRESS_STATUS_OPTIONS } from "../../../../../../constants";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { GetGridFns } from "../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../core/grids/IGridModel";
import I18n from "../../../../../../core/localization/I18n";
import { EntityTypes } from "../../../../../../enums";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../../../services/api/v2/actionTypes/ActionTypes.api";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../services/api/v2/actions/Actions.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import ActionsHub, { ActionsHub as IActionsHub } from "../../../../../../services/hubs/ActionsHub/Actions_hub";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { GetActionGridViewColumns } from "./AcGridView_columns";
import { getActionGridModals } from "./modals/AcGridView_modals";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";

export class AcGridViewModel implements IGridModel {
  modalService: IModalContextModel;
  organisationId: number;
  projectId: number;
  @observable.ref actionTypes: FP.Entities.IActionType[];
  @observable.ref projectTeamMembers: any[];
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypeProvider: IActionTypesApi;
  actionsProvider: IActionsApi;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  actionsHub: IActionsHub;
  userCanViewImpacts: boolean;
  type: EntityTypes;
  gridToastService: IGridToastService;
  userCanViewStakeholders: boolean;
  colDefs: FP.Entities.IColumnDef[];

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    colDefs: FP.Entities.IColumnDef[]
  ) {
    makeObservable(this);
    this.actionTypeProvider = ActionTypesApi;
    this.actionsProvider = ActionsApi;
    this.modalService = ModalContext;
    this.colDefs = colDefs;
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.actionsHub = ActionsHub;
    this.gridToastService = GridToastService;
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.type = EntityTypes.ACTIONS;
    this.mapProgressStatuses();
  }

  onMount = async () => {
    await this.loadActionTypes();
    await this.loadProjectTeamMembers();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };

  getColumnConfig = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );

    return GetActionGridViewColumns({
      userCanViewImpacts: this.userCanViewImpacts,
      progressStatuses: this.progressStatuses,
      actionTypes: this.actionTypes,
      projectTeamMembers: this.projectTeamMembers,
      modals: getActionGridModals(this.organisationId, this.projectId),
      canEdit: canEditActions,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: GetGridFns(this.actionsProvider as any, this.organisationId, this.projectId),
      userCanViewStakeholders: this.userCanViewStakeholders
    });
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypeProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.setActionTypes(res.payload);
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setActionTypes = (actionTypes) => {
    this.actionTypes = actionTypes;
  }
  @action
  setProjectTeamMembers = (projTeamMembers) => {
    this.projectTeamMembers = projTeamMembers;
  }

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
