import { makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { GridTypes } from "../../../../enums";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../services/api/v2/impactGroups/ImpactGroups.api";
import { IGridModel } from "../../IGridModel";
import { GetImpactGroupGridViewColumns } from "./ImpactGroupGrid_columns";

export class ImpactGroupGridViewModel implements IGridModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = false;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactGroupsProvider: IImpactGroupsApi;
  authUser: FP.Entities.IUser;
  userCanViewImpacts: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  userCanViewStakeholders: boolean;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.impactGroupsProvider = ImpactGroupsApi;
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
  }

  onMount = async () => {};

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetImpactGroupGridViewColumns({
      userCanViewImpacts: this.userCanViewImpacts,
      progressStatuses: this.progressStatuses,
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: null,
      userCanViewStakeholders: this.userCanViewStakeholders,
      pinned: null
    });
  };
}
