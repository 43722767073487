import { FORM_COL } from "../../../../../../../constants";
import {
  ISearchTreePickerModel,
  ITreeDataItem
} from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../../../../core/forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";

export const getImpactBusinessAreasSidebarFormFields = (locationData: ITreeDataItem[]) => {
  const businessAreas: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "businessAreas",
    value: locationData.filter(e => e.isChecked === true),
    options: locationData,
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  let fields = [];
  fields.push(businessAreas);

  return generateFormFieldsFromJson(fields);
};
