import React, { useMemo } from "react";
import { Button } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../../core/localization/I18n";

export const StakeholderEmail: React.FC<{ stakeholders: FP.Entities.IProjectStakeholderSummary[] }> = ({
  stakeholders
}) => {
  const emailList = useMemo(() => stakeholders.map(e => e.email).join(","), [stakeholders]);
  return (
    <div className="stakeholder-email container-fluid py-6">
      <div className="row">
        <div className="col">
          <h1 className="mb-1">Email Addresses</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ul className="my-0 mb-3">
            {stakeholders.length > 0
              ? stakeholders.map(e => {
                  return (
                    <li className="stakeholder-email__list" key={e.id}>
                      <p className="mb-0">{e.email}</p>
                    </li>
                  );
                })
              : I18n.t("phrases.stakeholders_noEmailAddresses")}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Button
            onClick={() => {
              navigator.clipboard.writeText(emailList);
            }}
            className="mr-2"
          >
            {I18n.t("phrases.copyToClipboard")}
          </Button>
        </div>
      </div>
    </div>
  );
};
