import { ButtonIcon, ButtonTypes, LinkButton, LinkButtonIcon } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../components/ui/ReviewModal";
import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../../core/grids/GridSideModals";
import { LabelGrid } from "../../../../../core/grids/commonGrids/LabelGrid/LabelGrid_view";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { Animations } from "../../../../../core/util/Animations";
import { Enums } from "../../../../../enums";
import Pages from "../../../../../routes/InsightRoutes";
import { QUERY_STRING_PARAMS } from "../../../../../services/local/queryStringService/QueryStringService";
import { ActionsUploader } from "../ActionsUploader/ActionsUploader_view";
import { ImpactGrid } from "../ImpactGrid/ImpactGrid_view";
import { ActionBulkUpdateSidebarModel } from "./AcGridView/modals/ActionBulkUpdateSidebar/ActionBulkUpdateSidebar_model";
import { BulkUpdateActionsSidebar } from "./AcGridView/modals/ActionBulkUpdateSidebar/ActionBulkUpdateSidebar_view";
import { ActionNotesSideBar } from "./AcGridView/modals/ActionNotesSidebar";
import { StakeholderEmail } from "./AcGridView/modals/StakeholderEmail/StakeholderEmail_view";
import { StakeholderOnwersSidebar } from "./AcGridView/modals/stakeholderSidebar/StakeholdersSidebar_view";

export const SHOW_NOTES_MODAL = (
  modalService: IModalContextModel,
  organisationId: number,
  projectId: number,
  actionId: number
) => {
  // const initUrl = `/organisations/${organisationId}/projects/${projectId}/actions`;
  const initUrl = Pages.projects.actions.listView.generateLink(organisationId, projectId);
  modalService.show({
    showClose: false,
    title: (
      <div className="d-flex mt-6 mb-5">
        <LinkButton
          id="ViewDetailsButton"
          className="ml-auto mr-1"
          href={`${initUrl}/${actionId}`}
          onClick={modalService.hide}
        >
          {I18n.t("phrases.viewDetails")}
        </LinkButton>
        <LinkButtonIcon
          key="2"
          className="mr-1"
          type={ButtonTypes.OUTLINE_PRIMARY}
          iconSize={Enums.UiSizes.SM}
          symbol={IconSymbols.Pencil}
          onClick={modalService.hide}
          href={`${initUrl}/${actionId}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
        />
        <ButtonIcon
          key="3"
          type={ButtonTypes.OUTLINE_PRIMARY}
          iconSize={Enums.UiSizes.SM}
          symbol={IconSymbols.Close}
          onClick={modalService.hide}
        />
      </div>
    ),
    content: <ActionNotesSideBar projectId={projectId} actionId={actionId} organisationId={organisationId} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_STAKEHOLDER_OWNERS_MODAL = (modalService: IModalContextModel) => action => {
  modalService.show({
    showClose: true,
    title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.ownerNames")} />,
    content: <StakeholderOnwersSidebar actionId={action.id} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_LABELS_LINK_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  onAssignSuccess: (labelIds: number[]) => Promise<any>
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <LabelGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_ACTION_DELETE_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  actionIds: number[],
  removeActionRange: (actionIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeActionFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmActionRangeRemove", { name: actionIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await removeActionRange(actionIds);
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_ACTION_REVIEW_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  actionIds: number[],
  reviewActionRange: (actionIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewActionRange(actionIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_CONFIRM_CREATION_MODAL = (modalService: IModalContextModel, name): Promise<boolean> => {
  return new Promise(async resolve => {
    await modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.createSameNameAction")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        resolve(true);
      },
      () => {
        modalService.hide();
        resolve(false);
      }
    );
  });
};

export const SHOW_IMPACT_LINK_MODAL = (modalService: IModalContextModel, projectId: number, actionsIds: number[]) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <ImpactGrid projectId={projectId} onAssignSuccess={modalService.hide} actionsIds={actionsIds} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_EMAILS = (modalService: IModalContextModel, impactIds: number[], projectId: number) => {
  modalService.show({
    showClose: true,
    title: null,
    content: <StakeholderEmail impactIds={impactIds} projectId={projectId} />,
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_ACTIONS_UPLOADER_MODAL = (modalService: IModalContextModel) => {
  modalService.show({
    showClose: true,
    title: <h1 className="mt-3 px-3">Upload actions</h1>,
    content: (
      <>
        <img
          className="beta-ribbon-img"
          src="/images/assets/beta-ribbon.png"
          alt=""
          style={{
            height: 50,
            width: 50,
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
        <ActionsUploader />
      </>
    ),
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_ACTION_BULK_EDIT_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  organisationId: number,
  selectedActions: number[]
) => {
  modalService.show({
    showClose: true,
    title: (
      <GridSideModalTitle
        name={I18n.t("phrases.updatingInBulk")}
        field={`${I18n.t("phrases.noOfActionsSelected", {
          plural: selectedActions.length > 1 ? "s" : "",
          num: selectedActions.length
        })}`}
      />
    ),
    content: (
      <BulkUpdateActionsSidebar
        model={new ActionBulkUpdateSidebarModel(modalService.hide, projectId, organisationId, selectedActions)}
      ></BulkUpdateActionsSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
