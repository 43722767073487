import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import _ from "lodash";
import moment from "moment";
import { AutocompletePerson } from "../../../../../../../components/ui/AutocompletePersonOption";
import { AutocompleteOption } from "../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { BULK_UPDATE_IMPACT_LEVEL_OPTIONS, FORM_COL, PROGRESS_STATUS_OPTIONS } from "../../../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_DATEPICKER } from "../../../../../../../core/forms/controls/datePicker/DatePicker_model";
import { IDatePickerModel } from "../../../../../../../core/forms/controls/datePicker/IDatePickerModel";
import { ITextFieldModel } from "../../../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";
import { ImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";

export const getBulkUpdateImpactsFormFields = (
  impactsProvider: ImpactsApi,
  projectId: number,
  projectTeamMembers: any[]
) => {
  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")}</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    }),
    isLoading: false,
    valueLabelFn: obj => obj.label,

    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    }
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")}</label>,
    placeholder: I18n.t("placeholders.selectStartDate"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      icon: IconSymbols.Calendar,
      datePickerProps: {
        isOutsideRange: day => false
      }
    }
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "actualEndDate",
    label: <label htmlFor="actualEndDate">{I18n.t("forms.endDate")}</label>,
    placeholder: I18n.t("placeholders.selectEndDate"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["startDate", "progressStatus"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "startDate") {
        const sd = field.value;
        if (sd) {
          self.setDatepickerProps({
            isOutsideRange: day => {
              return day < sd;
            },
            initialVisibleMonth: () => (sd != null ? sd : moment())
          });
        }
      }
    },
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: IconSymbols.Calendar
    }
  };

  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: <label htmlFor={"owner"}>{I18n.t("forms.impactOwner")}</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    charInputNumber: 0,
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        if (projectTeamMembers) {
          const sortedUsers = _.orderBy(projectTeamMembers, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          model.setOptions(sortedUsers);
        }
      }
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: (e: FP.Entities.IUser) => `${e.firstName} ${e.lastName} (${e.email})`,
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12",
    noResultsFoundLabel: I18n.get("phrases.noResultsFound")
  };

  const numberImpacted: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "totalPeopleImpacted",
    label: I18n.t("grids.numberImpacted"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    placeholder: "Total People Impacted"
  };
  const impactLevel: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactLevel",
    label: <label htmlFor={"impactLevel"}>{I18n.t("forms.impactLevel")}</label>,
    placeholder: I18n.t("placeholders.impactLevel"),
    options: BULK_UPDATE_IMPACT_LEVEL_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    }),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    }
  };

  const fields = [];
  fields.push(progressStatus);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(owner);
  fields.push(numberImpacted);
  fields.push(impactLevel);

  return generateFormFieldsFromJson(fields);
};
