import { BaseModel } from "../../../../../core/util/BaseModel";

import { ButtonTypes } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import MilestonesApi, {
  MilestonesApi as IMilestonesApi
} from "../../../../../services/api/v2/milestones/Milestones.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";
import { getFormFields } from "../../../forms/milestone/MilestoneFormSection_data";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class MilestoneModalModel extends BaseModel {
  formModel: SingleFormModel;
  modalService: IModalContextModel;
  projectId: number;
  projectProvider: IProjectsApi;
  milestoneProvider: IMilestonesApi;
  milestone: FP.Entities.IMilestone;
  organisationId: number;

  constructor(organisationId: number, milestone?: FP.Entities.IMilestone, closeFn?: Function, projectId?: number) {
    super();
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.projectProvider = ProjectsApi;
    this.modalService = ModalContext;
    this.milestoneProvider = MilestonesApi;
    this.milestone = milestone;

    this.formModel = new SingleFormModel();
    this.formModel.formFields = getFormFields(this.milestone);
    this.formModel.actions = [
      {
        id: "no",
        label: I18n.t("phrases.cancel"),
        onAction: () => {
          this.modalService.hide();
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        }
      },
      {
        id: "CreateMilestoneButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let milestoneFormEl = await this.formModel.submit();
          if (milestoneFormEl) {
            let formResult = {
              ...milestoneFormEl
            };

            formResult.projectId = projectId || (this.milestone ? this.milestone.projectId : 0);

            let res;
            if (this.milestone) {
              res = await this.milestoneProvider.updateProjectMilestone(
                this.organisationId,
                this.projectId,
                this.milestone.id,
                formResult as FP.Entities.IMilestone
              );
            } else {
              res = await this.milestoneProvider.createProjectMilestone(
                this.organisationId,
                this.projectId,
                formResult as FP.Entities.IMilestone
              );
            }

            if (res) {
              closeFn && closeFn();
              this.modalService.hide();
            }
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  }

  onMount = () => {};

  onUnmount = () => {};
}
