import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../components/ui/ReviewModal";
import { gridSideModalComponentProps } from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { Animations } from "../../../../../core/util/Animations";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import { Panel } from "@flightpath/coreui";
import { ImpactGroupGrid } from "./modals/ImpactGroupGrid/ImpactGroupGrid_view";
import { SystemProcessUploader } from "./modals/SystemProcessUploader/SystemProcessUploader_view";

export const SHOW_PROCESS_REVIEW_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  processIds: number[],
  reviewProcessRange: (processIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewProcessRange(processIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_PROCESS_DELETE_CONFIRM_MODAL = (
  processIds: number[],
  removeProcessRange: (processIds: number[]) => Promise<any>
) => {
  return ModalContext.showConfirmDialog(
    <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12">
          <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
          {I18n.t("warnings.removeProcessFromProject")}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {I18n.t("phrases.confirmProcessRangeRemove", { name: processIds.length + "" })}
        </div>
      </div>
    </div>,
    I18n.t("phrases.yes"),
    I18n.t("phrases.no"),
    {
      wrapWidth: "small",
      spacing: "small",
      position: "middle",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE
      }
    },
    async () => {
      ModalContext.hide();
      await removeProcessRange(processIds);
    },
    () => {
      ModalContext.hide();
    },
    ButtonTypes.DANGER
  );
};

export const SHOW_PROCESS_IMPACT_GROUP_LINK_MODAL = (projectId: number, processIds: number[]) => {
  ModalContext.show({
    showClose: false,
    title: null,
    content: (
      <ImpactGroupGrid
        projectId={projectId}
        onAssignSuccess={ModalContext.hide}
        processIds={processIds}
      />
    ),
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_SYSTEM_PROCESS_UPLOADER_MODAL = () => {
  ModalContext.show({
    showClose: true,
    title: null,
    content: <SystemProcessUploader />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};
