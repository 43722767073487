import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { UiPortalContent } from "../../../../../../components/ui/UiPortal";
import I18n from "../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ReportingCard } from "../TopActionsReportingBar/ReportingCard";
import { ReportingPie } from "../TopActionsReportingBar/ReportingPie";
import {
  getConfidenceMetric,
  getConfidenceMetricColour,
  PIE_UI_PORTAL
} from "../TopActionsReportingBar/TopActionsReportingBar_view";
import { ActionsReportingTimelineModel } from "./ActionsReportingTimeline_model";
import { ActionTimelineHeader as ActionTimelineControls } from "./ActionTimelineControls";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { TimelineView } from "../../../../../../components/widgets/TimelineWidget/Timeline_view";
import { Animations } from "../../../../../../core/util/Animations";

interface ActionsReportingTimelineProps {
  actions: FP.Entities.IActionSummary[];
  project: FP.Entities.IProject;
}

export const ActionsReportingTimeline: React.FC<ActionsReportingTimelineProps> = observer(({ actions, project }) => {
  const { model, typeFilter, wrapperSetTypeFilter } = useFilters(actions, project);

  if (!model) return <PositionedSpinner />;

  const confidenceColourArray = [getConfidenceMetricColour(+model.confidenceMetric), "#BEBEBE"];

  return (
    <div className={`row my-4 ${Animations.FP_ZOOM_IN}`}>
      <div className="col action-timeline">
        {!model.isLoading && (
          <ReportingCard className="pt-2 pb-8">
            <ActionTimelineControls
              actionTypes={model.actionTypes}
              toggleStatusFilterFn={model.toggleProgressFilters}
              setTypeFilterFn={wrapperSetTypeFilter}
              typeFilter={typeFilter}
            />
            <div className="px-3 pb-3">
              <TimelineView
                model={model.timelineModel}
                labels={{
                  noItemsText: I18n.t("phrases.noProjectActionsOrMilestones"),
                  today: I18n.t("phrases.today"),
                  scrollLeft: I18n.t("phrases.scrollLeft"),
                  scrollRight: I18n.t("phrases.scrollRight"),
                  zoomOut: I18n.t("phrases.zoomOut"),
                  zoomIn: I18n.t("phrases.zoomIn"),
                  resetZoom: I18n.t("phrases.resetZoom")
                }}
              />
            </div>
          </ReportingCard>
        )}
      </div>
      <UiPortalContent name={PIE_UI_PORTAL}>
        {typeFilter ? (
          <ReportingPie
            data={model.confidenceData}
            metric={getConfidenceMetric(+model.confidenceMetric)}
            colourArray={confidenceColourArray}
            title={I18n.t("visualisations.filterConfidencePie", { filterName: typeFilter.name })}
          />
        ) : null}
      </UiPortalContent>
    </div>
  );
});

const useFilters = (actions: FP.Entities.IActionSummary[], project: FP.Entities.IProject) => {
  const organisationId = useCurrentOrganisationId();

  const [typeFilter, setTypeFilter] = useState(null);
  const wrapperSetTypeFilter = useCallback(
    type => {
      setTypeFilter(typeFilter?.id === type?.id ? null : type);
    },
    [setTypeFilter, typeFilter]
  );
  const [statusFilters, setStatusFilters] = useState([]);
  const wrapperSetStatusFilters = useCallback(
    status => {
      let filters = statusFilters;
      let arrayPosition = filters.indexOf(status);
      arrayPosition === -1 ? filters.push(status) : filters.splice(arrayPosition, 1);
      setStatusFilters([...filters]);
    },
    [setStatusFilters, statusFilters]
  );
  const [model, setModel] = useState(null);
  useEffect(() => {
    setModel(
      new ActionsReportingTimelineModel(
        actions,
        organisationId,
        project,
        typeFilter,
        wrapperSetTypeFilter,
        statusFilters,
        wrapperSetStatusFilters
      )
    );
  }, [actions, typeFilter, wrapperSetTypeFilter, statusFilters, wrapperSetStatusFilters, organisationId, project]);

  return {
    model,
    typeFilter,
    wrapperSetTypeFilter
  };
};
