export enum CommonColDefFieldNamesEnum {
  Selected = "selected",
  RefNumber = "refNumber",
  Name = "name",
  Owner = "owner",
  Tags = "tags",
  StartDate = "startDate",
  EndDate = "endDate",
  ProgressStatus = "progressStatus",
  CreatedBy = "createdByName",
  CreatedAt = "createdAt",
  ModifiedBy = "modifiedByName",
  UpdatedAt = "updatedAt",
  ReviewedBy = "reviewedBy",
  LastReviewedOn = "reviewedOn",
  NextReviewDue = "nextReviewDue",
  NoteCount = "noteCount",
  Description = "description",
  Impacts = "impacts",
  ProjectName = "projectName",
  ProgrammeName = "programmeName"
}

export enum ProcessColDefFieldNamesEnum {
  UniqueIdentifier = "uniqueIdentifier",
  Level1 = "level1",
  Level2 = "level2",
  Level3 = "level3",
  ScopeItem = "scopeItemNames"
}

export enum ImpactColDefFieldNamesEnum {
  ImpactTypes = "impactTypes",
  TotalPeopleImpacted = "totalPeopleImpacted",
  ImpactLevel = "impactLevel",
  BusinessAreas = "businessAreas",
  Locations = "locations",
  ImpactGroups = "impactGroups",
  Actions = "actions",
  ProjectStakeholder = "projectStakeholderNames",
  Audiences = "audiences",
  Milestones = "milestones"
}

export enum ActionColDefFieldNamesEnum {
  RagStatus = "ragStatus"
}

// false 'asIs'
// false 'toBe'
// false 'gap'

// true 'createdOn'
// true 'updatedOn'
