import { ColDef } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { CommonColDefFieldNamesEnum, ProcessColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { ProcessHierarchField } from "../../utils/GridFields";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { PillsColumnBuilder } from "../../columns/commonColumns/PillsColumn/PillsColumn_builder";
import { PILLS_COLUMN_CONFIG, PILLS_FILTER_CONFIG } from "../../columns/commonColumns/PillsColumn/PillsColumn_config";
import ProcessesApi from "../../../../../../services/api/v2/processes/Processes.api";

export interface ProcessesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewImpacts: boolean;
  columns: string[];
  onFieldUpdate: () => void;
}

export class ProcessesGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ProcessesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;

  constructor(gridProps: ProcessesGridColumnBuilderProps) {
    super(ProcessesApi, gridProps.organisationId, gridProps.projectId);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [ProcessColDefFieldNamesEnum.UniqueIdentifier]: () => this.buildUniqueIdentifierColumn(),
      [ProcessColDefFieldNamesEnum.Level1]: () => this.buildLevel1Column(),
      [ProcessColDefFieldNamesEnum.Level2]: () => this.buildLevel2Column(),
      [ProcessColDefFieldNamesEnum.Level3]: () => this.buildLevel3Column(),
      [ProcessColDefFieldNamesEnum.ScopeItem]: () =>
        this.buildPillsColumn("scopeItemNames", I18n.t("grids.scopeItemNames"))
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    return res;
  };

  buildUniqueIdentifierColumn = () => {
    let model = new SimpleTextColumnBuilder({
      field: "uniqueIdentifier",
      headerName: I18n.t("grids.uniqueIdentifier")
    })
      .makeSelectable()
      .makeEditable();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateField(ProcessHierarchField.UNIQUE_IDENTIFIER));
    }
    return model.generateColumnOptions();
  };

  buildLevel1Column = () => {
    let model = new SimpleTextColumnBuilder({ field: "level1", headerName: I18n.t("grids.level1") }).makeSelectable();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateField(ProcessHierarchField.LEVEL_1));
    }
    return model.generateColumnOptions();
  };

  buildLevel2Column = () => {
    let model = new SimpleTextColumnBuilder({ field: "level2", headerName: I18n.t("grids.level2") }).makeSelectable();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateField(ProcessHierarchField.LEVEL_2));
    }
    return model.generateColumnOptions();
  };

  buildLevel3Column = () => {
    let model = new SimpleTextColumnBuilder({ field: "level3", headerName: I18n.t("grids.level3") }).makeSelectable();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateField(ProcessHierarchField.LEVLE_3));
    }
    return model.generateColumnOptions();
  };

  buildPillsColumn = (field: string, headerName: string, modalConfig?: any) => {
    let model = new PillsColumnBuilder()
      .makeSelectable()
      .makeEditable()
      .setColumnOptions(PILLS_COLUMN_CONFIG({ field, headerName }))
      .withCellRenderer(field)
      .setFilterOptions(PILLS_FILTER_CONFIG);

    if (this.gridProps.canEdit && modalConfig) {
      const pillsColumnModal = (impact: FP.Entities.IProjectProcess) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(modalConfig(this.organisationId, this.projectId, impact))
          .generateModal();
      model.setEditableOnDoubleClick(pillsColumnModal);
      model.withOnDeleteModal(pillsColumnModal);
    }

    return model.generateColumnOptions();
  };

  updateField = (field: ProcessHierarchField) => async (entityId: number, text: string) => {
    await this.updateTextField(field, entityId, text);
    this.gridProps.onFieldUpdate();
  };
}
