import { makeObservable, observable } from "mobx";
import { IMPACT_LEVEL_OPTIONS, PROGRESS_STATUS_OPTIONS } from "../../../../../../../constants";
import { GetGridFns } from "../../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import I18n from "../../../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { GridTypes, ImpactLevel } from "../../../../../../../enums";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import ImpactsHub, { ImpactsHub as IImpactsHub } from "../../../../../../../services/hubs/ImpactsHub/Impacts_hub";
import { GetImpactGroupGridViewColumns } from "./ImpactGroupGrid_columns";

export class ImpactGroupGridViewModel extends BaseModel implements IGridModel {
  impactsProvider: IImpactsApi;
  impactsHub: IImpactsHub;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  type: GridTypes = GridTypes.PROCESS_IMPACT_GROUP_GRID;
  organisationId: number;
  projectId: number;
  userCanViewActions: boolean;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.impactsProvider = ImpactsApi;
    this.impactsHub = ImpactsHub;
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mapProgressStatuses();
    this.mapImpactLevels();
    // this.userCanViewActions = PermissionsContext.canViewField(
    //   PermissionFields.PROCESSES,
    //   organisationId,
    //   projectId
    // );
  }

  onMount = async () => {
    this.isLoading = false;
    Promise.all([this.loadProjectTeamMembers()]).then(v => {
      this.isLoading = false;
    });
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetImpactGroupGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      projectTeamMembers: [],
      impactLevels: this.impactLevels,
      progressStatuses: this.progressStatuses,
      gridFns: GetGridFns(this.impactsProvider, this.organisationId, this.projectId),
      pinned: null
    });
  };

  loadProjectTeamMembers = () => {};

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  mapImpactLevels = () => {
    this.impactLevels = IMPACT_LEVEL_OPTIONS.filter(e => e.key !== ImpactLevel.UNKNOWN).map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
