import React, { useEffect, useState } from "react";
import { MilestoneSettingsModel } from "./MilestoneSettings_model";
import { TableView } from "../../../../../core/table/Table_view";
import { observer } from "mobx-react";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";
import { useParams } from "react-router-dom";

export interface MilestoneSettingsViewProps {}

const ProjectMilestoneSettingsView: React.FunctionComponent<MilestoneSettingsViewProps> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new MilestoneSettingsModel(organisationId, +projectId));
  useEffect(() => {
    model.onMount();
  }, [model]);

  return (
    <div className={`container-fluid milestone-settings-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h1 className="d-inline-block">
            {model.project?.name} | {I18n.t("phrases.manageProject")}
          </h1>
          <LinkButton
            id="CloseProjectSettingsButton"
            type={ButtonTypes.OUTLINE_PRIMARY}
            href={`/organisations/${organisationId}/projects/${model.project?.id}`}
            className="float-right"
          >
            {I18n.t("phrases.closeSettings")}
          </LinkButton>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("phrases.manageProjectMilestonesDescription")}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <Button
            id="ShowMilestoneFormButton"
            onClick={() => {
              model.showMilestoneModal();
            }}
          >
            {I18n.t("phrases.createMilestone")}
          </Button>

          <SearchButton
            className={"float-right"}
            value={""}
            onChange={() => {}}
            // onClose={model.resetQuery}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <TableView model={model.tableModel} />
        </div>
      </div>
    </div>
  );
});

export { ProjectMilestoneSettingsView };
