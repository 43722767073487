import { observer } from "mobx-react";
import { ImpactGroupsGridView } from "../../../../../core/grids/builder/directorBuilder/grids/ImpactGroupsGrid/ImpactGroupsGridView_view";

export interface ImpactGroupsViewProps {}

const ImpactGroupsView: React.FunctionComponent<ImpactGroupsViewProps> = observer(() => {
  return (
    <div className="impact-groups-view">
      <ImpactGroupsGridView />
    </div>
  );
});

export { ImpactGroupsView };
