import { action, makeObservable, observable } from "mobx";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../../../services/api/v2/organisations/Organisations.api";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";
import LocationsApi, { LocationsApi as ILocationsApi } from "../../../../../../../services/api/v2/locations/Locations.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getImpactLocationsSidebarFormFields } from "./ImpactLocationsSidebar_fields";

export class ImpactLocationsSidebarModel extends BaseModel {
  impactTypesProvider: IImpactTypesApi;
  impactsProvider: IImpactsApi;
  modalService: IModalContextModel;
  locationsProvider: ILocationsApi;
  organisationsProvider: IOrganisationsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  @observable locationTreeData: FP.Entities.ITreeDataItem[];
  canEdit: boolean;
  @observable.ref formModel: SingleFormModel;
  onSubmit: (locationIds: number[]) => void;
  actions = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        this.submitForm();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(organisationId: number, projectId: number, onSubmit: (locationIds: number[]) => void) {
    super();
    makeObservable(this);
    this.impactTypesProvider = ImpactTypesApi;
    this.locationsProvider = LocationsApi;
    this.organisationsProvider = OrganisationsApi;
    this.organisationId = organisationId;
    this.modalService = ModalContext;
    this.onSubmit = onSubmit;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.loadSidebar();
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  submitForm = async () => {
    let res = await this.formModel.submit();
    this.onSubmit(res.locations);
    this.modalService.hide();
  };

  @action
  getOrgLocations = async () => {
    let res = await this.organisationsProvider.getLocations(this.organisationId);
    if (!res || res.isError) return;
    let k = res.payload.map(e => {
      return { ...e, label: e.name };
    });
    this.locationTreeData = k as any[];
  };

  @action
  loadSidebar = async () => {
    this.setIsLoading(true);
    await this.getOrgLocations();
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactLocationsSidebarFormFields(this.locationTreeData);
    this.formModel.actions = this.actions;
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
