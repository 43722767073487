import { observer } from "mobx-react";
import React, { useState } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { ImpactLocationsSidebarModel } from "./ImpactLocationsSidebar_model";

export interface ImpactLocationsSidebarProps {
  onSubmit: (locationIds: number[]) => void;
}

export const ImpactLocationsSidebar: React.FC<ImpactLocationsSidebarProps> = observer(({ onSubmit }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new ImpactLocationsSidebarModel(organisationId, +projectId, onSubmit));
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
