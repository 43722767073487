import { ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, GridApi, SortChangedEvent } from "ag-grid-community";
import { IGridAddon } from "./IGridAddon";
import _ from "lodash";
import LocalStorageService, {
  LocalStorageService as ILocalStorageService
} from "../../../../../../../services/local/localStorageService/LocalStorageService";
import { EntityTypes } from "../../../../../../../enums";

export interface IColumnAddonReturnProps {
  onColumnResized(event: ColumnResizedEvent<any>): void;
  onColumnPinned(event: ColumnPinnedEvent<any>): void;
  onColumnMoved(event: ColumnMovedEvent<any>): void;
  onSortChanged(event: SortChangedEvent<any>): void;
}

interface IColumnStateAddonProps {
  projectId: number;
  gridType: EntityTypes;
  columnOrderHasChangedFn: (value: boolean) => void;
}

export class ColumnStateAddon implements IGridAddon<IColumnAddonReturnProps> {
  storageService: ILocalStorageService;
  defaultColumnState: any;
  colDefStorageKey: string;
  projectId: number;
  gridType: EntityTypes;
  gridApi: GridApi<any>;
  columnOrderHasChangedFn: (value: boolean) => void;

  /**
   *
   */
  constructor({ projectId, gridType, columnOrderHasChangedFn }: IColumnStateAddonProps) {
    this.storageService = LocalStorageService;
    this.projectId = projectId;
    this.gridType = gridType;
    this.colDefStorageKey = `projects-${projectId}-${gridType}-colDef`;
    this.columnOrderHasChangedFn = columnOrderHasChangedFn;
  }

  onSaveGridColumnState = () => {
    if (!this.gridApi) return;
    let columnState = this.gridApi.getColumnState();
    if (!_.isEqual(columnState, this.defaultColumnState))
      this.storageService.set(this.colDefStorageKey, JSON.stringify(columnState));
    this.checkColumnOrder();
  };

  checkColumnOrder = () => {
    let colDef = this.storageService.get(this.colDefStorageKey);
    if (colDef && !_.isEqual(this.defaultColumnState, colDef) && colDef !== "null") {
      return this.columnOrderHasChangedFn(true);
    }
    return this.columnOrderHasChangedFn(false);
  };

  initialiseAddon = () => {
    this.defaultColumnState = JSON.stringify(this.gridApi.getColumnState());
    let columnState = JSON.parse(this.storageService.get(this.colDefStorageKey));
    if (columnState) {
      this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }
    this.checkColumnOrder();
  };

  onGridReady = (gridApi: GridApi<any>) => {
    this.gridApi = gridApi;
    this.initialiseAddon();
  };

  resetColumns = () => {
    this.storageService.remove(this.colDefStorageKey);
    this.gridApi.resetColumnState();

    this.gridApi.resetColumnState();
    this.columnOrderHasChangedFn(false);
  };

  getProps = () => {
    return {
      onColumnResized: this.onSaveGridColumnState,
      onColumnPinned: this.onSaveGridColumnState,
      onColumnMoved: this.onSaveGridColumnState,
      onSortChanged: this.onSaveGridColumnState
    };
  };
}
