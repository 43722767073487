import { action, makeObservable, observable } from "mobx";
import { IModalContextModel } from "../../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import ProcessesApi, {
  ProcessesApi as IProcessesApi
} from "../../../../../../../services/api/v2/processes/Processes.api";
import CsvHelper, { PARSE_CSV_FILE } from "../../../../../../../services/local/csvHelper/CsvHelper";

export enum SystemProcessesUploaderViews {
  Initial,
  IsUploading,
  IsNotOneFileError,
  IsUploadSuccessfull
}

export class SystemProcessesUploaderModel {
  organisationId: number;
  projectId: number;
  @observable viewState: SystemProcessesUploaderViews = SystemProcessesUploaderViews.Initial;
  @observable isUploading: boolean = false;
  @observable isOneFile: boolean = true;
  modalService: IModalContextModel;
  systemProcessesProvider: IProcessesApi;
  onProcessesCreated: () => void;
  constructor(organisationId: number, onProcessesCreated: () => void) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.modalService = ModalContext;
    this.onProcessesCreated = onProcessesCreated;
    this.systemProcessesProvider = ProcessesApi;
  }

  @action
  processFiles = (files: FileList, event: React.DragEvent<HTMLDivElement>) => {
    if (files.length > 1) {
      this.viewState = SystemProcessesUploaderViews.IsNotOneFileError;
      this.isOneFile = false;
      return;
    }
    this.viewState = SystemProcessesUploaderViews.IsUploading;

    PARSE_CSV_FILE<FP.Entities.IProjectProcess>(files[0], this.uploadSystemProcesses);
  };

  convertToHierarchy(data) {
    const hierarchy = [];

    data.forEach(item => {
      let currentLevel = hierarchy;
      const levels = item["Level 1"].split("/");

      levels.forEach((level, index) => {
        const existingLevel = currentLevel.find(node => node.name === level);
        if (existingLevel) {
          currentLevel = existingLevel.children;
        } else {
          const newLevel = {
            name: level,
            uniqueIdentifier: item["Unique Identifier"],
            children: []
          };
          currentLevel.push(newLevel);
          currentLevel = newLevel.children;
        }

        if (index === levels.length - 1) {
          const level2 = item["Level 2"];
          if (!level2) return;
          const existingLevel2 = currentLevel.find(node => node.name === level2);
          if (existingLevel2) {
            currentLevel = existingLevel2.children;
          } else {
            const newLevel2 = {
              name: level2,
              uniqueIdentifier: item["Unique Identifier"],
              children: []
            };
            currentLevel.push(newLevel2);
            currentLevel = newLevel2.children;
          }

          if (!item["Level 3"]) return;
          currentLevel.push({
            name: item["Level 3"],
            uniqueIdentifier: item["Unique Identifier"],
            code: item["Scope Item"]
          });
        }
      });
    });

    return hierarchy;
  }

  uploadSystemProcesses = async (systemProcesses: any[]) => {
    let hierarchy = this.convertToHierarchy(systemProcesses);
    console.log(hierarchy);
    await this.systemProcessesProvider.create(this.organisationId, hierarchy as any);
    this.onProcessesCreated && (await this.onProcessesCreated());
    this.modalService.hide();
  };

  @action
  setViewState = (viewState: SystemProcessesUploaderViews) => (this.viewState = viewState);

  downloadTemplate = () => {
    CsvHelper.exportToCsv("system-processes-upload-template", [
      ["Unique Identifier", "Level 1", "Level 2", "Level 3", "Scope Item"],
      ["1.0.0", "Procure to pay", "", "", ""],
      ["1.1.0", "Procure to pay", "Master Data Management", "", ""],
      ["1.1.1", "Procure to pay", "Master Data Management", "Create and maintain contracts", "SC-13"]
    ]);
  };
}
