import { IGridHubStoreModel } from "../../../../../../apps/insight/stores/GridHubStore";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../../components/ui/ReviewModal";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { ImpactsGrid } from "../ImpactsGrid/ImpactsGridView_view";

export const LINK_TO_IMPACTS_MODAL_CONFIG = (
  selectedLinkItems: number[],
  onAssignSuccess: (itemIds: number[]) => void,
  impactStore: IGridHubStoreModel<FP.Entities.IImpactSummary>
) => {
  return {
    showClose: false,
    title: null,
    content: (
      <ImpactsGrid
        gridToolbarType="link-modal"
        onAssignSuccess={onAssignSuccess}
        selectedLinkItems={selectedLinkItems}
        data={impactStore.data}
        backendColumnDefs={impactStore.columnDefs}
      />
    )
  };
};

export const SHOW_IMPACT_GROUP_DELETE_CONFIRM_MODAL = (
  itemIds: number[],
  removeItemRange: (itemIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeImpactGroupFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmImpactGroupRangeRemove", { name: itemIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeItemRange(itemIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_IMPACT_GROUP_REVIEW_CONFIRM_MODAL = (
  itemIds: number[],
  reviewItemRange: (itemIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    ModalContext.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewItemRange(itemIds, comment);
        ModalContext.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};
