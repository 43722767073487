import React, { useState } from "react";
import I18n from "../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getAudienceTagFormFields } from "./AudienceTagsSidebar_formFields";
import TagsApi from "../../../../../../../services/api/v2/tags/Tags.api";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";

interface ManageAudienceTagFormProps {
  organisationId: number;
  projectId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  closeFn: () => void;
}

class ManageAudienceTagFormModel {
  formModel: SingleFormModel;

  constructor(
    organisationId: number,
    projectId: number,
    projectStakeholder: FP.Entities.IProjectStakeholder,
    closeFn: () => void
  ) {
    this.formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          closeFn();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    const tagsProvider = TagsApi;
    let formFields = getAudienceTagFormFields(tagsProvider, organisationId, projectId, projectStakeholder);
    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const ManageAudienceTagForm: React.FC<ManageAudienceTagFormProps> = props => {
  const { projectStakeholder, closeFn, projectId, organisationId } = props;
  let [model] = useState(() => new ManageAudienceTagFormModel(organisationId, projectId, projectStakeholder, closeFn));

  return <SingleForm model={model.formModel} />;
};
