import { action, makeObservable, observable } from "mobx";
import { PROGRESS_STATUS_OPTIONS } from "../../../../constants";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { IGridModel } from "../../IGridModel";
import I18n from "../../../localization/I18n";
import { GridTypes } from "../../../../enums";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../services/api/v2/actionTypes/ActionTypes.api";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import ActionsHub, { ActionsHub as IActionsHub } from "../../../../services/hubs/ActionsHub/Actions_hub";
import { getActionGridModals } from "../../../../pages/insight/projects/Actions/ActionListView/AcGridView/modals/AcGridView_modals";
import { GetActionGridViewColumns } from "./ActionGrid_columns";

export class ActionGridViewModel implements IGridModel {
  organisationId: number;
  projectId: number;
  @observable.ref actionTypes: FP.Entities.IActionType[];
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypeProvider: IActionTypesApi;
  actionsProvider: IActionsApi;
  authUser: FP.Entities.IUser;
  actionsHub: IActionsHub;
  userCanViewImpacts: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  userCanViewStakeholders: boolean;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.actionTypeProvider = ActionTypesApi;
    this.actionsProvider = ActionsApi;
    this.actionsHub = ActionsHub;
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.mapProgressStatuses();
  }

  onMount = async () => {
    Promise.all([this.loadActionTypes(), this.loadProjectTeamMembers()]).then(values => {
      this.isLoading = false;
    });
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetActionGridViewColumns({
      userCanViewImpacts: this.userCanViewImpacts,
      progressStatuses: this.progressStatuses,
      actionTypes: this.actionTypes,
      modals: getActionGridModals(this.organisationId, this.projectId),
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: null,
      userCanViewStakeholders: this.userCanViewStakeholders,
      pinned: null
    });
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypeProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.actionTypes = res.payload;
  };

  // TODO : review why this is commented out
  @action
  loadProjectTeamMembers = async () => {
    // const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    // if (!res || res.isError) return;
    // this.projectTeamMembers = res.payload;
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
