import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IF } from "../../../../../../components/hoc/If";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { AppGrid } from "../base/AppGrid_view";
import { ModalGridToolbar } from "../base/ModalGridToolbar_view";
import { ImpactsGridModel } from "./ImpactsGridView_model";

interface ImpactsGridProps {
  gridToolbarType?: AppGridToolbarType;
  onAssignSuccess?: (selectedImpacts: number[]) => void;
  selectedLinkItems?: number[];
  data: FP.Entities.IImpactSummary[];
  backendColumnDefs: FP.Entities.IColumnDef[];
  connectedUsers?: FP.Entities.IUser[];
  onCellStateChange?: (organisationId: number, projectId: number) => void;
}

export const ImpactsGrid: React.FunctionComponent<ImpactsGridProps> = observer(
  ({ gridToolbarType = "base", onAssignSuccess, data, connectedUsers, onCellStateChange, backendColumnDefs }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const [searchParams] = useSearchParams();

    const gridRef = useRef<AppGrid>();
    const { projectId } = useParams<{ projectId: string }>();

    const [gridModel, setGridModel] = useState(
      () => new ImpactsGridModel(organisationId, +projectId, authUser, backendColumnDefs, searchParams)
    );

    useEffect(() => {
      if (gridModel.projectId !== +projectId || gridModel.organisationId !== organisationId) {
        setGridModel(new ImpactsGridModel(organisationId, +projectId, authUser, backendColumnDefs, searchParams));
      }
      // eslint-disable-next-line
    }, [organisationId, projectId]);

    useEffect(() => {
      gridModel.onMount();
      return gridModel.onUnmount;
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <IF condition={gridToolbarType === "all" || gridToolbarType === "link-modal"}>
          <ModalGridToolbar
            className="impact-action-grid"
            onQuichSearchInputChange={e => {
              gridRef?.current?.setSearchText(e.currentTarget.value);
            }}
            selectedItems={gridModel.selectedItems}
            onAssignSuccess={onAssignSuccess}
          />
        </IF>
        <IF condition={gridToolbarType === "all" || gridToolbarType === "base"}>
          <AppGridToolbar
            connectedUsers={connectedUsers}
            onQuichSearchInputChange={e => {
              gridRef.current.setSearchText(e.currentTarget.value);
            }}
            selectedItemCount={gridModel.selectedItems.length}
            actions={gridModel.gridActions}
          />
        </IF>
        <AppGrid
          ref={gridRef}
          gridOptions={{ context: { deleteFn: gridModel.deleteFieldData } }}
          filterStoreAddon={gridModel.filterStoreAddon}
          columnStateAddon={gridModel.columnStateAddon}
          textWrapperAddon={gridModel.textWrapAddon}
          onGridStateUpdate={gridModel.onGridStateUpdate}
          rowData={data}
          columnDefs={gridModel.gridColumns}
          shouldTrackSelectedCell={true}
          connectedUsers={connectedUsers}
          onCellClicked={() => onCellStateChange && onCellStateChange(organisationId, +projectId)}
          onCellEditModeChange={() => onCellStateChange && onCellStateChange(organisationId, +projectId)}
          rowSelection="multiple" // Options - allows click selection of rows
        />
      </>
    );
  }
);
