import { useLocation, useParams } from "react-router-dom";
import { useStores } from "../../../../apps/insight/stores/RootStore";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ImpactsViewModel } from "./ImpactsView_model";

export const ImpactTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(new ImpactsViewModel(!!projectId ? +projectId : null, organisationId));

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";
    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };
  const { impactStore } = useStores();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const items: InsightTabContentItem[] = [
    {
      label: tabHeadingWithCount(I18n.t("table.highLevelImpacts"), model.impactGroupCount),
      url: Pages.projects.impactGroups.listView.generateLink(organisationId, +projectId)
    },
    {
      label: tabHeadingWithCount(I18n.t("table.detailedImpacts"), impactStore?.data?.length),
      url: Pages.projects.impacts.listView.generateLink(organisationId, +projectId)
    }
  ];
  if (isVisualisationsEnabled) {
    items.push({
      label: I18n.t("phrases.impactAssessment"),
      url: Pages.projects.impacts.visualisations.impactAssesment.generateLink(organisationId, +projectId)
    });
  }
  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(
    currentPath.endsWith("visualisations/breakdown") ? 1 : currentPath.endsWith("visualisations") ? 2 : 0
  );

  useEffect(
    () => {
      if (organisationId !== model.organisationId || +projectId === model.projectId) {
        setModel(new ImpactsViewModel(+projectId, organisationId));
      }
      model.onMount();
      return () => {};
    },
    // eslint-disable-next-line
    [projectId, organisationId]
  );

  useEffect(() => {
    if (location.pathname.endsWith("impact-groups")) {
      setCurrIndex(0);
    }
    if (location.pathname.endsWith("impacts")) {
      setCurrIndex(1);
    }
    if (location.pathname.endsWith("visualisations/impact-assessment")) {
      setCurrIndex(2);
    }
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});
