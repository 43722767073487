import React, { useState } from "react";
import { Avatar } from "../../../../components/ui/Avatar";
import { UiSizes } from "../../../../enums";
import { Panel } from "../../../../components/ui/Panel";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { useOktaAuth } from "@okta/okta-react";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import I18n from "../../../../core/localization/I18n";
import { useFlightPathUser } from "../../../../core/auth_insight";

export const AuthUserAvatar: React.FunctionComponent<any> = props => {
  const [isTooltipContentShown, setIsTooltipContentShown] = useState(false);
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();

  const { oktaAuth } = useOktaAuth();
  const logout = async () => {
    oktaAuth.signOut();
  };

  return (
    <div
      className={`navigation-view ${props.className ? props.className : ""} ${
        isTooltipContentShown ? "navigation-view--display-tooltip" : ""
      }`}
      tabIndex={0}
      onBlur={() => setTimeout(() => setIsTooltipContentShown(false), 300)}
    >
      <div className="navigation-view__label-tooltip navigation-view__avatar">
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-2 ${Animations.FADE_IN} speed-3`}
        >
          <p className="mb-0 text-dark">{I18n.t("phrases.myProfile")}</p>
        </Panel.Panel>
      </div>

      <div onClick={() => setIsTooltipContentShown(!isTooltipContentShown)}>
        <Avatar className="ml-1" size={UiSizes.MD} imgSrc={authUser.profileImageUrl} />
      </div>
      <div>
        {isTooltipContentShown && (
          <Panel.Panel
            hasBorderRadius={true}
            background={Panel.PanelBackgrounds.BG_WHITE}
            hasShadow={true}
            className={`p-2 ml-3 ${Animations.FADE_IN} speed-3 navigation-view__tooltip-content`}
          >
            <LinkButton
              href={`/organisations/${organisationId}/users/settings`}
              type={ButtonTypes.LINK}
              size={UiSizes.XS}
            >
              {I18n.t("phrases.settings")}
            </LinkButton>
            <Button
              type={ButtonTypes.LINK}
              size={UiSizes.XS}
              onClick={() => {
                logout();
              }}
            >
              {I18n.t("phrases.logout")}
            </Button>
          </Panel.Panel>
        )}
      </div>
    </div>
  );
};
