import { Panel } from "@flightpath/coreui";
import { observer } from "mobx-react";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "../../../../../components/ui/Tabs";
import { useIsVisualisationsEnabled } from "../../../../../contexts/organisationSettings/OrganisationSettingsContext";
import I18n from "../../../../../core/localization/I18n";
import { StakeholderProfilingType, StakeholderType } from "../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { StakeholderGapAnalysis } from "./components/StakeholderGapAnalysis/StakeholderGapAnalysis_view";
import { StakeholderHeatmap } from "./components/StakeholderHeatmap/StakeholderHeatmap_view";
import { TotalStakeholder } from "./components/TotalStakeholders/TotalStakeholder_view";
import { StakeholderProfileLinks, StakeholderTypeLinks } from "./StakeholderReporting_components";
import { StakeholderReportingModel } from "./StakeholderReporting_model";

export const StakeholderReporting: React.FC<any> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId, stakeholderType, profilingType } = useParams<{
    projectId: string;
    stakeholderType: string;
    profilingType: string;
  }>();
  const projId = parseInt(projectId);
  const navigate = useNavigate();
  const stakeholderTypeCasted: StakeholderType = useMemo(
    () => (stakeholderType ? parseInt(stakeholderType) : StakeholderType.INDIVIDUAL),
    [stakeholderType]
  );

  const profilingTypeCasted: StakeholderProfilingType = useMemo(
    () => (profilingType ? parseInt(profilingType) : StakeholderProfilingType.Commitment),
    [profilingType]
  );

  const [model, setModel] = useState(
    () => new StakeholderReportingModel(organisationId, projId, navigate, stakeholderTypeCasted, profilingTypeCasted)
  );

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (
      organisationId !== model.organisationId ||
      projId !== model.projectId ||
      stakeholderTypeCasted !== model.stakeholderType ||
      profilingTypeCasted !== model.profileType
    ) {
      setModel(
        new StakeholderReportingModel(organisationId, projId, navigate, stakeholderTypeCasted, profilingTypeCasted)
      );
    }
  }, [
    navigate,
    model.organisationId,
    model.projectId,
    model.stakeholderType,
    model.profileType,
    projId,
    organisationId,
    stakeholderTypeCasted,
    profilingTypeCasted
  ]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };
  const baseUrl = `/organisations/${organisationId}/projects/${projectId}/stakeholders/visualisations`;

  const stakeholderProfileSelector: JSX.Element = (
    <h4>
      <StakeholderProfileLinks
        stakeholderType={model.stakeholderType}
        baseUrl={baseUrl}
        profileType={model.profileType}
      />
    </h4>
  );

  return (
    <div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={2}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount)}></Tab>
        <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.stakeholderMaps")}</h2>}
        />
      </Tabs>

      <div className="pt-4 container-fluid">
        <div className="row">
          <div className="col-6">
            <h2>
              <StakeholderTypeLinks
                stakeholderType={model.stakeholderType}
                baseUrl={baseUrl}
                profileType={model.profileType}
              />
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row pt-4 ">
          <div className="col-4">
            <TotalStakeholder
              isLoading={model.isLoading}
              data={model.stakeholders}
              profilingType={
                `${model.profileType as any}` === "0"
                  ? "0"
                  : (StakeholderProfilingType[model.profileType].toLowerCase() as any)
              }
            />
          </div>
          <div className="col-4">
            <StakeholderGapAnalysis
              isLoading={model.isLoading}
              data={model.stakeholders}
              profilingType={
                `${model.profileType as any}` === "0"
                  ? "0"
                  : (StakeholderProfilingType[model.profileType].toLowerCase() as any)
              }
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col">
            <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 w-100">
              <div className="col-12">
                <h2>{I18n.t("visualisations.stakeholderMap")}</h2>
              </div>
              <div className="px-3">
                <StakeholderHeatmap
                  stakeholderProfileSelector={stakeholderProfileSelector}
                  stakeholderType={model.stakeholderType}
                  data={model.stakeholders}
                  profilingType={
                    `${model.profileType as any}` === "0"
                      ? "0"
                      : (StakeholderProfilingType[model.profileType].toLowerCase() as any)
                  }
                />
              </div>
            </Panel.Panel>
          </div>
        </div>
      </div>
    </div>
  );
});
