import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { observer } from "mobx-react";
import React from "react";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ImpactBulkUpdateSidebarModel } from "./impactBulkUpdateSidebar_model";

interface ImpactBulkUpdateSidebarProps {
  model: ImpactBulkUpdateSidebarModel;
}

export const BulkUpdateImpactsSidebar: React.FC<ImpactBulkUpdateSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid p-3">
      <div className="col">

      <SingleForm model={model.formModel} />
      </div>
    </div>
  );
});
