import React, { Dispatch, useState } from "react";
import { ButtonTypes } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getActionImpactFormFields } from "./ActionImpactsSidebar_formFields";
import { ActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";

interface AddImpactFormProps {
  actionsProvider: ActionsApi;
  organisationId: number;
  action: FP.Entities.IAction;
  reloadFn: (id: number) => void;
  setFormIsShown?: Dispatch<boolean>;
}

class AddImpactFormModel {
  formModel: SingleFormModel;

  constructor(actionsProvider, organisationId, action, reloadFn, setFormIsShown) {
    this.formModel = new SingleFormModel();

    let formFields = getActionImpactFormFields(actionsProvider, organisationId, action);
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          setFormIsShown(false);
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.add"),
        onAction: async () => {
          const formRes = await this.formModel.submit();
          if (!formRes) return;
          const res = await actionsProvider.addImpactsAsync(
            organisationId,
            action.projectId,
            action.id,
            formRes.impacts
          );

          if (!res || res.isError) return;

          if (res.payload) {
            reloadFn(action.id);
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const AddImpactForm: React.FC<AddImpactFormProps> = props => {
  const { actionsProvider, action, organisationId, reloadFn, setFormIsShown } = props;

  let [model] = useState(
    () => new AddImpactFormModel(actionsProvider, organisationId, action, reloadFn, setFormIsShown)
  );

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
};
