// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { AxisProps } from "@nivo/axes";
import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export type BarChartProps = {
  data: any[];
  keys: string[];
  indexBy: string | ((e: any) => string);
  leftAxisLabel: string;
  bottomAxisLabel?: string;
  colors: string[];
  legendLabel: (e) => string;
  tooltipLabelFn: (e) => string;
  onClick?: (data) => void;
  bottomAxisLabelClick?: (data) => void;
  groupType?: "stacked" | "grouped";
  chartMargin?: { top?: number; right?: number; bottom?: number; left?: number };
  bottomAxisValueFormat?: (val: string) => {
    value: string;
    maxLineLength?: number;
    maxLines?: number;
    truncateAtChar?: number;
  };
  bottomAxisProps?: AxisProps | null;
  enableGridY?: boolean;
  enableGridX?: boolean;
};

const getTspanGroups = (value: string, maxLineLength: number, maxLines: number = 2, truncateAtChar?: number) => {
  const truncatedValue =
    truncateAtChar && value.length > truncateAtChar ? value.slice(0, truncateAtChar) + "..." : value;
  const words = truncatedValue.replace("/", " / ").split(" ");

  type linesAcc = {
    lines: string[];
    currLine: string;
  };

  //reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + " " + word
      };
    },
    { lines: [], currLine: "" }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  const lines = allLines.slice(0, maxLines);
  let children: JSX.Element[] = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={0} dy={dy} key={i}>
        {lineText}
      </tspan>
    );
    //increment dy to render next line text below
    dy = 11;
  });

  return children;
};

export const BarChart: React.FC<BarChartProps> = ({
  data,
  keys,
  indexBy,
  tooltipLabelFn,
  leftAxisLabel,
  bottomAxisLabel,
  legendLabel,
  colors,
  onClick,
  bottomAxisLabelClick,
  groupType,
  chartMargin,
  bottomAxisValueFormat,
  bottomAxisProps,
  enableGridX = false,
  enableGridY = true
}) => (
  <ResponsiveBar
    groupMode={groupType || "stacked"}
    onClick={onClick ?? null}
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{
      top: chartMargin?.top || 50,
      right: chartMargin?.right || 130,
      bottom: chartMargin?.bottom || 130,
      left: chartMargin?.left || 60
    }}
    padding={0.15}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={colors}
    tooltipLabel={tooltipLabelFn}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickRotation: 45,
      legend: bottomAxisLabel,
      legendPosition: "middle",
      legendOffset: 110,
      ...bottomAxisProps,
      renderTick: ({ opacity, textAnchor, textBaseline, textX, textY, value, x, y }) => {
        const formattedValue: {
          value: string;
          maxLineLength?: number;
          maxLines?: number;
          truncateAtChar?: number;
        } = bottomAxisValueFormat ? bottomAxisValueFormat(value) : { value };

        return (
          <g
            transform={`translate(${x},${y})`}
            style={{ opacity, cursor: `${!!bottomAxisLabelClick ? "pointer" : "default"}` }}
            onClick={() => !!bottomAxisLabelClick && bottomAxisLabelClick(value)}
          >
            <text
              textAnchor={textAnchor}
              transform={`translate(${textX},${textY}) rotate(50)`}
              style={{
                fontFamily: "sans-serif",
                fontSize: "10px"
              }}
            >
              {formattedValue.truncateAtChar && formattedValue.value.length > formattedValue.truncateAtChar && (
                <title>{formattedValue.value}</title>
              )}
              {getTspanGroups(
                formattedValue.value,
                formattedValue.maxLineLength || 15,
                formattedValue.maxLines || 10,
                formattedValue.truncateAtChar
              )}
            </text>
          </g>
        );
      }
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: leftAxisLabel,
      legendPosition: "middle",
      legendOffset: -40,
      tickValues: 5
      // truncateTickAt: 0
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]]
    }}
    legendLabel={legendLabel}
    enableGridX={enableGridX}
    enableGridY={enableGridY}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    // barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
  />
);
