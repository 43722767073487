import { action, makeObservable, observable } from "mobx";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";

export class ImpactsViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  projectId: number;
  httpProgress: IProgressIndicatorModel;
  organisationId: number;
  @observable impactGroupCount: number;

  constructor(projectId: number, organisationId: number) {
    super();
    makeObservable(this);
    this.projectProvider = ProjectsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.projectId = projectId;
    this.organisationId = organisationId;
  }

  @action
  setImpactGroupCount = count => {
    this.impactGroupCount = count;
  };

  onMount = () => {
    this.setCountsForImpactAndImpactGroup();
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.setImpactGroupCount(res.payload.impactGroupCount);
  };
}
