import { ColDef } from "ag-grid-community";
import { IBaseColumnProps, baseTextColumn } from "../../../../../core/grids/BaseColumns";
import AgGridAutocomplete from "../../../../../core/grids/editors/AgGridAutocomplete";
import {
  commitmentTextMatcher,
  sentimentReceptivenessTextMatcher,
  yesNoTextMatcher,
  zeroToTenTextMatcher
} from "../../../../../core/grids/filters/TextMatcher";
import { makeCellDeletable, openModalOnDelete } from "../../../../../core/grids/GridHelpers";
import I18n from "../../../../../core/localization/I18n";
import { StakeholderProfilingCell } from "../../Stakeholders/StakeholdersListView/StakeholderGridView/StakeholderGridView_ui";
import React from "react";
import { GridPills } from "../../../../../components/widgets/gridPills/GridPills";

export const colEmail = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "email",
      heading: I18n.t("grids.email"),
      width: 220,
      pinned: "left",
      editable: false,
      ...props
    })
  };
};

export const colRole = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "role",
      heading: I18n.t("grids.role"),
      width: 150,
      editable: false,
      ...props
    })
  };
};
export const colAudienceCount = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "audienceCount",
      heading: I18n.t("grids.audienceCount"),
      width: 150,
      editable: false,
      ...props
    })
  };
};
export const colBusinessArea = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "businessArea",
      heading: I18n.t("grids.businessArea"),
      width: 150,
      editable: false,
      ...props
    })
  };
};
export const colOwnerName = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "ownerName",
      heading: I18n.t("grids.relationshipOwner"),
      width: 150,
      editable: false,
      ...props
    })
  };
};

const leverClassRules = {
  "stakeholder-grid__cell--unknown": params =>
    params.value <= 0 || typeof params.value === "undefined" || params.value === null,
  "stakeholder-grid__cell--low": params => params.value >= 1 && params.value <= 4,
  "stakeholder-grid__cell--medium": params => params.value >= 5 && params.value <= 7,
  "stakeholder-grid__cell--high": params => params.value > 7
};

export const colInfluence = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  options: any[],
  props?: IBaseColumnProps
): ColDef => {
  let res = {
    ...baseTextColumn({
      fieldName: "influence",
      heading: I18n.t("grids.influence"),
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filter: "agTextColumnFilter",
    filterParams: {
      textMatcher: zeroToTenTextMatcher
    },
    cellEditorParams: {
      field: "influence",
      getValueLabel: (ee: any) => {
        const influence = options.find(e => e.key === ee + "");
        return influence ? influence.label : "Unknown";
      },
      options: options ?? []
    },
    cellClassRules: leverClassRules,
    valueFormatter: params => {
      const influence = options.find(e => e.key === params.value + "");
      return influence ? influence.label : "Unknown";
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colImpact = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  options: any[],
  props?: IBaseColumnProps
): ColDef => {
  let res = {
    ...baseTextColumn({
      fieldName: "impact",
      heading: I18n.t("grids.impact"),
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filter: "agTextColumnFilter",
    filterParams: {
      textMatcher: zeroToTenTextMatcher
    },
    cellEditorParams: {
      field: "impact",
      getValueLabel: (ee: any) => {
        const impact = options.find(e => e.key === ee + "");
        return impact ? impact.label : "Unknown";
      },
      options: options ?? []
    },
    cellClassRules: leverClassRules,
    valueFormatter: params => {
      const impact = options.find(e => e.key === params.value + "");
      return impact ? impact.label : "Unknown";
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colIsKeyStakeholder = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  keyStakeholderOptions: any[],
  props?: IBaseColumnProps
): ColDef => {
  let res = {
    ...baseTextColumn({
      fieldName: "isKeyStakeholder",
      heading: I18n.t("grids.keyStakeholder"),
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    cellEditorParams: {
      field: "isKeyStakeholder",
      getValueLabel: (ee: any) => {
        // if true pick the positive option else pick the negative
        // only two options should be available here at all times
        return ee ? keyStakeholderOptions[1].label : keyStakeholderOptions[0].label;
      },
      options: keyStakeholderOptions ?? []
    },
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    cellRenderer: props => {
      let val = props.data[props.colDef.field];
      return val ? I18n.t("phrases.yes") : <span style={{ color: "#aaa" }}>{I18n.t("phrases.no")}</span>;
    },
    filter: "agTextColumnFilter",
    filterParams: {
      textMatcher: yesNoTextMatcher
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colSentiment = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  options: any[],
  props?: IBaseColumnProps
) => {
  let res = {
    ...baseTextColumn({
      fieldName: "sentiment",
      heading: I18n.t("grids.sentiment"),
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filterParams: {
      textMatcher: sentimentReceptivenessTextMatcher
    },
    cellEditorParams: {
      field: "sentiment",
      getValueLabel: (ee: any) => {
        const sentiment = options.find(e => e.key === ee + "");
        return sentiment ? sentiment.label : "Unknown";
      },
      options: options ?? []
    },
    cellRenderer: StakeholderProfilingCell
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colCommitment = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  options: any[],
  props?: IBaseColumnProps
) => {
  let res = {
    ...baseTextColumn({
      fieldName: "commitment",
      heading: I18n.t("grids.commitment"),
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filterParams: {
      textMatcher: commitmentTextMatcher
    },
    cellEditorParams: {
      field: "commitment",
      getValueLabel: (ee: any) => {
        const commitment = options.find(e => e.key === ee + "");
        return commitment ? commitment.label : "Unknown";
      },
      options: options ?? []
    },
    cellRenderer: StakeholderProfilingCell
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colReceptiveness = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  options: any[],
  props?: IBaseColumnProps
) => {
  let res = {
    ...baseTextColumn({
      fieldName: "receptiveness",
      heading: I18n.t("grids.receptiveness"),
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filterParams: {
      textMatcher: sentimentReceptivenessTextMatcher
    },
    cellEditorParams: {
      field: "receptiveness",
      getValueLabel: (ee: any) => {
        const receptiveness = options.find(e => e.key === ee + "");
        return receptiveness ? receptiveness.label : "Unknown";
      },
      options: options ?? []
    },
    cellRenderer: StakeholderProfilingCell
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colOwners = (canEdit: boolean, editFn?: any): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "ownerNames",
      heading: I18n.t("grids.ownerNames"),
      width: 500,
      editable: false,
      autoHeight: true
    }),
    cellRenderer: e => <GridPills text={e.data.ownerNames} />
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => editFn(e.data);
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};
