import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { IF } from "../../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { ButtonIconDropdown } from "../../../../../components/ui/ButtonIconDropdown";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Tab, Tabs } from "../../../../../components/ui/Tabs";
import { Input } from "../../../../../components/ui/_forms/Input";
import { ConnectedUserAvatars } from "../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { useIsVisualisationsEnabled } from "../../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { CanEdit } from "../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { GridView } from "../../../../../core/grids/GridView_view";
import I18n from "../../../../../core/localization/I18n";
import { EntityTypes, Enums, UiSizes } from "../../../../../enums";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { SHOW_STAKEHOLDER_EMAILS } from "./StakeholdersView_modal";
import { StakeholdersViewModel } from "./StakeholdersView_model";

export const StakeholdersView: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectStakeholderStore } = useStores();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId } = useParams<{ projectId: string }>();
  const wrapTextKey = `projects-${projectId}-${EntityTypes.STAKEHOLDERS}-wrap-text`;
  const storageService = LocalStorageService;
  const authUser = useFlightPathUser();
  const navigate = useNavigate();
  const [hasFilters, setHasFilters] = useState(false);
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const filterRef = useRef(null);
  const [model, setModel] = useState(() => new StakeholdersViewModel(organisationId, +projectId, authUser, navigate));

  useEffect(() => {
    projectStakeholderStore.invokeDataLoad(organisationId, +projectId, authUser);
    model.onMount();
    return () => {
      projectStakeholderStore.invokeUserLeft(organisationId, +projectId, authUser);
      model.onUnmount();
    };
    // eslint-disable-next-line
  }, [model]);

  useEffect(() => {
    if (model.projectId !== +projectId || model.organisationId !== organisationId) {
      setModel(new StakeholdersViewModel(organisationId, +projectId, authUser, navigate));
      projectStakeholderStore.canInvoke() &&
        projectStakeholderStore.invokeDataLoad(organisationId, +projectId, authUser);
    }
    // eslint-disable-next-line
  }, [projectStakeholderStore, organisationId, projectId, authUser]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="stakeholders-view">
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={0}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), projectStakeholderStore?.data?.length)}></Tab>
        <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.stakeholderMaps")}</h2>}
        />
      </Tabs>

      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex">
            <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
              <ButtonIcon
                type={ButtonTypes.PRIMARY}
                size={UiSizes.SM}
                onClick={model.showLinkStakeholderModal}
                symbol={IconSymbols.Plus}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.add")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                symbol={IconSymbols.CommentPencil}
                isDisabled={model.selectedStakeholderIds.length === 0}
                onClick={model.selectedStakeholderIds.length > 0 ? model.showConfirmReviewModal : null}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.review")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedStakeholderIds.length === 0}
                onClick={() => {
                  SHOW_STAKEHOLDER_EMAILS(model.modalService, model.selectedStakeholders);
                }}
                symbol={IconSymbols.Mail}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.email")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedStakeholderIds.length === 0}
                symbol={IconSymbols.Trash}
                onClick={model.selectedStakeholderIds.length > 0 ? model.showStakeholderConfirmDeleteModal : null}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.remove")}
              </ButtonIcon>

              <ButtonIconDropdown
                iconSymbol={IconSymbols.Link}
                buttonProps={{
                  size: UiSizes.SM,
                  type: ButtonTypes.LINK,
                  style: { display: "block" },
                  className: "p-0 mr-2",
                  isDisabled: model.selectedStakeholderIds.length === 0
                }}
                tooltipWidth={180}
                actions={model.actions}
              >
                {I18n.t("phrases.link")}
              </ButtonIconDropdown>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedStakeholderIds.length === 0}
                onClick={model.selectedStakeholderIds.length > 0 ? model.exportRows : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Download}
              >
                {I18n.t("phrases.export")}
              </ButtonIcon>
              <span className="vertical-line"></span>
              <ButtonIcon
                type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                onClick={() => {
                  setIsTextWrapToggled(!isTextWrapToggled);
                  filterRef.current.toggleTextWrapper();
                }}
                symbol={IconSymbols.TextWrap}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.textWrap")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasFilters}
                onClick={() => filterRef.current.clearFilters()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.ClearFilter}
              >
                {I18n.t("phrases.clearFilters")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasColumnChanges}
                onClick={() => filterRef.current.resetColumns()}
                symbol={IconSymbols.MonitorBack}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.resetColumns")}
              </ButtonIcon>
            </CanEdit>
          </div>
          <div className="col-3">
            <ConnectedUserAvatars connectedUsers={projectStakeholderStore.connectedUsers} />
          </div>
          <div className="col-2">
            <div className="text-right">
              <Input
                autoFocus={true}
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                style={{ height: 32 }}
              />
            </div>
          </div>
        </div>
      </div>

      <IF condition={model.isLoading}>
        <PositionedSpinner />
      </IF>
      <IF condition={!model.isLoading}>
        <GridView
          context={{ deleteFn: model.deleteFieldData }}
          data={projectStakeholderStore.data}
          users={projectStakeholderStore.connectedUsers}
          onCellClicked={projectStakeholderStore.updateUserSelectedCell(organisationId, +projectId)}
          onCellEditModeChange={projectStakeholderStore.updateUserSelectedCell(organisationId, +projectId)}
          onSelectionChanged={model.updateSelectedStakeholderIds}
          onGridReady={model.onGridReady}
          model={model.gridModel}
          isLoading={projectStakeholderStore.isLoading || model.isLoading}
          ref={filterRef}
          filterHasChangedFn={setHasFilters}
          columnOrderHasChangedFn={setHasColumnChanges}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});
