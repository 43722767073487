import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseOrganisationApiModel } from "../BaseOrganisationApiModel";
import http from "../Http";

export class ColumnDefsApi extends BaseOrganisationApiModel<any> {
  controller: string = "column-defs";
  constructor(http: AxiosInstance) {
    super(http, "column-defs");
  }

  getStakeholderColumnDefs = async (organisationId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}/stakeholders`, config);
    return res.data;
  };

  getAudiencesColumnDefs = async (organisationId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}/audiences`, config);
    return res.data;
  };
  getImpactColumnDefs = async (organisationId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}/impacts`, config);
    return res.data;
  };
  getProcessColumnDefs = async (organisationId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}/processes`, config);
    return res.data;
  };
}

const instance = new ColumnDefsApi(http);

export default instance;
