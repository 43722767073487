import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { GetGridFns, IGridFns } from "../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { EntityTypes } from "../../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import { GenerateColumnDefinitions, GetStakeholderGridViewColumns } from "./AudienceGridView_columns";

export class AudienceGridViewModel extends BaseModel implements IGridModel {
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  organisationId: number;
  projectId: number;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  gridFns: IGridFns;
  modalService: IModalContextModel;
  stakeholderId: number;
  type: EntityTypes = EntityTypes.AUDIENCES;
  userCanViewImpacts: boolean;
  colDefs: FP.Entities.IColumnDef[];

  /**
   *
   */
  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    colDefs: FP.Entities.IColumnDef[]
  ) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = ModalContext;
    this.colDefs = colDefs;
    this.projectStakeholdersProvider = ProjectStakeholdersApi;
    this.gridFns = GetGridFns(this.projectStakeholdersProvider as any, this.organisationId, this.projectId);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
  }

  getColumnConfig = () => {
    const canEditStakeholders = PermissionsContext.canEditField(
      PermissionFields.STAKEHOLDERS,
      this.organisationId,
      this.projectId
    );

    return GenerateColumnDefinitions(
      this.colDefs,
      {
        canEdit: canEditStakeholders,
        organisationId: this.organisationId,
        projectId: this.projectId,
        stakeholderId: this.stakeholderId,
        modalService: this.modalService,
        isKeyStakeholdersOptions: [
          { key: "0", label: I18n.t("phrases.no") },
          { key: "1", label: I18n.t("phrases.yes") }
        ],
        impactLevels: [],
        gridFns: this.gridFns,
        userCanViewImpacts: this.userCanViewImpacts,
        pinned: "left"
      },
      GetStakeholderGridViewColumns
    );
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
