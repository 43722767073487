import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const NAME_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "name",
  headerName: "header Name",
  pinned: "left",
  editable: true,
  ...columnOptions
});

export const NAME_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agTextColumnFilter",
  filterParams: {
    buttons: ["clear"]
  }
};
