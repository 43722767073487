import React, { useState } from "react";
import Timeline, {
  TodayMarker,
  TimelineMarkers,
  CustomMarker,
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  ReactCalendarTimelineProps
} from "react-calendar-timeline";
import { observer } from "mobx-react-lite";
import { TimeLineModel } from "./Timeline_model";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import moment from "moment";
import { ButtonIcon, ButtonTypes } from "../../ui/Button";
import { Tooltip } from "react-tippy";
import _ from "lodash";
import { Enums } from "../../../enums";
import { IconSymbols } from "../../ui/Icon";

export interface TimelineLabels {
  noItemsText: string;
  today: string;
  scrollLeft: string;
  scrollRight: string;
  zoomOut: string;
  zoomIn: string;
  resetZoom: string;
}

export interface TimelineProps {
  model: TimeLineModel;
  labels: TimelineLabels;
  hideControls?: boolean;
  className?: string;
  timelineProps?: Partial<ReactCalendarTimelineProps<any, any>>;
}

const TimelineView: React.FunctionComponent<TimelineProps> = observer(
  ({ model, labels, hideControls, className, timelineProps }) => {
    const [timelineHeight, setTimelineHeight] = useState(300);
    if (model.isLoading) {
      return <PositionedSpinner style={{ height: `${timelineHeight}px` }} />;
    }

    if (!model.groups?.filter(group => group.title !== null).length && !model.markers?.length) {
      return <div>{labels.noItemsText}</div>;
    }

    const tp: any = {
      canMove: model.canEdit ? true : false,
      canResize: model.canEdit ? ("both" as "both") : false,
      ...(model.isEditable && {
        onItemMove: model.handleItemMove,
        onItemResize: model.handleItemResize,
        onItemDoubleClick: model.handleItemDoubleClick,
        sidebarWidth: 0,
        itemHeightRatio: 0.8,
        lineHeight: 56,
        className: "react-calendar-timeline--editable"
      }),
      ...timelineProps
    };

    // TODO: this should be wrapped in a useEffect
    if (model.isEditable) {
      const timelineEl: HTMLElement | any = document.querySelector(".timeline");
      if (timelineEl && timelineHeight !== timelineEl.offsetHeight) {
        setTimelineHeight(timelineEl.offsetHeight);
      }
    }

    const groupMarkers = _.groupBy(model.markers, e => e.project);

    return (
      <div className={`timeline ${className ? className : ""}`}>
        <div className="timeline__placeholder"></div>
        <Timeline
          groups={model.groups}
          items={model.items}
          defaultTimeStart={model.defaultStartDate}
          sidebarWidth={model.sidebarWidth}
          defaultTimeEnd={model.defaultEndDate}
          visibleTimeStart={model.visibleTimeStart.valueOf()}
          visibleTimeEnd={model.visibleTimeEnd.valueOf()}
          onTimeChange={model.handleTimeChange}
          minZoom={model.minZoom}
          maxZoom={model.maxZoom}
          canChangeGroup={false}
          itemRenderer={model.itemRenderer || undefined}
          groupRenderer={model.groupRenderer || undefined}
          itemTouchSendsClick={false}
          {...tp}
        >
          <TimelineHeaders>
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div {...getRootProps()} className="rct-sidebarHeader">
                    {model.sideheaderTitle && model.sideheaderTitle}
                  </div>
                );
              }}
            </SidebarHeader>
            <DateHeader
              unit={model.currentZoom > 60 ? "year" : "month"}
              labelFormat={model.currentZoom > 60 ? "YYYY" : "MMM YY"}
              intervalRenderer={({ getIntervalProps, intervalContext }: any) => {
                const { onClick, ...restIntervalProps } = getIntervalProps();
                return (
                  <div {...restIntervalProps} className="rct-dateHeader rct-dateHeader-primary">
                    <span className="text-truncate">{intervalContext.intervalText}</span>
                  </div>
                );
              }}
            />
            <DateHeader
              unit={model.currentZoom > 60 ? "month" : "day"}
              labelFormat={model.currentZoom > 365 ? "MMM" : model.currentZoom > 60 ? "MMM YY" : "D"}
              intervalRenderer={({ getIntervalProps, intervalContext }: any) => {
                const { onClick, ...restIntervalProps } = getIntervalProps();
                return (
                  <div {...restIntervalProps} className="rct-dateHeader">
                    <span className="text-truncate">{intervalContext.intervalText}</span>
                  </div>
                );
              }}
            />
          </TimelineHeaders>

          <TimelineMarkers>
            <TodayMarker date={moment().valueOf()}>
              {({ styles, date }) => {
                const customStyles: React.CSSProperties = {
                  ...styles,
                  pointerEvents: "auto",
                  zIndex: 1
                };
                return (
                  <div style={customStyles}>
                    <Tooltip theme="light" followCursor html={<small className="d-block">{labels.today}</small>}>
                      <div className="timeline-marker timeline-marker--today"></div>
                    </Tooltip>
                  </div>
                );
              }}
            </TodayMarker>
            {Object.values(groupMarkers).map(groupMarker => {
              const markersByDate = _.groupBy(groupMarker, e => e.date);

              return Object.keys(markersByDate).map(dateMarker => {
                const groupDateMarker = markersByDate[dateMarker];
                const dateValue = groupDateMarker[0].date.set({ hour: 13 }).valueOf();
                if (groupDateMarker.length > 1) {
                  return (
                    <CustomMarker date={dateValue} key={groupDateMarker[0].id}>
                      {({ styles, date }) => {
                        const customStyles: React.CSSProperties = {
                          ...styles,
                          pointerEvents: "auto",
                          zIndex: 100,
                          width: 0,
                          height: 0,
                          top: `${groupDateMarker[0].project + 7}px`
                        };
                        return (
                          <div style={customStyles}>
                            <Tooltip
                              theme="light"
                              followCursor
                              animateFill={false}
                              html={
                                <div className="timeline-marker--grouped-tooltip">
                                  <h4 className="text-center">{moment(date).format("DD/MM/YYYY")}</h4>
                                  {groupDateMarker.map(marker => (
                                    <div className="timeline-marker--grouped-tooltip-group">
                                      <div
                                        className={`timeline-marker__icon timeline-marker__icon--group ${
                                          marker.typeClass ? marker.typeClass : ""
                                        }`}
                                      />
                                      <p>{marker.title}</p>
                                    </div>
                                  ))}
                                </div>
                              }
                            >
                              <div className={`timeline-marker timeline-marker--grouped`}>{groupDateMarker.length}</div>
                            </Tooltip>
                          </div>
                        );
                      }}
                    </CustomMarker>
                  );
                } else {
                  // Render a single marker
                  return (
                    <CustomMarker date={dateValue} key={groupDateMarker[0].id}>
                      {({ styles, date }) => {
                        const customStyles: React.CSSProperties = {
                          ...styles,
                          pointerEvents: "auto",
                          zIndex: 100,
                          ...(groupDateMarker[0].project && {
                            width: 0,
                            height: 0
                          })
                        };

                        return (
                          <div style={customStyles} onClick={() => model.handleMarkerSelect(groupDateMarker[0])}>
                            <Tooltip
                              theme="light"
                              followCursor
                              animateFill={false}
                              html={
                                groupDateMarker[0].project ? (
                                  <>
                                    <h4 className="text-center">{moment(date).format("DD/MM/YYYY")}</h4>
                                    {groupDateMarker[0].title ? (
                                      <p className="mb-0">{groupDateMarker[0].title}</p>
                                    ) : null}
                                    {groupDateMarker[0].description && (
                                      <>
                                        <hr />
                                        <p
                                          className="mb-0"
                                          dangerouslySetInnerHTML={{
                                            __html: groupDateMarker[0].description
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {groupDateMarker[0].title ? (
                                      <small className="d-block">{groupDateMarker[0].title}</small>
                                    ) : null}
                                    <small className="d-block">{moment(date).format("DD/MM/YYYY")}</small>
                                    {groupDateMarker[0].description && (
                                      <>
                                        <hr />
                                        <small
                                          className="d-block"
                                          dangerouslySetInnerHTML={{
                                            __html: groupDateMarker[0].description
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                )
                              }
                            >
                              <div
                                className="timeline-marker"
                                data-selected={model.selectedMarker?.id === groupDateMarker[0].id}
                              >
                                <div
                                  className={`timeline-marker__icon ${
                                    groupDateMarker[0].typeClass ? groupDateMarker[0].typeClass : ""
                                  }`}
                                  style={{ top: `${groupDateMarker[0].project}px` }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        );
                      }}
                    </CustomMarker>
                  );
                }
              });
            })}
          </TimelineMarkers>
        </Timeline>

        {!hideControls && (
          <div className="timeline__controls">
            <ButtonIcon
              symbol={IconSymbols.ChevronLeft}
              type={ButtonTypes.LINK}
              title={labels.scrollLeft}
              iconSize={Enums.UiSizes.SM}
              size={Enums.UiSizes.XS}
              onClick={model.onPrevClick}
            />
            <ButtonIcon
              symbol={IconSymbols.ChevronRight}
              type={ButtonTypes.LINK}
              className="ml-1"
              title={labels.scrollRight}
              iconSize={Enums.UiSizes.SM}
              size={Enums.UiSizes.XS}
              onClick={model.onNextClick}
            />
            <ButtonIcon
              symbol={IconSymbols.Minus}
              type={ButtonTypes.LINK}
              className="ml-1"
              title={labels.zoomOut}
              iconSize={Enums.UiSizes.SM}
              size={Enums.UiSizes.XS}
              onClick={model.onZoomOut}
              isDisabled={!model.canZoomOut}
            />
            <ButtonIcon
              symbol={IconSymbols.Plus}
              type={ButtonTypes.LINK}
              className="ml-1"
              title={labels.zoomIn}
              iconSize={Enums.UiSizes.SM}
              size={Enums.UiSizes.XS}
              onClick={model.onZoomIn}
              isDisabled={!model.canZoomIn}
            />
            <ButtonIcon
              symbol={IconSymbols.Minimize2}
              type={ButtonTypes.LINK}
              className="ml-1"
              title={labels.resetZoom}
              iconSize={Enums.UiSizes.SM}
              size={Enums.UiSizes.XS}
              onClick={model.resetZoom}
            />
          </div>
        )}
      </div>
    );
  }
);

export { TimelineView };
