import { action, makeObservable, observable } from "mobx";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ProjectsApi, { ProjectsApi as IProjectApi } from "../../../../services/api/v2/projects/Projects.api";

export class ImpactGroupGridModel {
  projectId: number;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable gridImpactGroups: FP.Entities.IImpactGroup[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedImpactGroups: number[] = [];
  gridApi: GridApi;
  projectsProvider: IProjectApi;
  impactGroupsProvider: IImpactGroupsApi;
  searchText: string;

  /**
   *
   */
  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.projectId = projectId;
    this.impactGroupsProvider = ImpactGroupsApi;
    this.projectsProvider = ProjectsApi;
    this.organisationId = organisationId;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.loadImpactGroups();
  };

  onUnmount = () => {};

  loadImpactGroups = async () => {
    let res = await this.projectsProvider.getImpactGroups(this.organisationId, this.projectId);
    if (res.isError) return;

    this.setImpactGroupRowData(res.payload);
  };

  @action
  setImpactGroupRowData = impacts => {
    this.gridImpactGroups = impacts;
    this.isLoading = false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedImpactGroups = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;
    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
