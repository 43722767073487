import { ColDef } from "ag-grid-community";
import { EntityTypes } from "../../../../enums";
import { colName, colRefNumber, colSelected } from "../../CommonGridColumns";

import { IGridFns } from "../../GridFunctions";
import { ImpactGroupField } from "../../builder/directorBuilder/utils/GridFields";

export interface IGetImpactGroupGridViewColumns {
  userCanViewImpacts: boolean;
  progressStatuses: FP.Generic.IKeyLabel[];
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  userCanViewStakeholders: boolean;
  pinned: "left" | "right" | null;
}

export const GetImpactGroupGridViewColumns = (modelProps: IGetImpactGroupGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  const canEdit = modelProps.canEdit;

  result.push(colSelected());
  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.ACTIONS, { pinned: modelProps.pinned })
  );
  result.push(
    colName(canEdit, canEdit && modelProps.gridFns.updateTextField, ImpactGroupField.NAME, "", {
      width: 500,
      pinned: modelProps.pinned
    })
  );

  // result.push(colReviewedBy());
  // result.push(colLastReviewedOn());
  // result.push(colNextReviewDue());
  // result.push(colCreatedByName());
  // result.push(colCreatedOn());
  // result.push(colModifiedByName());
  // result.push(colUpdatedOn());

  return result;
};
