import * as GridQueryStringHelpers from "../../../../../../core/grids/GridQueryStringHelpers";
import * as GridFilterHelpers from "../../../../../../core/grids/GridFilterHelpers";
import { QueryStringParts } from "../../../../../../enums";

export const getActionGridFilters = () => {
  const ragStatus: string = GridQueryStringHelpers.getParameter(QueryStringParts.RagStatus);
  const owner: string = GridQueryStringHelpers.getOwnerParameter();
  const assignedTo: string = GridQueryStringHelpers.getAssignedToParameter();
  const createdBy: string = GridQueryStringHelpers.getCreatedByParameter();
  const reviewedBy: string = GridQueryStringHelpers.getReviewedByParameter();
  const progressStatus: string = GridQueryStringHelpers.getProgressStatusParameter();
  const actionStakeholders: string = GridQueryStringHelpers.getActionStakeholders();
  const businessAreas: string = GridQueryStringHelpers.getBusinessAreasParameter();
  const actionAudiences: string = GridQueryStringHelpers.getActionAudiencesNames();

  let filter = {};

  if (ragStatus !== null) filter["ragStatus"] = GridFilterHelpers.textContains_Filter(ragStatus);
  if (actionStakeholders !== null) {
    filter["actionStakeholders"] = GridFilterHelpers.textContains_Filter(actionStakeholders);
  }
  if (owner !== null) {
    filter["ownerName"] = GridFilterHelpers.textEquals_Filter(owner);
  }
  if (assignedTo !== null) {
    filter["assignedToName"] = GridFilterHelpers.textEquals_Filter(assignedTo);
  }
  if (createdBy !== null) {
    filter["createdByName"] = GridFilterHelpers.textEquals_Filter(createdBy);
  }
  if (reviewedBy !== null) {
    filter["reviewedByName"] = GridFilterHelpers.textEquals_Filter(reviewedBy);
  }
  if (progressStatus !== null) {
    filter["progressStatus"] = GridFilterHelpers.textEquals_Filter(progressStatus);
  }
  if (businessAreas !== null) {
    filter["businessAreas"] = GridFilterHelpers.getMultiValueCondition_Filter(
      businessAreas.split("|"),
      "contains",
      "AND"
    );
  }
  if (actionAudiences !== null) {
    filter["actionAudiences"] = GridFilterHelpers.getMultiValueCondition_Filter(
      actionAudiences.split("|"),
      "contains",
      "AND"
    );
  }

  return filter;
};
