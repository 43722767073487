import { ColDef } from "ag-grid-community";
import {
  colCreatedByName,
  colCreatedOn,
  colImpacts,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colReviewedBy,
  colSelected,
  colTags,
  colUpdatedOn
} from "../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../core/grids/GridFunctions";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import { ProjectStakeholderField, Translator } from "../../../../../enums";
import { GetDynamicColDef } from "../../Stakeholders/StakeholdersListView/StakeholderGridView/StakeholderGridView_columns";
import {
  SHOW_AUDIENCE_IMPACT_MODAL,
  SHOW_AUDIENCE_NOTES_MODAL,
  SHOW_AUDIENCE_OWNER_MODAL,
  SHOW_AUDIENCE_TAGS_MODAL
} from "../AudiencesView_modal";
import {
  colAudienceCount,
  colBusinessArea,
  colCommitment,
  colImpact,
  colInfluence,
  colIsKeyStakeholder,
  colOwners,
  colReceptiveness,
  colSentiment
} from "./AudienceGridView_baseColumns";

export interface IGetStakeholderGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  stakeholderId: number;
  isKeyStakeholdersOptions: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  gridFns: IGridFns;
  modalService: IModalContextModel;
  userCanViewImpacts: boolean;
  pinned: "left" | "right" | null;
  // modals: IImpactGridModals;
}

export const GetStakeholderGridViewColumns = (modelProps: IGetStakeholderGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  // const modals = modelProps.modals;
  if (modelProps.canEdit) {
    result.push(colSelected());
  }
  result.push(
    colName(
      false,
      null,
      null,
      `/organisations/${modelProps.organisationId}/projects/${modelProps.projectId}/stakeholders`,
      { width: 150, pinned: modelProps.pinned }
    )
  );
  result.push(colBusinessArea());
  result.push(colAudienceCount());
  result.push(
    colOwners(modelProps.canEdit, audience =>
      SHOW_AUDIENCE_OWNER_MODAL(modelProps.modalService, modelProps.projectId, audience)
    )
  );
  result.push(
    colIsKeyStakeholder(
      modelProps.canEdit,
      modelProps.gridFns.updateTextField,
      ProjectStakeholderField.isKeyStakeholder,
      modelProps.isKeyStakeholdersOptions
    )
  );
  result.push(
    colInfluence(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.influence,
      Translator.InfluenceLevelMapped()
    )
  );
  result.push(
    colImpact(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.impact,
      Translator.ImpactLevelMapped()
    )
  );
  result.push(
    colSentiment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.sentiment,
      Translator.StakeholderProfilingMapped()
    )
  );
  result.push(
    colCommitment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.commitment,
      Translator.StakeholderProfilingMappedSecondary()
    )
  );
  result.push(
    colReceptiveness(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.receptiveness,
      Translator.StakeholderProfilingMapped()
    )
  );

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewImpacts) {
    result.push(colImpacts(modelProps.canEdit, SHOW_AUDIENCE_IMPACT_MODAL(modelProps.modalService)));
  }
  result.push(
    colNoteCount(modelProps.canEdit, audience => {
      SHOW_AUDIENCE_NOTES_MODAL(modelProps.modalService, audience);
    })
  );
  result.push(colTags(modelProps.canEdit, audience => SHOW_AUDIENCE_TAGS_MODAL(modelProps.modalService, audience)));
  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());
  return result;
};

export const GetAudienceGridViewColumnsDictionary = (modelProps: IGetStakeholderGridViewColumns) => {
  var result = {
    name: colName(
      false,
      null,
      null,
      `/organisations/${modelProps.organisationId}/projects/${modelProps.projectId}/stakeholders`,
      { width: 150, pinned: modelProps.pinned }
    ),
    businessArea: colBusinessArea(),
    audienceCount: colAudienceCount(),
    isKeyStakeholder: colIsKeyStakeholder(
      modelProps.canEdit,
      modelProps.gridFns.updateTextField,
      ProjectStakeholderField.isKeyStakeholder,
      modelProps.isKeyStakeholdersOptions
    ),
    influence: colInfluence(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.influence,
      Translator.InfluenceLevelMapped()
    ),
    impact: colImpact(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.impact,
      Translator.ImpactLevelMapped()
    ),
    sentiment: colSentiment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.sentiment,
      Translator.StakeholderProfilingMapped()
    ),
    commitment: colCommitment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.commitment,
      Translator.StakeholderProfilingMappedSecondary()
    ),
    receptiveness: colReceptiveness(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.receptiveness,
      Translator.StakeholderProfilingMapped()
    ),
    notes: colNoteCount(modelProps.canEdit, audience => {
      SHOW_AUDIENCE_NOTES_MODAL(modelProps.modalService, audience);
    }),
    tags: colTags(modelProps.canEdit, audience => SHOW_AUDIENCE_TAGS_MODAL(modelProps.modalService, audience)),
    owners: colOwners(modelProps.canEdit, audience =>
      SHOW_AUDIENCE_OWNER_MODAL(modelProps.modalService, modelProps.projectId, audience)
    ),
    reviewedBy: colReviewedBy(),
    reviewedOn: colLastReviewedOn(),
    nextReviewDue: colNextReviewDue(),
    createdBy: colCreatedByName(),
    createdOn: colCreatedOn(),
    modifiedBy: colModifiedByName(),
    updatedOn: colUpdatedOn(),
    selected: colSelected()
  };

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewImpacts) {
    result["impacts"] = colImpacts(modelProps.canEdit, SHOW_AUDIENCE_IMPACT_MODAL(modelProps.modalService));
  }
  return result;
};

export const GenerateColumnDefinitions = (
  colDefs: FP.Entities.IColumnDef[],
  modelProps: IGetStakeholderGridViewColumns,
  defaultColFN: (props: IGetStakeholderGridViewColumns) => ColDef[]
): ColDef[] => {
  if (!colDefs || colDefs.length === 0) {
    return defaultColFN(modelProps);
  }
  const config = GetAudienceGridViewColumnsDictionary(modelProps);
  let res = [];
  colDefs.forEach(e => {
    if (!config[e.colName] && e.isCore) {
      return;
    }
    if (e.isCore) {
      return res.push(config[e.colName]);
    }
    return res.push(GetDynamicColDef(modelProps.organisationId, modelProps.projectId, e));
  });
  if (modelProps.canEdit) {
    res.unshift(config["selected"]);
  }

  return res;
};
