import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG } from "../../baseColumn/BaseColumn_config";

export const SIMPLE_TEXT_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "name",
  headerName: "header Name",
  pinned: "left",
  editable: true,
  ...columnOptions
});
