import { useState } from "react";
import ActionsApi from "../../../../../services/api/v2/actions/Actions.api";
import { ActionSummaryChart } from "./ActionSummaryChart/ActionSummaryChart_view";
import { SHOW_BUSINESS_AREA_SELECTION_MODAL } from "./ActionSummary_modals";
import { ActionSummaryModel } from "./ActionSummary_model";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import Pages from "../../../../../routes/InsightRoutes";
import {
  MapProgressStatusesFromActionStackBarChart,
  MapRagStatusesFromActionStackBarChart
} from "./ActionSummary_mappers";

export const ActionBreakdownView: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [model] = useState(() => new ActionSummaryModel(organisationId, +projectId));
  const mappedProgressStatuses = MapProgressStatusesFromActionStackBarChart();
  const mappedRagStatuses = MapRagStatusesFromActionStackBarChart();
  return (
    <div className={`action-breakdown-view pt-5 ${Animations.FP_ZOOM_IN}`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-6">
            <ActionSummaryChart
              title="Action Progress Status by Assigned To"
              loadDataFn={ActionsApi.getAssignedToActionProgressReport}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                onClick: item => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?progressStatus=${
                    mappedProgressStatuses.find(e => e.id === item.id).label
                  }&assignedToName=${item.data.name.trim()}`;
                  navigate(url);
                },
                bottomAxisLabelClick: value => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?assignedToName=${value.trim()}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "inProgress", "notStarted"],
                bottomAxisLabel: "Project Team Members",
                colors: ["#58C8D1", "#FFC036", "#E5E5E5"],
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                leftAxisLabel: "Number of actions"
              })}
            />
          </div>
          <div className="col-6">
            <ActionSummaryChart
              title="Action RAG Status by Assigned To"
              loadDataFn={ActionsApi.getAssignedToActionRagBreakdownReport}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                onClick: item => {
                  const url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
                    item.id === "completed" ? "progressStatus" : "ragStatus"
                  }=${mappedRagStatuses.find(e => e.id === item.id).label}&assignedToName=${item.data.name}`;
                  navigate(url);
                },
                bottomAxisLabelClick: value => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?assignedToName=${value.trim()}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "red", "amber", "green", "unknown"],
                colors: ["#58C8D1", "#F91E1E", "#F9AB1E", "#1AC541", "#E5E5E5"],
                bottomAxisLabel: "Project Team Members",
                leftAxisLabel: "Number of actions"
              })}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <ActionSummaryChart
              key={JSON.stringify(model.progressBusinessAreaIds)}
              clearFilterFN={model.clearProgressBusinessAreas}
              title="Action Progress Status by Business Areas"
              loadDataFn={(organisationId, projectId) =>
                ActionsApi.getBusinessAreaActionProgressReport(organisationId, projectId, model.progressBusinessAreaIds)
              }
              filterModalFn={() => {
                SHOW_BUSINESS_AREA_SELECTION_MODAL(model.updateProgressBusinessAreas, model.progressBusinessAreaIds);
              }}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                onClick: item => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?progressStatus=${mappedProgressStatuses.find(e => e.id === item.id).label}&businessAreas=${
                    item.data.name
                  }`;
                  navigate(url);
                },
                bottomAxisLabelClick: value => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?businessAreas=${value}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "inProgress", "notStarted"],
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                bottomAxisLabel: "Business Areas",
                colors: ["#58C8D1", "#FFC036", "#E5E5E5"],
                leftAxisLabel: "Number of actions"
              })}
            />
          </div>
          <div className="col-6">
            <ActionSummaryChart
              key={JSON.stringify(model.ragBusinessAreaIds)}
              clearFilterFN={model.clearRagBusinessAreas}
              title="Action Rag Status by Business Areas"
              loadDataFn={(organisationId, projectId) =>
                ActionsApi.getBusinessAreaActionRagBreakdownReport(organisationId, projectId, model.ragBusinessAreaIds)
              }
              filterModalFn={() => {
                SHOW_BUSINESS_AREA_SELECTION_MODAL(model.updateRagBusinessAreas, model.ragBusinessAreaIds);
              }}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                onClick: item => {
                  const url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
                    item.id === "completed" ? "progressStatus" : "ragStatus"
                  }=${mappedRagStatuses.find(e => e.id === item.id).label}&businessAreas=${item.data.name}`;
                  navigate(url);
                },
                bottomAxisLabelClick: value => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?businessAreas=${value}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "red", "amber", "green", "unknown"],
                colors: ["#58C8D1", "#F91E1E", "#F9AB1E", "#1AC541", "#E5E5E5"],
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                bottomAxisLabel: "Business Areas",
                leftAxisLabel: "Number of actions"
              })}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <ActionSummaryChart
              title="Action Progress Status by Audiences"
              loadDataFn={ActionsApi.getAudienceActionProgressReport}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                onClick: item => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?progressStatus=${mappedProgressStatuses.find(e => e.id === item.id).label}&actionAudiences=${
                    item.data.name
                  }`;
                  navigate(url);
                },
                bottomAxisLabelClick: value => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?actionAudiences=${value}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "inProgress", "notStarted"],
                bottomAxisLabel: "Audiences",
                colors: ["#58C8D1", "#FFC036", "#E5E5E5"],
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                leftAxisLabel: "Number of actions"
              })}
            />
          </div>
          <div className="col-6">
            <ActionSummaryChart
              title="Action RAG Status by Audiences"
              loadDataFn={ActionsApi.getAudienceActionRagBreakdownReport}
              transformer={data => ({
                data,
                tooltipLabelFn: item => {
                  return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
                },
                legendLabel: e => {
                  return I18n.t(`phrases.${e.id}`);
                },
                onClick: item => {
                  const url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
                    item.id === "completed" ? "progressStatus" : "ragStatus"
                  }=${mappedRagStatuses.find(e => e.id === item.id).label}&actionAudiences=${item.data.name}`;
                  navigate(url);
                },
                bottomAxisLabelClick: value => {
                  const url = `${Pages.projects.actions.listView.generateLink(
                    organisationId,
                    projectId
                  )}?actionAudiences=${value}`;
                  navigate(url);
                },
                indexBy: "name",
                keys: ["completed", "red", "amber", "green", "unknown"],
                colors: ["#58C8D1", "#F91E1E", "#F9AB1E", "#1AC541", "#E5E5E5"],
                bottomAxisLabel: "Audiences",
                leftAxisLabel: "Number of actions"
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
