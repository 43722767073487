import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { ImpactsGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ImpactsGrid/ImpactsGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { DetailedImpactsViewModel } from "./DetailedImpactsView_model";
import { IF } from "../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

export interface DetailedImpactsViewProps {
  model?: DetailedImpactsViewModel;
}

const DetailedImpactsView: React.FunctionComponent<DetailedImpactsViewProps> = observer(({ model: m }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const { impactStore } = useStores();

  useEffect(() => {
    impactStore.registerSocketEvents().then(e => {
      impactStore.invokeDataLoad(organisationId, +projectId, authUser);
    });
    return () => {
      impactStore.invokeUserLeft(organisationId, +projectId, authUser);
      // impactStore.stopConnection();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    impactStore.canInvoke() && impactStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [impactStore, organisationId, projectId, authUser]);

  return (
    <div className="detailed-impacts-view">
      <IF condition={impactStore.isLoading}>
        <PositionedSpinner />
      </IF>

      <IF condition={!impactStore.isLoading}>
        <ImpactsGrid
          data={impactStore.data}
          backendColumnDefs={impactStore.columnDefs}
          connectedUsers={impactStore.connectedUsers}
          onCellStateChange={impactStore.updateUserSelectedCell}
        />
      </IF>
    </div>
  );
});

export { DetailedImpactsView };
