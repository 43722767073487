import { Enums } from "./enums";
//General Constants
// export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
// export const ERROR_REPORT_EMAIL = 'jon.hay@sysdocgroup.com';
/**
 * Default application services.
 * This is a list of the services that can be registered with the
 * application DI container.
 */
export enum Services {}

export const LOG_LEVEL_NAMES = {
  [Enums.LogLevel.Diagnostics]: "Diagnostics",
  [Enums.LogLevel.Info]: "Info",
  [Enums.LogLevel.Warning]: "Warning",
  [Enums.LogLevel.Error]: "Error"
};

export const COMPANY_SIZE_OPTIONS = [
  { key: 1, label: "1 - 100 people" },
  { key: 2, label: "101 - 500 people" },
  { key: 3, label: "501 - 1000 people" },
  { key: 4, label: "1001 - 5000 people" },
  { key: 5, label: "5000+ people" }
];

export const IMPACT_TYPE_OPTIONS = [
  { key: Enums.ImpactType.PEOPLE, label: "phrases.people" },
  { key: Enums.ImpactType.PROCESS, label: "phrases.process" },
  { key: Enums.ImpactType.TECHNOLOGY, label: "phrases.technology" }
];

export const IMPACT_LEVEL_OPTIONS = [
  { key: Enums.ImpactLevel.LOW, label: "phrases.low" },
  { key: Enums.ImpactLevel.MEDIUM, label: "phrases.medium" },
  { key: Enums.ImpactLevel.HIGH, label: "phrases.high" },
  { key: Enums.ImpactLevel.UNKNOWN, label: "phrases.unknown" }
];
export const BULK_UPDATE_IMPACT_LEVEL_OPTIONS = [
  { key: Enums.ImpactLevelBulkUpdate.LOW, label: "phrases.low" },
  { key: Enums.ImpactLevelBulkUpdate.MEDIUM, label: "phrases.medium" },
  { key: Enums.ImpactLevelBulkUpdate.HIGH, label: "phrases.high" }
];

export const IMPACT_LEVELS_DROPDOWN = ["High", "Medium", "Low"];

export const STAKEHOLDER_FEELING_OPTIONS = [
  { key: Enums.ImpactLevel.LOW, label: "phrases.concerned" },
  { key: Enums.ImpactLevel.MEDIUM, label: "phrases.neutral" },
  { key: Enums.ImpactLevel.HIGH, label: "phrases.advocate" },
  { key: Enums.ImpactLevel.UNKNOWN, label: "phrases.unknown" }
];

export const MITIGATION_CONFIDENCE_OPTIONS = [
  { key: Enums.MitigationConfidence.LOW, label: "phrases.low" },
  { key: Enums.MitigationConfidence.MEDIUM, label: "phrases.medium" },
  { key: Enums.MitigationConfidence.HIGH, label: "phrases.high" },
  { key: Enums.MitigationConfidence.UNKNOWN, label: "phrases.unknown" }
];

export const PROGRESS_STATUS_OPTIONS = [
  { key: Enums.ProgressStatus.NOT_STARTED, label: "phrases.notStarted" },
  { key: Enums.ProgressStatus.IN_PROGRESS, label: "phrases.inProgress" },
  { key: Enums.ProgressStatus.COMPLETED, label: "phrases.completed" },
  { key: Enums.ProgressStatus.ONHOLD, label: "phrases.onHold" },
  { key: Enums.ProgressStatus.CANCELLED, label: "phrases.cancelled" }
];

export const ZERO_TO_TEN_OPTIONS = [
  { key: -1, label: "phrases.unknown" },
  { key: 0, label: "phrases.unknown" },
  { key: 1, label: "phrases.low" },
  { key: 2, label: "phrases.low" },
  { key: 3, label: "phrases.low" },
  { key: 4, label: "phrases.low" },
  { key: 5, label: "phrases.medium" },
  { key: 6, label: "phrases.medium" },
  { key: 7, label: "phrases.medium" },
  { key: 8, label: "phrases.high" },
  { key: 9, label: "phrases.high" },
  { key: 10, label: "phrases.high" }
];

export const COMMITMENT_PROFILING_OPTIONS = [
  { key: -1, label: "phrases.unknown" },
  { key: 0, label: "phrases.unknown" },
  { key: 1, label: "phrases.cautious" },
  { key: 2, label: "phrases.cautious" },
  { key: 3, label: "phrases.cautious" },
  { key: 4, label: "phrases.cautious" },
  { key: 5, label: "phrases.neutral" },
  { key: 6, label: "phrases.neutral" },
  { key: 7, label: "phrases.neutral" },
  { key: 8, label: "phrases.advocate" },
  { key: 9, label: "phrases.advocate" },
  { key: 10, label: "phrases.advocate" }
];

export const SENTIMENT_RECEPTIVENESS_OPTIONS = [
  { key: -1, label: "phrases.unknown" },
  { key: 0, label: "phrases.unknown" },
  { key: 1, label: "phrases.concerned" },
  { key: 2, label: "phrases.concerned" },
  { key: 3, label: "phrases.concerned" },
  { key: 4, label: "phrases.concerned" },
  { key: 5, label: "phrases.neutral" },
  { key: 6, label: "phrases.neutral" },
  { key: 7, label: "phrases.neutral" },
  { key: 8, label: "phrases.advocate" },
  { key: 9, label: "phrases.advocate" },
  { key: 10, label: "phrases.advocate" }
];

export const TIMELINE_PROGRESS_STATUS_OPTIONS = [
  { key: Enums.ProgressStatus.NOT_STARTED, label: "phrases.notStarted" },
  { key: Enums.ProgressStatus.IN_PROGRESS, label: "phrases.inProgress" },
  { key: Enums.ProgressStatus.COMPLETED, label: "phrases.completed" },
  { key: Enums.ProgressStatus.OVERDUE, label: "phrases.overdue" }
];

export const IMPACT_STATUS_OPTIONS = [
  { key: Enums.ProgressStatus.NOT_STARTED, label: "phrases.notStarted" },
  { key: Enums.ProgressStatus.IN_PROGRESS, label: "phrases.inProgress" },
  { key: Enums.ProgressStatus.COMPLETED, label: "phrases.mitigated" }
];

export const RAG_STATUS_OPTIONS = [
  { key: Enums.RagStatus.UNKNOWN, label: "phrases.unknown" },
  { key: Enums.RagStatus.RED, label: "phrases.red" },
  { key: Enums.RagStatus.AMBER, label: "phrases.amber" },
  { key: Enums.RagStatus.GREEN, label: "phrases.green" }
];

export const KEY_CONCERN_STATUS_OPTIONS = [
  { key: Enums.ConcernStatus.ACTIVE, label: "phrases.active" },
  { key: Enums.ConcernStatus.CLOSED, label: "phrases.closed" },
  { key: Enums.ConcernStatus.IRRELEVANT, label: "phrases.noLongerRelevant" }
];

export const MILESTONE_TYPE_OPTIONS = [
  { key: Enums.MilestoneType.GO_LIVE, label: "phrases.goLive" },
  { key: Enums.MilestoneType.COMMS, label: "phrases.comms" },
  { key: Enums.MilestoneType.CHANGE_PLAN, label: "phrases.changePlan" },
  { key: Enums.MilestoneType.ORG_ANNOUNCEMENT, label: "phrases.orgAnnouncement" },
  { key: Enums.MilestoneType.READINESS, label: "phrases.readiness" },
  { key: Enums.MilestoneType.TRAINING, label: "phrases.training" },
  { key: Enums.MilestoneType.ENGAGEMENT_ACTIVITY, label: "phrases.engagementActivity" },
  { key: Enums.MilestoneType.UAT, label: "phrases.uat" },
  { key: Enums.MilestoneType.DESIGN_CONFIGURATION, label: "phrases.designConfig" },
  { key: Enums.MilestoneType.DATA_MT, label: "phrases.dataMT" },
  { key: Enums.MilestoneType.HYPERCARE, label: "phrases.hypercare" },
  { key: Enums.MilestoneType.STAGEGATE, label: "phrases.stageGate" },
  { key: Enums.MilestoneType.OTHER, label: "phrases.other" }
];

export const DEFAULT_DATE: string = "0001-01-01T00:00:00";

export const PERMISSION_SCOPE_OPTIONS = [
  {
    key: Enums.PermissionScope.ADMIN,
    label: "phrases.admin",
    isAssignable: false
  },
  {
    key: Enums.PermissionScope.OWNER,
    label: "phrases.owner",
    isAssignable: true
  },
  {
    key: Enums.PermissionScope.CONTRIBUTOR,
    label: "phrases.contributor",
    isAssignable: true
  },
  {
    key: Enums.PermissionScope.NOACCESS,
    label: "phrases.noAccess",
    isAssignable: false
  },
  {
    key: Enums.PermissionScope.READER,
    label: "phrases.reader",
    isAssignable: true
  }
];

export const ORGANISATION_PERMISSION_SCOPE_OPTIONS = [
  {
    key: Enums.PermissionScope.OWNER,
    label: "phrases.owner",
    isAssignable: true
  },
  {
    key: Enums.PermissionScope.CONTRIBUTOR,
    label: "phrases.contributor",
    isAssignable: true
  },
  {
    key: Enums.PermissionScope.READER,
    label: "phrases.reader",
    isAssignable: true
  }
];

export const PROJECT_PERMISSION_SCOPE_OPTIONS = [
  {
    key: Enums.PermissionScope.OWNER,
    label: "phrases.owner",
    isAssignable: true,
    permissionActivity: "phrases.ownerActivity"
  },
  {
    key: Enums.PermissionScope.CONTRIBUTOR,
    label: "phrases.contributor",
    isAssignable: true,
    permissionActivity: "phrases.contributorActivity"
  },
  {
    key: Enums.PermissionScope.READER,
    label: "phrases.reader",
    isAssignable: true,
    permissionActivity: "phrases.readerActivity"
  }
];

export const GetI18nParsedList = (i18n, data, key = "label") => {
  data.forEach(e => GetI18nParsed(i18n, e, key));
  return data;
};

export const GetI18nParsed = (i18n, item, key = "label") => {
  let label = item[key] + "";
  if (
    label.startsWith("phrases.") ||
    label.startsWith("forms.") ||
    label.startsWith("placeholders.") ||
    label.startsWith("tables.")
  ) {
    item[key] = i18n.t(label);
  }
  return item;
};

export const IMPACT_REPORT_GROUPS = [
  { key: "businessAreas", label: "phrases.businessAreas" },
  { key: "locations", label: "phrases.locations" },
  { key: "stakeholders", label: "phrases.stakeholderAudience" }
];

export const REVIEW_DATE_RANGE_OPTIONS = [
  { key: Enums.ReviewRange.FOUR_TO_SIX_WEEKS, label: "phrases.reviewRange_FourToSixWeeks" },
  { key: Enums.ReviewRange.TWO_TO_FOUR_WEEKS, label: "phrases.reviewRange_TwoToFourWeeks" },
  { key: Enums.ReviewRange.WITHIN_TWO_WEEKS, label: "phrases.reviewRange_WithinTwoWeeks" },
  { key: Enums.ReviewRange.OVERDUE, label: "phrases.reviewRange_Overdue" }
];

export const DEBOUNCE_DELAY = {
  FAST: 250,
  NORMAL: 500,
  SLOW: 750
};

export const FORM_COL = {
  FULL_WIDTH: "col-12",
  HALF_WIDTH: "col-6",
  THIRD_WIDTH: "col-4"
};

export const USER_IMAGE_PLACEHOLDER = (firstName: string, lastName: string) =>
  `https://eu.ui-avatars.com/api/?background=fff&color=00135d&name=${firstName || "N"}+${lastName || "A"}`;
export const NAME_IMAGE_PLACEHOLDER = (firstName: string) =>
  `https://eu.ui-avatars.com/api/?background=fff&color=00135d&name=${firstName || "NA"}`;

export const LOCAL_STORAGE_FILTERS = {
  IMPACTS: "filters.impacts",
  ACTIONS: "filters.actions",
  STAKEHOLDERS: "filters.stakeholders",
  AUDIENCES: "filters.audiences",
  IMPACTGROUPS: "filters.impactgroups",
  PROJECT_TEAM_USERS: "filters.projectTeamUsers",
  PROJECT_INDIVIDUAL_USERS: "filters.projectIndividualUsers",
  USERS: "filters.users"
};

export const HEATMAP_FILTERS = {
  BUSINESS_AREA: "Business Area"
};

export const REMOVE_UNSAFE_CHARACTERS = (value: string) => value.replace(/[^A-Z0-9\s_!()@./#&+-=]/gi, "").trim();

export const HUB_URL = {
  PROJECT_ARTIFACTS_REPORTING: "project-artifacts-reporting"
};

export const parseUrlForProgrammeId = (path: string) => {
  let exp = new RegExp(/\/organisations\/(.*?)\/programmes\/(.*?)($|\/+)/g);
  var match = exp.exec(path);
  if (!exp.test(path) && match === null) return [null, null];
  let orgId = match.length > 1 ? +match[1] : null;
  let programmeId = match.length > 2 ? +match[2] : null;
  return [orgId, programmeId];
};

export const parseUrlForProjectId = (path: string) => {
  let exp = new RegExp(/\/organisations\/(.*?)\/projects\/(.*?)($|\/+)/g);
  var match = exp.exec(path);
  if (!exp.test(path) && match === null) return [null, null];
  let orgId = match.length > 1 ? +match[1] : null;
  let projectId = match.length > 2 ? +match[2] : null;
  return [orgId, projectId];
};

export const RouteParams = {
  organisationId: ":organisationId",
  programmeId: ":programmeId",
  projectId: ":projectId",
  actionId: ":actionId",
  impactId: ":impactId",
  impactGroupId: ":impactGroupId",
  type: ":type",
  page: ":page",
  section: ":section",
  stakeholderId: ":stakeholderId",
  stakeholderType: ":stakeholderType",
  profilingType: ":profilingType",
  userId: ":userId"
};

export const BASE_ROUTE = `/organisations/${RouteParams.organisationId}`;

export const containsFilterOptions = [
  {
    id: Enums.ConditionFilterKey.OR,
    name: "Contains: Either"
  },
  {
    id: Enums.ConditionFilterKey.AND,
    name: "Contains: All"
  }
];
