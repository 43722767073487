import { action, makeObservable, observable } from "mobx";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import ActionsHub, { ActionsHub as IActionsHub } from "../../../../services/hubs/ActionsHub/Actions_hub";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../services/api/v2/actionTypes/ActionTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";

export class ActionGridModel {
  projectId: number;
  actionsHub: IActionsHub;
  organisationId: number;
  @observable isLoading: boolean = true;
  actionTypeProvider: IActionTypesApi;
  @observable gridActions: FP.Entities.IActionSummary[];
  @observable.ref actionTypes: FP.Entities.IActionType[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedActions: number[] = [];
  gridApi: GridApi;
  impactsProvider: IImpactsApi;
  searchText: string;

  /**
   *
   */
  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.projectId = projectId;
    this.actionTypeProvider = ActionTypesApi;
    this.actionsHub = ActionsHub;
    this.impactsProvider = ImpactsApi;
    this.organisationId = organisationId;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.actionsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.actionsHub.isConnectionStarted === true) {
      await this.actionsHub.stopConnection();
    }
    await this.actionsHub.startConnection();

    this.actionsHub.onLoadData(d => {
      this.setActionRowData(d);
    });

    await this.actionsHub.invokeLoadData(this.organisationId, this.projectId);
  };

  @action
  setActionRowData = impacts => {
    this.gridActions = impacts;
    this.isLoading = false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedActions = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;
    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypeProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.actionTypes = res.payload;
  };
}
