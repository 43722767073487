import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { IF } from "../../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { ButtonIconDropdown } from "../../../../../components/ui/ButtonIconDropdown";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { Input } from "../../../../../components/ui/_forms/Input";
import { ConnectedUserAvatars } from "../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { CanEdit } from "../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { GridView } from "../../../../../core/grids/GridView_view";
import I18n from "../../../../../core/localization/I18n";
import { EntityTypes, Enums, UiSizes } from "../../../../../enums";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { MicroForm } from "../../../../change/forms/microForm/MicroForm";
import { SHOW_STAKEHOLDER_EMAILS } from "./ActionListView_modals";
import { ActionListViewModel } from "./ActionListView_model";
import { Animations } from "../../../../../core/util/Animations";

export interface ActionListViewProps {
  model?: ActionListViewModel;
}

const ActionListView: React.FunctionComponent<ActionListViewProps> = observer(({ model: m }) => {
  const navigate = useNavigate();
  const { actionStore } = useStores();
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const pId = !!projectId ? +projectId : null;
  const [model, setModel] = useState(() => new ActionListViewModel(navigate, organisationId, pId, authUser));
  const wrapTextKey = `projects-${projectId}-${EntityTypes.ACTIONS}-wrap-text`;
  const storageService = LocalStorageService;
  const filterRef = useRef(null);
  const [hasFilters, setHasFilters] = useState(false);
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  // const isAdmin = useIsOrganisationAdmin(organisationId);

  useEffect(
    () => {
      setModel(new ActionListViewModel(navigate, organisationId, pId, authUser));
    },
    // eslint-disable-next-line
    [projectId, organisationId]
  );

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    return () => actionStore.invokeUserLeft(organisationId, +projectId, authUser);
    // eslint-disable-next-line
  }, [actionStore]);

  useEffect(() => {
    actionStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [actionStore, organisationId, projectId, authUser]);

  return (
    <div className={`action-list-view ${Animations.FP_ZOOM_IN}`}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex">
            <CanEdit field={PermissionFields.ACTIONS} projectId={model.projectId}>
              <MicroForm
                iconSymbol={IconSymbols.Plus}
                fieldName="name"
                className="float-left mr-2"
                buttonPlaceholder={I18n.t("phrases.add")}
                formModel={model.microActionForm}
                onSubmit={model.createMicroAction}
              />
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedActions.length === 0}
                onClick={model.selectedActions.length > 0 ? model.showActionConfirmReviewModal : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.CommentPencil}
              >
                {I18n.t("phrases.review")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedActions.length === 0}
                onClick={() => {
                  SHOW_STAKEHOLDER_EMAILS(model.modalService, model.selectedActions, +projectId);
                }}
                symbol={IconSymbols.Mail}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.email")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedActions.length === 0}
                onClick={model.selectedActions.length > 0 ? model.showActionConfirmDeleteModal : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Trash}
              >
                {I18n.t("phrases.delete")}
              </ButtonIcon>

              <ButtonIconDropdown
                iconSymbol={IconSymbols.Link}
                buttonProps={{
                  size: UiSizes.SM,
                  type: ButtonTypes.LINK,
                  style: { display: "block" },
                  className: "p-0 mr-2",
                  isDisabled: model.selectedActions.length === 0
                }}
                tooltipWidth={210}
                actions={model.uiActions}
              >
                {I18n.t("phrases.link")}
              </ButtonIconDropdown>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedActions.length === 0}
                onClick={model.selectedActions.length > 0 ? model.showBulkEditModal : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Edit3}
              >
                {I18n.t("phrases.bulkEdit")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedActions.length === 0}
                onClick={model.selectedActions.length > 0 ? model.exportRows : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Download}
              >
                {I18n.t("phrases.export")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                onClick={() => model.showUploaderModel()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Upload}
              >
                {I18n.t("phrases.upload")}
              </ButtonIcon>
              <span className="vertical-line"></span>
              <ButtonIcon
                type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                onClick={() => {
                  setIsTextWrapToggled(!isTextWrapToggled);
                  filterRef.current.toggleTextWrapper();
                }}
                symbol={IconSymbols.TextWrap}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.textWrap")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasFilters}
                onClick={() => filterRef.current.clearFilters()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.ClearFilter}
              >
                {I18n.t("phrases.clearFilters")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasColumnChanges}
                onClick={() => filterRef.current.resetColumns()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.MonitorBack}
              >
                {I18n.t("phrases.resetColumns")}
              </ButtonIcon>
            </CanEdit>
          </div>
          <div className="col-3">
            <ConnectedUserAvatars connectedUsers={actionStore.connectedUsers} />
          </div>
          <div className="col-2">
            <div className="text-right">
              <Input
                autoFocus={true}
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                style={{ height: 32 }}
              />
            </div>
          </div>
        </div>
      </div>

      <IF condition={!actionStore.isLoading && !model.gridModel.isLoading}>
        <div className={Animations.FP_ZOOM_IN}>
          <GridView
            context={{
              deleteFn: model.deleteFieldData
            }}
            users={actionStore.connectedUsers}
            data={actionStore.data}
            onCellClicked={actionStore.updateUserSelectedCell(organisationId, +projectId)}
            onCellEditModeChange={actionStore.updateUserSelectedCell(organisationId, +projectId)}
            onSelectionChanged={model.updateSelectedActions}
            model={model.gridModel}
            isLoading={model.gridModel.isLoading}
            onGridReady={model.onGridReady}
            ref={filterRef}
            filterHasChangedFn={setHasFilters}
            columnOrderHasChangedFn={setHasColumnChanges}
            overlayNoRowsTemplate={null}
          />
        </div>
      </IF>
    </div>
  );
});

export { ActionListView };
