import { GetFilterParameterFromUrl } from "../../../../../core/grids/GridQueryStringHelpers";
import * as GridFilterHelpers from "../../../../../core/grids/GridFilterHelpers";

enum QueryStringParts {
  Influence = "influence",
  Impact = "impact",
  Sentiment = "sentiment",
  Commitment = "commitment",
  Receptiveness = "receptiveness",
  Location = "location",
  BusinessArea = "businessArea",
  WithinMonth = "withinMonth",
  WithinYear = "withinYear",
  GapAnalysis = "ga",
  Value = "value",
  Level = "level",
  Type = "type",
  Owner = "owner",
  CreatedBy = "createdByName",
  ReviewedBy = "reviewedByName",
  OwnerNames = "ownerNames"
}

const decodeParameter = (parameter: string) => {
  return parameter === null ? null : decodeURI(parameter);
};

const GetImpactParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Impact);
  return decodeParameter(s);
};

const GetInfluenceParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Influence);
  return decodeParameter(s);
};

const GetSentimentParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Sentiment);
  return decodeParameter(s);
};

const GetCommitmentParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Commitment);
  return decodeParameter(s);
};

const GetReceptivenessParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Receptiveness);
  return decodeParameter(s);
};

export const GetLocationParameter = () => {
  var parameter = GetFilterParameterFromUrl(QueryStringParts.Location);
  return decodeParameter(parameter);
};
export const GetOwnerNamesParameter = () => {
  var parameter = GetFilterParameterFromUrl(QueryStringParts.OwnerNames);
  return decodeParameter(parameter);
};

export const GetBusinessAreaParameter = () => {
  var parameter = GetFilterParameterFromUrl(QueryStringParts.BusinessArea);
  return decodeParameter(parameter);
};

export const GetGapAnalysisParameter = () => {
  var parameter = GetFilterParameterFromUrl(QueryStringParts.GapAnalysis);
  return parameter;
};

export const GetValueParameter = () => {
  var parameter = GetFilterParameterFromUrl(QueryStringParts.Value);
  return parameter;
};

export const GetStakeholderURLFilters = () => {
  const impact = GetImpactParameter();
  const influence = GetInfluenceParameter();
  const sentiment = GetSentimentParameter();
  const commitment = GetCommitmentParameter();
  const receptiveness = GetReceptivenessParameter();
  const location: string = GetLocationParameter();
  const businessArea: string = GetBusinessAreaParameter();
  const gapAnalysis: string = GetGapAnalysisParameter();
  const owner: string = GetFilterParameterFromUrl(QueryStringParts.Owner);
  const createdBy = GetFilterParameterFromUrl(QueryStringParts.CreatedBy);
  const reviewedBy = GetFilterParameterFromUrl(QueryStringParts.ReviewedBy);
  const ownerNames = GetFilterParameterFromUrl(QueryStringParts.OwnerNames);

  let filter = {};

  if (owner !== null) {
    filter["ownerName"] = GridFilterHelpers.textEquals_Filter(owner);
  }
  if (ownerNames !== null) {
    filter["ownerNames"] = GridFilterHelpers.textContains_Filter(ownerNames);
  }
  if (createdBy !== null) {
    filter["createdByName"] = GridFilterHelpers.textEquals_Filter(createdBy);
  }
  if (reviewedBy !== null) {
    filter["reviewedByName"] = GridFilterHelpers.textEquals_Filter(reviewedBy);
  }
  if (impact !== null) {
    filter["impact"] = GridFilterHelpers.textEquals_Filter(impact);
  }
  if (influence !== null) {
    filter["influence"] = GridFilterHelpers.textEquals_Filter(influence);
  }
  if (sentiment !== null) {
    filter["sentiment"] = GridFilterHelpers.textEquals_Filter(sentiment);
  }
  if (commitment !== null) {
    filter["commitment"] = GridFilterHelpers.textEquals_Filter(commitment);
  }
  if (receptiveness !== null) {
    filter["receptiveness"] = GridFilterHelpers.textEquals_Filter(receptiveness);
  }
  if (location !== null) {
    filter["locationNames"] = GridFilterHelpers.textEquals_Filter(location);
  }
  if (businessArea !== null) {
    filter["businessAreaNames"] = GridFilterHelpers.textEquals_Filter(businessArea);
  }

  if (gapAnalysis !== null) {
    const value: string = GetValueParameter();
    const hasValue: string = "1|2|3|4|5|6|7|8|9";
    const doesNotHaveValue: string = "-1";

    switch (gapAnalysis) {
      case "sentiment":
        filter["sentiment"] =
          value === "1"
            ? GridFilterHelpers.textEquals_Filter(hasValue)
            : GridFilterHelpers.textEquals_Filter(doesNotHaveValue);
        filter["impacts"] = value === "1" ? GridFilterHelpers.notBlank_Filter() : GridFilterHelpers.blank_Filter();
        break;
      case "commitment":
        filter["commitment"] =
          value === "1"
            ? GridFilterHelpers.textEquals_Filter(hasValue)
            : GridFilterHelpers.textEquals_Filter(doesNotHaveValue);
        filter["impacts"] = value === "1" ? GridFilterHelpers.notBlank_Filter() : GridFilterHelpers.blank_Filter();
        break;
      case "receptiveness":
        filter["receptiveness"] =
          value === "1"
            ? GridFilterHelpers.textEquals_Filter(hasValue)
            : GridFilterHelpers.textEquals_Filter(doesNotHaveValue);
        filter["impacts"] = value === "1" ? GridFilterHelpers.notBlank_Filter() : GridFilterHelpers.blank_Filter();
        break;
    }
  }

  return filter;
};
