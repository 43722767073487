// TODO - Make Milestones settings reusable across projects and programmes

import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action, makeObservable } from "mobx";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { MilestoneModal } from "./MilestoneModal_view";
import { MilestoneModalModel } from "./MilestoneModal_model";
import I18n from "../../../../../core/localization/I18n";
import moment from "moment";
import { Animations } from "../../../../../core/util/Animations";
import { Panel } from "../../../../../components/ui/Panel";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Translator } from "../../../../../enums";
import MilestonesApi, {
  MilestonesApi as IMilestonesApi
} from "../../../../../services/api/v2/milestones/Milestones.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class MilestoneSettingsModel extends BaseModel {
  projectId: number;
  @observable.ref project?: FP.Entities.IProject;
  projectsProvider: IProjectsApi;
  milestoneProvider: IMilestonesApi;
  tableModel: ITableModel<FP.Entities.IMilestone>;
  modalService: IModalContextModel;
  organisationId: number;

  constructor(organisationId: number, projectId: number) {
    super();
    makeObservable(this);
    this.projectsProvider = ProjectsApi;
    this.projectId = projectId;
    this.milestoneProvider = MilestonesApi;
    this.modalService = ModalContext;
    this.organisationId = organisationId;
    this.tableModel = new TableModel();
    let tableConfig: ITableConfig<any> = {
      colHeaders: [
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => obj.name
        },
        {
          key: "deadline",
          content: <h4 className="mb-0">{I18n.t("table.milestoneDate")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => moment(obj.deadline).format("L")
        },
        {
          key: "milestoneType",
          content: <h4 className="mb-0">{I18n.t("table.milestoneType")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => Translator.MilestoneType(obj.milestoneType)
        }
      ] as any[],
      actions: [
        {
          id: "edit",
          label: I18n.t("phrases.edit"),
          onAction: (ev, row: ITableRowModel) => {
            this.showMilestoneModal(row.rowObject);
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            this.showConfirmDeleteModal(row.rowObject);
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          rendersIn: UiActionRenderers.BUTTON
        }
      ]
    };

    this.tableModel.set(tableConfig);
  }

  onMount = async () => {
    await this.loadProject();
  };

  @action
  async loadProject() {
    this.tableModel.isLoading = true;
    const res = await this.projectsProvider.getById(this.organisationId, this.projectId);
    if (res) {
      this.setProject(res.payload);
    }
  }

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
    this.tableModel.setData(project.milestones);
    this.tableModel.isLoading = false;
  };

  showModal = (row: ITableRowModel) => {
    this.showMilestoneModal(row.rowObject);
  };

  @action
  showMilestoneModal = (milestone?: FP.Entities.IMilestone) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon symbol={IconSymbols.Close} className="float-right mr-1" onClick={this.modalService.hide} />
        </div>
      ),
      content: (
        <MilestoneModal
          model={new MilestoneModalModel(this.organisationId, milestone, () => this.loadProject(), this.project.id)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        overflow: "visible",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showConfirmDeleteModal = (milestone: FP.Entities.IMilestone) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: milestone.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          const res = await this.milestoneProvider.deleteProjectMilestone(
            this.organisationId,
            this.project.id,
            milestone.id
          );
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.modalService.hide();
          this.loadProject();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
