import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../../../core/grids/GridSideModals";
import I18n from "../../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { ActionDescriptionSidebarModel } from "../actionDescriptionSidebar/ActionDescriptionSidebar_model";
import { ActionDescriptionSidebar } from "../actionDescriptionSidebar/ActionDescriptionSidebar_view";
import { ActionImpactsSidebarModel } from "./actionImpactsSidebar/ActionImpactsSidebar_model";
import { ActionImpactsSidebar } from "./actionImpactsSidebar/ActionImpactsSidebar_view";
import { ActionNotesSideBar } from "./ActionNotesSidebar";
import { ActionTagsSidebarModel } from "./actionTagsSidebar/ActionTagsSidebar_model";
import { ActionTagsSidebar } from "./actionTagsSidebar/ActionTagsSidebar_view";

export interface IActionGridModals {
  showEditActionDescriptionFN?: (action: FP.Entities.IAction) => void;
  showNotesModalFN?: (action: FP.Entities.IAction) => void;
  showImpactsModalFN?: (action: FP.Entities.IAction) => void;
  showTagsModalFN: (action: FP.Entities.IAction) => void;
}

export const getActionGridModals = (organisationId: number, projectId: number): IActionGridModals => {
  const modalService = ModalContext;

  const showTagsModal = (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.tags")} />,
      content: (
        <ActionTagsSidebar
          model={new ActionTagsSidebarModel(modalService.hide, action.id, organisationId, projectId)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showImpactsModal = (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
      content: <ActionImpactsSidebar model={new ActionImpactsSidebarModel(action.id, organisationId, projectId)} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showEditDescriptionModal = async (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.description")} />,
      content: (
        <ActionDescriptionSidebar
          model={new ActionDescriptionSidebarModel(modalService.hide, organisationId, projectId, action.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showNotesModal = (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.notes")} />,
      content: <ActionNotesSideBar projectId={projectId} organisationId={organisationId} actionId={action.id} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const ActionGridModals: IActionGridModals = {
    showTagsModalFN: showTagsModal,
    showImpactsModalFN: showImpactsModal,
    showEditActionDescriptionFN: showEditDescriptionModal,
    showNotesModalFN: showNotesModal
  };

  return ActionGridModals;
};
