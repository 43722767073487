import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import { ActionField } from "../../../../enums";
import http from "../Http";

export class ActionsApi extends ExportableProjectApi<FP.Entities.IAction> {
  controller: string = "actions";

  constructor(http: AxiosInstance) {
    super(http, "actions");
  }

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  deleteField = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    actionField: ActionField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/${actionField}`,
      config
    );
    return res.data;
  };

  reviewRange = async (
    organisationId: number,
    projectId: number,
    ids: number[],
    comment: string,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/review-range`,
      { actionIds: ids, comment },
      config
    );
    return res.data;
  };

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    projectId?: number,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`,
      config
    );
    return res.data;
  };

  getActionsSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/actions-summary`,
      config
    );
    return res.data;
  };

  async updateDates(
    organisationId: number,
    projectId: number,
    actionId: number,
    data: FP.Entities.IAction,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> {
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/setDates`,
      data,
      config
    );
    return res.data;
  }

  getDetailedByIdAsync = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`,
      config
    );
    return res.data;
  };

  getActionCount = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/total-count`,
      config
    );
    return res.data;
  };

  addImpactsAsync = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/impacts`,
      impactIds,
      config
    );
    return res.data;
  };

  bulkUpdateActions = async (
    organisationId: number,
    projectId: number,
    actionIds: number[],
    fields: any,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/bulk-update-actions`;
    let res = await this.http.post(url, { actionIds, ...fields }, config);
    return res.data;
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/impacts/${impactId}`,
      config
    );
    return res.data;
  };

  getUnassignedImpactsAsync = async (
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/unassignedImpacts`,
      config
    );
    return res.data;
  };

  getComments = async (organisationId: number, projectId: number, actionId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/comments`,
      config
    );
    return res.data;
  };

  addComment = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/comments`,
      comment,
      config
    );
    return res.data;
  };

  async updateField(
    organisationId: number,
    projectId: number,
    actionId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    actionField: ActionField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/${actionField}`,
      data,
      config
    );
    return res.data;
  }

  getStakeholderEmailsByActionIds = async (
    organisationId: number,
    projectId: number,
    actionIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<string[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${
      this.controller
    }/get-stakeholder-emails?actionIds=${actionIds.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<string[]>;
    }
  };

  getBusinessAreaActionProgressReport = async (
    organisationId: number,
    projectId: number,
    businessAreaIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let query = "";
    if (businessAreaIds) {
      query = businessAreaIds.join("&businessAreaIds=");
      query = "?businessAreaIds=" + query;
    }
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/business-area-action-progress-report${query}`,
      config
    );
    return res.data;
  };

  getAssignedToActionProgressReport = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/assigned-to-action-progress-report`,
      config
    );
    return res.data;
  };

  getBusinessAreaActionRagBreakdownReport = async (
    organisationId: number,
    projectId: number,
    businessAreaIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let query = "";
    if (businessAreaIds) {
      query = businessAreaIds.join("&businessAreaIds=");
      query = "?businessAreaIds=" + query;
    }
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/business-area-action-rag-report${query}`,
      config
    );
    return res.data;
  };

  getAudienceActionProgressReport = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/audience-action-progress-report`,
      config
    );
    return res.data;
  };

  getAssignedToActionRagBreakdownReport = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/assigned-to-action-rag-report`,
      config
    );
    return res.data;
  };

  getAudienceActionRagBreakdownReport = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/audience-action-rag-report`,
      config
    );
    return res.data;
  };

  assignStakeholderOwners = async (
    organisationId: number,
    projectId: number,
    actionIds: number[],
    stakeholderIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/project-stakeholder-owners`,
      { actionIds, projectStakeholderIds: stakeholderIds },
      config
    );
    return res.data;
  };

  assignLabels = async (
    organisationId: number,
    projectId: number,
    actionIds: number[],
    labelIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/tags`,
      { actionIds, tagIds: labelIds },
      config
    );
    return res.data;
  };

  getUnassignedStakeholderOwnerActions = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/project-stakeholder-owners/${projectStakeholderId}/unassigned`,
      config
    );
    return res.data;
  };

  getUnassignedStakeholderOwners = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/project-stakeholder-owners/unassigned`,
      config
    );
    return res.data;
  };

  getStakeholderOwnersByActionId = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/project-stakeholder-owners/`,
      config
    );
    return res.data;
  };

  getActionsByStakeholderOwnerId = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/project-stakeholder-owners/${projectStakeholderId}`,
      config
    );
    return res.data;
  };

  removeActionStakeholderOwner = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/project-stakeholder-owners/${projectStakeholderId}`,
      config
    );
    return res.data;
  };

  addMultiple = async (organisationId: number, projectId: number, actions: any[], config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-multiple`;
    let res = await this.http.post(url, { actions }, config);
    return res.data;
  };
}

const instance = new ActionsApi(http);
export default instance;
