import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import ImpactReportsHub, {
  ImpactReportsHub as IImpactReportsHub
} from "../../../../services/hubs/ImpactReportsHub/impactreports_hub";
import ImpactsHub, { ImpactsHub as IImpactsHub } from "../../../../services/hubs/ImpactsHub/Impacts_hub";
import { ImpactReportingViewChartProps } from "./ImpactReportingView_ChartProps";
import Pages from "../../../../routes/InsightRoutes";
import { NavigateFunction } from "react-router-dom";

export class ImpactReportingViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  organisationId: number;
  projectId: number;
  @observable impactCount: number;
  @observable impactGroupCount: number;
  @observable impacts: FP.Entities.IImpactSummary[];
  impactReportsHub: IImpactReportsHub;
  impactsHub: IImpactsHub;

  @observable byTypeChartDataProps: ImpactReportingViewChartProps;
  @observable byLocationChartDataProps: ImpactReportingViewChartProps;
  @observable byBusinessAreaChartDataProps: ImpactReportingViewChartProps;
  @observable byOwnerAreaChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderSentimentChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderCommitmentChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderReceptivenessChartDataProps: ImpactReportingViewChartProps;
  navigate: NavigateFunction;

  @observable ragData: any[];
  averageDataConfidence: number = 0;

  authUser: FP.Entities.IUser;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser, navigate: NavigateFunction) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.navigate = navigate;
    this.projectProvider = ProjectsApi;
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.impactReportsHub = ImpactReportsHub;
    this.impactsHub = ImpactsHub;

    this.setCountsForImpactAndImpactGroup();

    this.byTypeChartDataProps = new ImpactReportingViewChartProps(this.organisationId, this.projectId, navigate);
    this.byLocationChartDataProps = new ImpactReportingViewChartProps(this.organisationId, this.projectId, navigate);
    this.byBusinessAreaChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
    this.byOwnerAreaChartDataProps = new ImpactReportingViewChartProps(this.organisationId, this.projectId, navigate);
    this.byStakeholderSentimentChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
    this.byStakeholderCommitmentChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
    this.byStakeholderReceptivenessChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.impactReportsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.impactReportsHub.isConnectionStarted === true) {
      await this.impactReportsHub.stopConnection();
    }
    await this.impactReportsHub.startConnection();

    if (this.impactsHub.isConnectionStarted === true) {
      await this.impactsHub.stopConnection();
    }
    await this.impactsHub.startConnection();

    this.impactReportsHub.onImpactsByTypeChartData(d => {
      this.setByTypeData(d);
    });

    this.impactReportsHub.onImpactsByLocationChartData(d => {
      this.setByLocationData(d);
    });

    this.impactReportsHub.onImpactsByBusinessAreaChartData(d => {
      this.setByBusinessAreaData(d);
    });

    this.impactReportsHub.onImpactsByOwnerChartData(d => {
      this.setByOwnerData(d);
    });

    this.impactReportsHub.onImpactsByStakeholderSentimentChartData(d => {
      this.setByStakeholderSentimentData(d);
    });

    this.impactReportsHub.onImpactsByStakeholderCommitmentChartData(d => {
      this.setByStakeholderCommitmentData(d);
    });

    this.impactReportsHub.onImpactsByStakeholderReceptivenessChartData(d => {
      this.setByStakeholderReceptivenessData(d);
    });

    this.impactsHub.onLoadData(d => {
      this.setData(d.data);
      this.loadImpactLevelStatus();
    });

    await this.impactReportsHub.InvokeImpactReportByTypeData(this.organisationId, this.projectId);
    await this.impactReportsHub.InvokeImpactReportByLocationData(this.organisationId, this.projectId);
    await this.impactReportsHub.InvokeImpactReportByBusinessAreaData(this.organisationId, this.projectId);
    await this.impactReportsHub.InvokeImpactReportByOwnerData(this.organisationId, this.projectId);
    // await this.impactReportsHub.InvokeImpactReportByStakeholderSentimentData(this.organisationId, this.projectId);
    // await this.impactReportsHub.InvokeImpactReportByStakeholderCommitmentData(this.organisationId, this.projectId);
    // await this.impactReportsHub.InvokeImpactReportByStakeholderReceptivenessData(this.organisationId, this.projectId);
    await this.impactsHub.invokeLoadData(this.organisationId, this.projectId);
  };

  @action
  setByTypeData = chartData => {
    this.byTypeChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByLocationData = chartData => {
    this.byLocationChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByBusinessAreaData = chartData => {
    this.byBusinessAreaChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByOwnerData = chartData => {
    this.byOwnerAreaChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderSentimentData = chartData => {
    this.byStakeholderSentimentChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderCommitmentData = chartData => {
    this.byStakeholderCommitmentChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderReceptivenessData = chartData => {
    this.byStakeholderReceptivenessChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setData = impacts => {
    this.impacts = impacts;
    this.impactCount = impacts.length;
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.navigate(
        Pages.projects.impacts.visualisations.impactAssesment.generateLink(this.organisationId, this.projectId)
      );
      return;
    }

    if (newTabIndex === 0) {
      this.navigate(Pages.projects.impactGroups.listView.generateLink(this.organisationId, this.projectId));
      return;
    }
    this.navigate(Pages.projects.impacts.listView.generateLink(this.organisationId, this.projectId));
  };

  @action
  calculateAverageDataConfidence = () => {
    let allDataConfidence: number[] = [
      this.byTypeChartDataProps.DataConfidence,
      this.byLocationChartDataProps.DataConfidence,
      this.byBusinessAreaChartDataProps.DataConfidence,
      this.byOwnerAreaChartDataProps.DataConfidence
      // this.byStakeholderSentimentChartDataProps.DataConfidence,
      // this.byStakeholderCommitmentChartDataProps.DataConfidence,
      // this.byStakeholderReceptivenessChartDataProps.DataConfidence
    ];

    this.averageDataConfidence = Math.round(
      allDataConfidence.reduce((partialSum, a) => partialSum + a, 0) / allDataConfidence.length
    );
  };

  @action
  loadImpactLevelStatus = () => {
    this.ragData = [
      {
        label: I18n.t("visualisations.level_high"),
        value: this.impacts.filter(e => e.impactLevel > 7).length,
        id: "high"
      },
      {
        label: I18n.t("visualisations.level_medium"),
        value: this.impacts.filter(e => e.impactLevel > 4 && e.impactLevel < 8).length,
        id: "medium"
      },
      {
        label: I18n.t("visualisations.level_low"),
        value: this.impacts.filter(e => e.impactLevel > -1 && e.impactLevel < 5).length,
        id: "low"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: this.impacts.filter(e => e.impactLevel === -1 || e.impactLevel === 0).length,
        id: "unknown"
      }
    ];
  };
}
