import { observer } from "mobx-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../../apps/insight/stores/RootStore";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGrid } from "../base/AppGrid_view";
import { ImpactGroupsGridViewModel } from "./ImpactGroupsGridView_model";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { ModalGridToolbar } from "../base/ModalGridToolbar_view";
import { AppGridToolbarType } from "../../types/AppGrid_types";

interface ImpactGroupsGridViewProps {
  gridToolbarType?: AppGridToolbarType;
  onAssignSuccess?: (selectedImpacts: number[]) => void;
  selectedLinkItems?: number[];
}

export const ImpactGroupsGridView: React.FunctionComponent<ImpactGroupsGridViewProps> = observer(
  ({ gridToolbarType = "base", onAssignSuccess, selectedLinkItems }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const { projectId } = useParams<{ projectId: string }>();
    const { impactGroupStore, impactStore } = useStores();
    const gridRef = useRef<AppGrid>();

    const [gridModel, setGridModel] = useState(
      () => new ImpactGroupsGridViewModel(organisationId, +projectId, authUser, impactStore)
    );

    const columns = useMemo(() => {
      return gridModel.getColumnConfig();
    }, [gridModel]);

    useEffect(() => {
      setGridModel(() => new ImpactGroupsGridViewModel(organisationId, +projectId, authUser, impactStore));
      return () => {};
      // eslint-disable-next-line
    }, [organisationId, projectId]);

    useEffect(() => {
      impactGroupStore.invokeDataLoad(organisationId, +projectId, authUser);
      return () => {
        impactGroupStore.invokeUserLeft(organisationId, +projectId, authUser);
      };
      // eslint-disable-next-line
    }, [organisationId, projectId, authUser]);

    return (
      <>
        <IF condition={impactGroupStore.isLoading}>
          <PositionedSpinner />
        </IF>
        <IF condition={!impactGroupStore.isLoading}>
          <IF condition={gridToolbarType === "all" || gridToolbarType === "link-modal"}>
            <ModalGridToolbar
              className="impact-action-grid"
              onQuichSearchInputChange={e => {
                gridRef?.current?.setSearchText(e.currentTarget.value);
              }}
              selectedItems={gridModel.selectedItems}
              onAssignSuccess={onAssignSuccess}
            />
          </IF>
          <IF condition={gridToolbarType === "all" || gridToolbarType === "base"}>
            <AppGridToolbar
              connectedUsers={impactGroupStore.connectedUsers}
              onQuichSearchInputChange={e => {
                gridRef.current.setSearchText(e.currentTarget.value);
              }}
              selectedItemCount={gridModel.selectedItems.length}
              actions={gridModel.gridActions}
            />
          </IF>
          <AppGrid
            ref={gridRef}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            rowData={impactGroupStore.data}
            columnDefs={columns}
            shouldTrackSelectedCell={true}
            connectedUsers={impactGroupStore.connectedUsers}
            onCellClicked={impactGroupStore.updateUserSelectedCell(organisationId, +projectId)}
            onCellEditModeChange={impactGroupStore.updateUserSelectedCell(organisationId, +projectId)}
            rowSelection="multiple" // Options - allows click selection of rows
          />
        </IF>
      </>
    );
  }
);
