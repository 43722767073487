import React, { Dispatch } from "react";
import { ButtonTypes } from "../../../../../../../components/ui/Button";
import I18n from "../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getStakeholderImpactFormFields } from "./StakeholderImpactsSidebar_formFields";
import { ProjectStakeholdersApi } from "../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

interface AddImpactFormProps {
  projectStakeholderProvider: ProjectStakeholdersApi;
  organisationId: number;
  projectId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  reloadFn: (id: number) => void;
  setFormIsShown?: Dispatch<boolean>;
}

export const AddImpactForm: React.FC<AddImpactFormProps> = props => {
  const { projectStakeholderProvider, projectStakeholder, organisationId, reloadFn, setFormIsShown, projectId } = props;
  let formFields = getStakeholderImpactFormFields(
    projectStakeholderProvider,
    organisationId,
    projectStakeholder,
    projectId
  );
  let formModel = new SingleFormModel();
  let actions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.cancel"),
      onAction: ev => {
        setFormIsShown(false);
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("phrases.add"),
      onAction: async () => {
        const formRes = await formModel.submit();
        if (!formRes) return;
        const res = await projectStakeholderProvider.addImpacts(
          organisationId,
          projectId,
          projectStakeholder.id,
          formRes.impacts
        );

        if (!res || res.isError) return;

        if (res.payload) {
          reloadFn(projectStakeholder.stakeholder.id);
        }
      },
      componentProps: {
        className: "ml-2"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  formModel.formFields = formFields;
  formModel.actions = actions;

  return <div className="container-fluid">{formModel.renderComponent()}</div>;
};
