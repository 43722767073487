import React, { useEffect, useState } from "react";
import I18n from "../../../../core/localization/I18n";
import { Panel } from "../../../../components/ui/Panel";
import { Accordion } from "../../../../components/ui/Accordion";
import { StakeholderProgress } from "../../../change/dashboard/userReports/userStakeholders/UserStakeholders_config";
import { Enums } from "../../../../enums";
import { Link } from "react-router-dom";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { StakeholdersModel } from "./Stakeholders_model";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { IF } from "../../../../components/hoc/If";

export const Stakeholders: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new StakeholdersModel(organisationId));
  let data = model.stakeholders;

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return (
      <div className={`report-card report-card--md pb-5`}>
        <Panel.Panel
          className={`p-4 `}
          hasBorder={true}
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_LIGHT}
        >
          <h3>{I18n.t("phrases.myStakeholders")}</h3>
          <PositionedSpinner />
        </Panel.Panel>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className={`report-card report-card--md pb-5`}>
        <Panel.Panel
          className={`p-4 `}
          hasBorder={true}
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_LIGHT}
        >
          <div>{I18n.t("phrases.noStakeholderRelationships")}</div>
        </Panel.Panel>
      </div>
    );
  }

  return (
    <div className={`report-card report-card--md pb-5`}>
      <Panel.Panel
        className={`p-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <h3>{I18n.t("phrases.myStakeholders")}</h3>
        <IF condition={model.isLoading}>
          <PositionedSpinner />
        </IF>

        <IF condition={!model.isLoading && (!data || data.length === 0)}>
          <div>{I18n.t("phrases.noStakeholderRelationships")}</div>
        </IF>

        <IF condition={!model.isLoading && data.length > 0}>
          <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="row mx-0">
            <div className="col-5">
              <h4 className="mb-0 strong my-3">{I18n.t("table.stakeholders")}</h4>
            </div>
            <div className="col-3 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.project")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.influence")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.impact")}</h4>
            </div>
          </Panel.Panel>
          {data.map((stakeholders: any[]) => {
            let projectNo = stakeholders.length;
            return (
              <Accordion
                key={stakeholders[0].id + stakeholders[0].projectName}
                side="right"
                hideArrow={projectNo <= 1}
                header={
                  <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="p-0 row mx-0">
                    <div className="col-5 my-3">
                      <StakeholderProgress projectStakeholder={stakeholders[0]} isProject={false} />
                    </div>
                    <div className="col-3 p-0 my-3">
                      <p className="mb-0">{projectNo > 1 ? projectNo : stakeholders[0].projectName}</p>
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">
                        {projectNo > 1 ? "" : Enums.Translator.InfluenceLevel(stakeholders[0].influence)}
                      </p>
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">
                        {projectNo > 1 ? "" : Enums.Translator.ImpactLevel(stakeholders[0].impact)}
                      </p>
                    </div>
                  </Panel.Panel>
                }
              >
                {projectNo > 1 &&
                  stakeholders?.map((pStakeholder: any) => {
                    return (
                      <Panel.Panel
                        type={Panel.PanelTypes.BORDER_LINES_Y}
                        key={pStakeholder.stakeholderId + "-s" + pStakeholder.projectId}
                        className="p-0 row mx-0"
                      >
                        <div className="col-5 my-3">
                          <Link
                            to={`/organisations/${pStakeholder.organisationId}/projects/${pStakeholder.projectId}/stakeholders/${pStakeholder.stakeholderId}`}
                          >
                            <StakeholderProgress projectStakeholder={pStakeholder} isProject={true} />
                          </Link>
                        </div>
                        <div className="col-3 p-0 my-3">
                          <p className="mb-0">{pStakeholder.projectName}</p>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{Enums.Translator.InfluenceLevel(pStakeholder.influence)}</p>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{Enums.Translator.ImpactLevel(pStakeholder.impact)}</p>
                        </div>
                      </Panel.Panel>
                    );
                  })}
              </Accordion>
            );
          })}
          <div className="row mt-4">
            <div className="col div d-flex">
              <LinkButton type={ButtonTypes.OUTLINE_PRIMARY} href="/dashboard/userStakeholders" className="ml-auto">
                {I18n.t("phrases.seeAll")}
              </LinkButton>
            </div>
          </div>
        </IF>
      </Panel.Panel>
    </div>
  );
});
