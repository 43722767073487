import { ColDef } from "ag-grid-community";
import { IGridFns } from "../../../../../../../core/grids/GridFunctions";
import { colName, colRefNumber, colSelected } from "../../../../../../../core/grids/CommonGridColumns";
import { EntityTypes, ImpactField } from "../../../../../../../enums";

export interface IGetImpactGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  projectTeamMembers: FP.Entities.IUser[];
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  gridFns: IGridFns;
  pinned: "left" | "right" | null;
}

export const GetImpactGroupGridViewColumns = (modelProps: IGetImpactGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  const canEdit = modelProps.canEdit;

  result.push(colSelected());

  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.IMPACTS, { pinned: modelProps.pinned })
  );
  result.push(
    colName(canEdit, canEdit && modelProps.gridFns.updateTextField, ImpactField.name, "", {
      width: 300,
      pinned: modelProps.pinned
    })
  );

  return result;
};
