import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { GridModalBuilder } from "../../modals/GridModal_builder";

export abstract class BaseGridColumnBuilder {
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  organisationId: number;
  projectId: number;
  provider: any;
  gridModalBuilder: GridModalBuilder;

  /**
   *
   */
  constructor(provider: any, organisationId: number, projectId: number) {
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.provider = provider;
    this.gridModalBuilder = new GridModalBuilder();
  }

  updateTextField = async (field: any, entityId: number, text: string): Promise<boolean> => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.provider.updateField(this.organisationId, this.projectId, entityId, text, 0, null, 0, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
      return true;
    }

    return false;
  };

  updateIdField = async (field: any, entityId: number, id: number) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.provider.updateField(this.organisationId, this.projectId, entityId, "", id, null, 0, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
    }
  };

  updateDateField = async (field: any, entityId: number, date: Date) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.provider.updateField(this.organisationId, this.projectId, entityId, "", 0, date, 0, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
    }
  };

  updateNumericField = async (field: any, entityId: number, numeric: number) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.provider.updateField(
      this.organisationId,
      this.projectId,
      entityId,
      "",
      0,
      null,
      numeric,
      field
    );
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
    }
  };
}
