import { FilterChangedEvent, GridApi } from "ag-grid-community";
import { observable } from "mobx";
import { IGridAddon } from "./IGridAddon";
import LocalStorageService, {
  LocalStorageService as ILocalStorageService
} from "../../../../../../../services/local/localStorageService/LocalStorageService";
import { EntityTypes } from "../../../../../../../enums";
import _ from "lodash";

export interface IFilterStoreAddonReturnProps {
  isExternalFilterPresent: boolean;
  doesExternalFilterPass: boolean;
}

export interface IFilterStoreAddonProps {
  projectId: number;
  gridType: EntityTypes;
  filterHasChangedFn: (value: boolean) => void;
  urlSearchParams?: URLSearchParams;
}

export class FilterStoreAddon implements IGridAddon<IFilterStoreAddonReturnProps> {
  @observable hasFilters: boolean = false;
  @observable isExternalFilterPresent: boolean = false;
  @observable doesExternalFilterPass: boolean = false;
  storageService: ILocalStorageService;
  gridType: EntityTypes;
  filterStorageKey: string;
  filterHasChangedFn: (value: boolean) => void;
  projectId: number;
  gridApi: GridApi<any>;
  urlSearchParams: URLSearchParams;

  /**
   *
   */
  constructor({ projectId, gridType, filterHasChangedFn, urlSearchParams }: IFilterStoreAddonProps) {
    this.storageService = LocalStorageService;
    this.projectId = projectId;
    this.gridType = gridType;
    this.urlSearchParams = urlSearchParams;
    this.filterHasChangedFn = filterHasChangedFn;
    this.filterStorageKey = `projects-${this.projectId}-${this.gridType}-filters`;
  }

  onGridReady = (gridApi: GridApi<any>) => {
    this.gridApi = gridApi;
    this.initialiseAddon();
  };

  private initialiseAddon = () => {
    let filterModel = this.storageService.get(this.filterStorageKey);

    if ((this.urlSearchParams as any).size > 0) {
      this.gridApi.setFilterModel(GetFilterModelFromSearchParams(this.urlSearchParams));
    } else if (filterModel) {
      this.gridApi.setFilterModel(JSON.parse(filterModel));
    }
    this.checkFilters();
  };

  private storeFilterChanges = (params: FilterChangedEvent) => {
    let filterModel = params.api.getFilterModel();
    if (filterModel) this.storageService.set(this.filterStorageKey, JSON.stringify(filterModel));
    this.checkFilters();
  };

  private checkFilters = () => {
    let hasFilters = this.storageService.get(this.filterStorageKey);

    if (hasFilters?.length > 2 && hasFilters !== "null") {
      return this.filterHasChangedFn(true);
    }

    return this.filterHasChangedFn(false);
  };

  clearFilters = () => {
    this.storageService.remove(this.filterStorageKey);
    this.gridApi.setFilterModel(null);
    this.filterHasChangedFn(false);
  };

  getProps = () => {
    return {
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      onFilterChanged: this.storeFilterChanges
    };
  };
}

const GetFilterModelFromSearchParams = (urlSearchParams: URLSearchParams) => {
  let params = {};

  urlSearchParams.forEach((value, key) => {
    const op = GetOperator(value);
    const type = GetFilterType(key);
    params = {
      ...params,
      [key]: {
        filterType: type,
        type: op.gridOperator,
        ...GetValue(value, op, type)
      }
    };
  });

  console.log(params);
  return params;
};

const GetOperator = operator => {
  const s = _.filter(UrlOperators, item => {
    return operator.indexOf(item.symbol) === 0;
  })[0];

  if (s) {
    return s;
  }
  return { gridOperator: "equals" };
};

const GetFilterType = (key: string) => {
  let dateFilters = ["startDate", "endDate"];
  if (dateFilters.indexOf(key) >= 0) {
    return "date";
  }

  return "text";
};

export const UrlOperators = {
  contains: { symbol: "@", gridOperator: "contains" },
  notContains: { symbol: "^", gridOperator: "notContains" },
  notEqual: { symbol: "!", gridOperator: "notEqual" },
  beginsWith: { symbol: "]", gridOperator: "startsWith" },
  endsWith: { symbol: "[", gridOperator: "endsWith" },
  blank: { symbol: "-", gridOperator: "blank" },
  notBlank: { symbol: "+", gridOperator: "notBlank" },
  between: { symbol: "<>", gridOperator: "inRange" },
  lessThan: { symbol: "<", gridOperator: "lessThan" },
  moreThan: { symbol: ">", gridOperator: "moreThan" }
};

const GetValue = (value: string, op, type) => {
  if (op.gridOperator !== "equals") {
    value = value.slice(1);
    return { filter: value };
  } else if (op.gridOperator === "inRange" && type === "date") {
    let s = value.split("|");
    return { dateFrom: s[0], dateTo: s[1] };
  }
  return { filter: value };
};
